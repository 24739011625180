import { useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { createUploadLink } from "apollo-upload-client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { split } from "@apollo/client";

import "./App.css";

import NavBar from "./components/NavBar";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { ThemeProvider } from "styled-components";
import { theme, GlobalStyle } from "./theme";
import Main from "./screens/Main";
import { getMainDefinition } from "@apollo/client/utilities";

interface Definition {
  kind: string;
  operation?: string;
}
// omitting additional array wrapped around context value

function App() {
  const httpLink = createUploadLink({
    uri:
      process.env.NODE_ENV === "production"
        ? process.env.REACT_APP_SERVER!
        : "http://localhost:4000/graphql",
    credentials: "include",
  });

  const wsLink = new WebSocketLink({
    uri:
      process.env.NODE_ENV === "production"
        ? process.env.REACT_APP_SOCKETS!
        : "ws://localhost:4000/graphql",
    options: {
      reconnect: true,
    },
  });

  const link = split(
    ({ query }) => {
      const { kind, operation }: Definition = getMainDefinition(query);
      return kind === "OperationDefinition" && operation === "subscription";
    },
    wsLink,
    httpLink
  );

  const client = new ApolloClient({
    link,

    credentials: "include",
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {},
        },
      },
    }),
  });
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <ApolloProvider client={client}>
          <Router>
            {!["/r/", "/s/"].some((condition) =>
              window.location.href.includes(condition)
            ) && <NavBar toggle={toggle} />}
            <Main />
          </Router>
        </ApolloProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
