import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../components/styles/ButtonsElements";

type FinalCTASectionProps = {};

const Container = styled.div`
  height: 50vh;
  width: 100%;
  font-family: ${(props) => props.theme.font.headline};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const H1 = styled.div`
  display: flex;
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
  letter-spacing: 1px;
  color: ${(props) => props.theme.color.black};
  text-align: center;
  @media (max-width: 414px) {
    font-size: 26px;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
`;

const H2 = styled.div`
  margin-top: 20px;
  font-size: 22px;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 414px) {
    font-size: 16px;
    width: 100%;
    text-align: center;
  }
`;

const CTAButton = styled(Button)`
  width: 140px;
  min-height: 40px;
  margin-top: 50px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
`;

const FinalCTASection: React.FC<FinalCTASectionProps> = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <H1>Build Product People Crave.</H1>
      <H2>
        Sky Rocket your Product Market Fit 🚀 with the power of Conversational
        Digital Twin.
      </H2>
      <CTAButton primary onClick={() => navigate("/earlyaccess")}>
        SIGN UP
      </CTAButton>
    </Container>
  );
};

export default FinalCTASection;
