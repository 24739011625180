import React, { lazy, Suspense } from "react";
import { RecoilRoot } from "recoil";
import { useMeQuery } from "../generated/graphql";
import UnauthenticatedApp from "./UnauthenticatedApp";
import LoadingFallBack from "./LoadingFallBack";

const AuthenticatedApp = lazy(() => import("./AuthenticatedApp"));

interface MainProps {}

const Main: React.FC<MainProps> = () => {
  const { data } = useMeQuery();

  return (
    <>
      <Suspense fallback={<LoadingFallBack />}>
        <RecoilRoot>
          {data?.me ? <AuthenticatedApp /> : <UnauthenticatedApp />}
        </RecoilRoot>
      </Suspense>
    </>
  );
};

export default Main;
