import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import {
  MeDocument,
  MeQuery,
  useChangePasswordMutation,
} from "../../generated/graphql";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import passwordValidator from "password-validator";
import { motion } from "framer-motion";

const Container = styled.div`
  height: 80vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: ${(props) => props.theme.font.headline};
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;
const Title = styled.div`
  font-size: 60px;
  font-weight: 500;
  color: ${(props) => props.theme.color.black};
  margin-bottom: 75px;
`;
const Password = styled.input`
  outline: none;
  height: 40px;
  width: 270px;
  padding: 0;
  margin: 0;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.color.primary};
  margin-bottom: 10px;
  padding-left: 10px;
`;

const SaveButton = styled.div`
  cursor: pointer;
  color: white;
  font-weight: 500;
  letter-spacing: 1px;
  background: ${(props) => props.theme.color.primary};
  box-shadow: 0px 0px 4px ${(props) => props.theme.color.primary};
  height: 40px;
  width: 280px;
  text-align: center;
  vertical-align: center;
  line-height: 40px;
  border-radius: 4px;
`;

const ErrorMessage = styled(motion.div)`
  position: absolute;
  width: 280px;
  border: 1px solid #fca3a3;
  height: 30px;
  border-radius: 4px;
  text-align: center;
  line-height: 30px;
  background: #ffe9e9;
  color: #c02f2f;
  letter-spacing: 1px;
  bottom: -50px;
`;

const PasswordLegend = styled.div`
  margin-top: 20px;
  text-align: center;
  font-size: 11px;
  max-width: 280px;
  line-height: 18px;
`;

const schema = new passwordValidator();
schema
  .is()
  .min(8)
  .max(100)
  .has()
  .not()
  .spaces()
  .has()
  .digits(2) //
  .is()
  .not()
  .oneOf(["Passw0rd", "Password123"]);

const ChangePassword: React.FC = () => {
  const [errors, setErrors] = useState("");
  let { token } = useParams();
  const navigate = useNavigate();
  const [changePassword] = useChangePasswordMutation();
  //const [tokenError, setTokenError] = useState("");
  const formik = useFormik({
    initialValues: { newPassword: "" },
    onSubmit: async ({ newPassword }) => {
      const validated = schema.validate(newPassword);
      if (!validated) setErrors("Invalid Password Type");
      if (validated) {
        const response = await changePassword({
          variables: {
            newPassword: newPassword,
            token: token || "",
          },
          update: (cache, { data }) => {
            cache.writeQuery<MeQuery>({
              query: MeDocument,
              data: {
                __typename: "Query",
                me: data?.changePassword.user,
              },
            });
          },
        });

        if (response.data?.changePassword.errors) {
          setErrors("Invalid Token");
        } else if (response.data?.changePassword.user) {
          // worked
          navigate("/");
        }
      }
    },
  });

  useEffect(() => {
    if (errors !== "") {
      setTimeout(() => {
        setErrors("");
      }, 4000);
    }
  }, [errors]);

  return (
    <Container>
      <SubContainer>
        <Title>New Password</Title>
        <Password
          name="newPassword"
          placeholder="New Password"
          type="password"
          onChange={formik.handleChange}
          value={formik.values.newPassword}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              formik.handleSubmit();
            }
          }}
        />
        <SaveButton onClick={() => formik.handleSubmit()}>Save</SaveButton>
        <PasswordLegend>
          Password should be longer than 8 characters, include a minimum of 2
          digits & not include spaces
        </PasswordLegend>
        {errors !== "" && (
          <ErrorMessage
            initial={{ opacity: 0, y: 25 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ type: "spring", bounce: 0.5 }}>
            {errors}
          </ErrorMessage>
        )}
      </SubContainer>
    </Container>
  );
};

export default ChangePassword;

/*

    <Formik
        initialValues={{ newPassword: "" }}
        onSubmit={async (values, { setErrors }) => {
          const response = await changePassword({
            variables: {
              newPassword: values.newPassword,
              token,
            },
            update: (cache, { data }) => {
              cache.writeQuery<MeQuery>({
                query: MeDocument,
                data: {
                  __typename: "Query",
                  me: data?.changePassword.user,
                },
              });
            },
          });

          if (response.data?.changePassword.errors) {
            const errorMap = toErrorMap(response.data.changePassword.errors);
            if ("token" in errorMap) {
              setTokenError(errorMap.token);
            }
            setErrors(errorMap);
          } else if (response.data?.changePassword.user) {
            // worked
            history.push("/");
          }
        }}>
        {() => (
          // TODO: show errors in ui
          <Form>
            <Password
              name="newPassword"
              placeholder="New Password"
              type="password"
            />

            {tokenError ? (
              <div style={{ color: "red" }}>{tokenError}</div>
            ) : null}

            <button type="submit">Change Password</button>
          </Form>
        )}
      </Formik>


*/
