import React from "react";
import styled from "styled-components";
import { Button } from "../../components/styles/ButtonsElements";
import { useNavigate } from "react-router-dom";
type ConfirmYourEmailProps = {};

const Container = styled.div`
  height: 80vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: ${(props) => props.theme.font.headline};
  font-size: 20px;
  text-align: center;
  line-height: 50px;
  letter-spacing: 1px;
`;
const Congratulation = styled.div`
  max-width: 750px;
  margin-bottom: 30px;
`;

const ConfirmYourEmail: React.FC<ConfirmYourEmailProps> = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <Congratulation>
        Good Job ! We're almost done ! You've been sent an email, verify your
        email and start using Sweez.io for free !
      </Congratulation>

      <Button primary large onClick={() => navigate("/")}>
        GO HOME
      </Button>
    </Container>
  );
};

export default ConfirmYourEmail;
