import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useConfirmUserMutation } from "../../generated/graphql";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../components/styles/ButtonsElements";

type ParamsTypes = {
  token: string;
};

const Container = styled.div`
  height: 80vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: ${(props) => props.theme.font.headline};
  font-size: 20px;
  text-align: center;
  line-height: 50px;
  letter-spacing: 1px;
`;

const UserConfirmation: React.FC = () => {
  const navigate = useNavigate();
  const [confirmation, setConfirmation] = useState(false);
  let { token } = useParams<ParamsTypes>();
  const [confirmUser] = useConfirmUserMutation({
    variables: { token: token || "" },
  });

  useEffect(() => {
    async function getConfirmation() {
      const { data } = await confirmUser();
      if (data?.confirmUser) {
        setConfirmation(true);
      }
    }

    getConfirmation();
  }, [confirmUser]);

  return (
    <Container>
      {confirmation
        ? "Confirmation Successful - Please Log In"
        : "Confirmation failed"}
      {confirmation ? (
        <Button primary large onClick={() => navigate("/login")}>
          Log In
        </Button>
      ) : null}
    </Container>
  );
};

export default UserConfirmation;
