import React from "react";
import styled from "styled-components";
import Loader from "react-loader-spinner";

type LoadingFallBackProps = {};

const Container = styled.div`
  height: 90vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Spinner = styled(Loader)`
  margin-left: 60px;
`;

const LoadingFallBack: React.FC<LoadingFallBackProps> = () => {
  return (
    <Container>
      <Spinner type="TailSpin" color="#ff3350" height={100} width={100} />
    </Container>
  );
};

export default LoadingFallBack;
