import React from "react";
import { Route, Routes } from "react-router-dom";

import Login from "./UnAuthenticatedApp/Login";
import { Register } from "./UnAuthenticatedApp/Register";

import HomeNotAuthed from "./UnAuthenticatedApp/HomeNotAuthed";
import UserConfirmation from "./UnAuthenticatedApp/UserConfirmation";
import ConfirmYourEmail from "./UnAuthenticatedApp/ConfirmYourEmail";
import ForgotPassword from "./UnAuthenticatedApp/ForgotPassword";
import WaitingList from "./UnAuthenticatedApp/WaitingList";
import ChangePassword from "./UnAuthenticatedApp/ChangePassword";

interface UnauthenticatedAppProps {}

const UnauthenticatedApp: React.FC<UnauthenticatedAppProps> = () => {
  return (
    <Routes>
      <Route path="/" element={<HomeNotAuthed />} />
      <Route path="/earlyaccess" element={<WaitingList />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/confirm/:token" element={<UserConfirmation />} />
      <Route path="/confirmYourEmail" element={<ConfirmYourEmail />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/change-password/:token" element={<ChangePassword />} />
      <Route path="/meeting/:meetingId" element={<Login />} />
      <Route path="/s/:id" element={<Login />} />
      {/*<Route path="/r/:id" element={<SharedRecordingPage />} />*/}
      {/*<Route path="/s/:id" element={<ShareableSnippet external />} />*/}
    </Routes>
  );
};

export default UnauthenticatedApp;
