import React from "react";
import styled from "styled-components";
import { useColors } from "../../../utils/useColors";
type LandingPageSectionProps = {
  title: string;
  tag: string;
  lines: string[];
  idx: number;
  svg: any;
  height?: string;
  inversed?: boolean;
};

const Container = styled.div`
  height: 60vh;
  min-height: 700px;
  width: 100%;
  min-width: 99vw;
  z-index: 99999;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    height: 120vh;
    min-height: 120vh;
  }
`;

const InnerContainer = styled.div`
  height: 90%;
  width: 90vw;

  display: flex;
  @media (min-width: 1600px) {
    width: 82.5vw;
  }

  @media (max-width: 769px) {
    width: 100vw;
    flex-direction: column;
    margin-top: 100px;
    height: 100%;
  }
`;

const ContentContainer = styled.div`
  width: 50%;
  height: 90%;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    align-items: center;
    text-align: center;
    width: 100%;
    height: 60%;
  }
`;

const VideoContainer = styled.div`
  width: 50%;
  height: 90%;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    height: 60%;
    margin-bottom: 20px;
  }
`;

const Title = styled.div`
  height: 120px;
  margin: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 50px; //4.5rem;

  display: flex;
  flex-direction: column;

  font-family: ${(props) => props.theme.font.headline};
  color: ${(props) => props.theme.color.black};

  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 55px;
    width: 100%;
    margin-bottom: 0px;
  }
  @media (max-width: 415px) {
    font-size: 28px;
  }

  @media (max-width: 320px) {
    font-size: 24px;
  }
`;

const DescriptionLine = styled.div`
  margin-top: 20px;
  font-family: ${(props) => props.theme.font.fullText};
  color: ${(props) => props.theme.color.black};

  font-size: 20px;
  line-height: 34px;

  display: flex;

  @media (max-width: 768px) {
    font-size: 17px;
    margin-top: 10px;
  }

  @media (max-width: 415px) {
    font-size: 14px;
    line-height: 27px;
  }
`;

const Tag = styled.div`
  margin-left: 3px;
  padding: 4px;
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 50px;
  margin-bottom: 10px;
  letter-spacing: 1.25px;
  font-weight: 500;

  font-size: 12.5px;
  font-family: ${(props) => props.theme.font.fullText};

  border-radius: 2px;
  width: fit-content;

  @media (max-width: 768px) {
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

const LandingPageSection: React.FC<LandingPageSectionProps> = ({
  title,
  tag,
  lines,
  idx,
  svg,
  height,
  inversed,
}) => {
  const colors = useColors();

  const { width } = window.screen;
  return (
    <Container>
      <InnerContainer
        style={{
          flexDirection:
            width > 768
              ? inversed
                ? "row-reverse"
                : "row"
              : inversed
              ? "column-reverse"
              : "column",
        }}>
        <ContentContainer>
          <Tag
            style={{
              background: colors[idx].background,
              color: colors[idx].color,
            }}>
            {tag}
          </Tag>
          <Title>
            {title.split("<br/>").length > 1 ? (
              <>
                <div>{title.split("<br/>")[0]}</div>
                <div>{title.split("<br/>")[1]}</div>
              </>
            ) : (
              title
            )}
          </Title>
          {lines.map((line) => (
            <DescriptionLine>👉 {line}</DescriptionLine>
          ))}
        </ContentContainer>
        <VideoContainer>
          <>
            <img
              style={{
                height: height ? height : width > 768 ? "140%" : "90%",
              }}
              src={svg}
              alt=""
            />
          </>
        </VideoContainer>
      </InnerContainer>
    </Container>
  );
};

export default LandingPageSection;
