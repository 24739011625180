import React, { useState } from "react";
import styled from "styled-components";
import { Formik, Form } from "formik";
import { InputField } from "../../components/InputField";
import { useForgotPasswordMutation } from "../../generated/graphql";
import { Button } from "../../components/styles/ButtonsElements";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: ${(props) => props.theme.font.headline};
  font-size: 20px;
  text-align: center;
  line-height: 50px;
  letter-spacing: 1px;
`;

const StyledInput = styled(InputField)`
  width: 100%;
  margin-bottom: 30px;
`;
const StyledForm = styled(Form)`
  width: 300px;

  @media (max-width: 400px) {
    width: 200px;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const ForgotPassword: React.FC<{}> = () => {
  const [complete, setComplete] = useState(false);
  const [forgotPassword] = useForgotPasswordMutation();
  return (
    <Container>
      <Formik
        initialValues={{ email: "" }}
        onSubmit={async (values, { setErrors }) => {
          await forgotPassword({ variables: values });
          setComplete(true);
        }}>
        {() =>
          complete ? (
            <div>An Email Has Been Sent to Reset Your Password 🍉 </div>
          ) : (
            <StyledForm>
              <StyledInput name="email" label="Email" type="email" />
              <StyledButton primary large type="submit">
                Reset Password
              </StyledButton>
            </StyledForm>
          )
        }
      </Formik>
    </Container>
  );
};

export default ForgotPassword;
