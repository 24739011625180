import React, { InputHTMLAttributes } from "react";
import { useField } from "formik";
import styled from "styled-components";

type InputFieldProps = InputHTMLAttributes<
  HTMLInputElement | HTMLTextAreaElement
> & {
  name: string;
  label?: string;
  primary?: boolean;
  large?: boolean;
  medium?: boolean;
  small?: boolean;
  textarea?: boolean;
  error?: string | number;
  touched?: boolean;
};

const Wrapper = styled.div`
  position: relative;
`;

const Input = styled.input`
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */

  border: 1px solid rgba(${(props) => props.theme.color.primaryRGBA}, 0.1);
  background: rgba(${(props) => props.theme.color.primaryRGBA}, 0.01);
  border-radius: ${(props) => props.theme.radius.small};
  font-family: ${(props) => props.theme.font.fullText};
  padding-left: 7px;

  letter-spacing: 1px;
  font-style: normal;
  font-weight: normal;
  line-height: 22px;
  font-size: 11px;
  height: 34px;

  /* identical to box height, or 244% */

  /* blue_primary_a */
  //height: 30px;
  &:focus {
    border: none;
    outline: none;
  }

  width: 100%;
`;

const TextArea = styled.textarea`
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */

  border: 1px solid rgba(${(props) => props.theme.color.primaryRGBA}, 0.1);
  background: rgba(${(props) => props.theme.color.primaryRGBA}, 0.01);
  border-radius: ${(props) => props.theme.radius.small};
  font-family: ${(props) => props.theme.font.fullText};
  padding-left: 7px;

  letter-spacing: 1px;
  font-style: normal;
  font-weight: normal;
  line-height: 22px;
  font-size: 11px;
  height: 70px;

  /* identical to box height, or 244% */

  /* blue_primary_a */
  //height: 30px;
  &:focus {
    border: none;
    outline: none;
  }

  width: 100%;
`;

const Label = styled.label`
  font-family: ${(props) => props.theme.font.headline};
  display: flex;
  align-items: center;
  position: absolute;
  pointer-events: none;
  left: 20px;
  top: 5px;
  transition: 0.3s ease all;
  letter-spacing: 1px;
  font-style: normal;
  font-weight: normal;
  line-height: 22px;
  font-size: 13px;
  color: ${(props) => props.theme.color.primary};
  ${Input}:focus ~ & {
    top: -20px;
    left: 0px;
    font-size: 12px;
  }

  ${Input}:not(:placeholder-shown) ~ & {
    top: -20px;
    left: 0px;
    font-size: 12px;
  }

  ${TextArea}:focus ~ & {
    top: -20px;
    left: 0px;
    font-size: 12px;
  }

  ${TextArea}:not(:placeholder-shown) ~ & {
    top: -20px;
    left: 0px;
    font-size: 12px;
  }
`;

const ValidationHelp = styled.label`
  position: absolute;
  left: -80px;
  top: 10px;
  font-family: ${(props) => props.theme.font.headline};
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 2px;
  color: red;
  z-index: 10;
`;

export const InputField: React.FC<InputFieldProps> = ({
  textarea,
  label,
  small,
  type,
  error,
  touched,
  ...props
}) => {
  const [field] = useField(props);
  return (
    <>
      <Wrapper>
        {error ? <ValidationHelp>{error}</ValidationHelp> : null}
        {textarea ? (
          <TextArea {...field} {...props} id={field.name} />
        ) : (
          <Input type={type} {...field} {...props} id={field.name} />
        )}
        <Label>{label}</Label>
      </Wrapper>
    </>
  );
};

// type="text" {...field} {...props} id={field.name}
/*

const TextArea = styled.textarea`
  border: 1px solid rgba(${(props) => props.theme.color.primaryRGBA}, 0.1);
  background: rgba(${(props) => props.theme.color.primaryRGBA}, 0.01);
  border-radius: ${(props) => props.theme.radius.small};
  font-family: ${(props) => props.theme.font.fullText};

  padding-left: 10px;
  letter-spacing: 1px;
  font-style: normal;
  font-weight: normal;
  line-height: 22px;
  font-size: 11px;
  margin: 10px;


  height: 70px;
  &:focus {
    border: none;
    outline: none;
  }
  width: ${({ large }: StyleProps) => (large ? "500px" : "auto")};
`;


*/
