import { useMemo } from "react";

export const lightColors = [
  {
    color: "#1C374B",
    background: "#D3E5EF",
    key: "Blue",
  },
  { color: "#4F3361", background: "#E8DEEE", key: "Purple" },
  { color: "#2A4636", background: "#DBEDDB", key: "Green" },
  { color: "#49290D", background: "#FADEC9", key: "Orange" },
  { color: "#532B3E", background: "#F5E0E9", key: "Pink" },
  { color: "#696969", background: "#f1f1f1", key: "Grey" },
];

export const useColors = () => {
  const colors = useMemo(() => {
    const allColors = Array(25).fill(lightColors).flat(1);
    return allColors;
  }, []);

  return colors;
};
