import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type Query = {
  __typename?: 'Query';
  hello: Scalars['String'];
  start: Scalars['String'];
  check: Scalars['String'];
  doubleCheck: Scalars['String'];
  users?: Maybe<Array<UsersData>>;
  me?: Maybe<User>;
  userTeams?: Maybe<UserTeams>;
  getMemoryToken: UserResponse;
  meeting: Meeting;
  meetingForDatasetBackend: Meeting;
  meetingsByUser: Array<Meeting>;
  nextUnAnnotatedMeeting: Meeting;
  meetingReportExists: Scalars['Boolean'];
  timelinesClusters: Array<ClusteredTimeline>;
  searchMeetings: Array<Meeting>;
  sharedPage?: Maybe<SharedPage>;
  temporarySharedMeeting: SharedPageOutput;
  uploadToken: Scalars['String'];
  notionWorkspaces: Array<NotionDatabaseResponse>;
  getNotionDatabases: Array<NotionDatabaseResponse>;
  checkAccess: Scalars['Boolean'];
  getPageData: PageData;
  getTurnMsg: Scalars['String'];
  annotatedData: Array<Dataset>;
  application: Application;
  applications: Array<Application>;
  userIntegrations: Array<Integration>;
  aircallStatus: AircallStatusOutput;
  memberTemplates: Array<MemberTemplateOutput>;
  notesTemplates: Array<NoteTemplate>;
  saveLocationByTemplateId: Scalars['String'];
  savedSearchs: Array<Searchs>;
  snippetsLibraries?: Maybe<Array<SnippetsLibrary>>;
  organization: Organization;
  member: Member;
  invitationsSentFromOrganization: Array<Scalars['String']>;
  checkForInvitation?: Maybe<InvitationOutput>;
  team: Team;
  participant: Participant;
  participants: Array<Participant>;
  speakerTime: SpeakerTimeOutput;
};


export type QueryMeetingArgs = {
  notionId?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  idMeeting?: Maybe<Scalars['String']>;
};


export type QueryMeetingForDatasetBackendArgs = {
  idMeeting: Scalars['String'];
};


export type QueryMeetingsByUserArgs = {
  id: Scalars['Float'];
};


export type QueryMeetingReportExistsArgs = {
  idMeeting: Scalars['String'];
};


export type QueryTimelinesClustersArgs = {
  idMeeting: Scalars['String'];
};


export type QuerySearchMeetingsArgs = {
  query: Scalars['String'];
};


export type QuerySharedPageArgs = {
  id: Scalars['String'];
};


export type QueryTemporarySharedMeetingArgs = {
  sharedPageId: Scalars['String'];
  token: Scalars['String'];
};


export type QueryCheckAccessArgs = {
  id: Scalars['String'];
};


export type QueryGetPageDataArgs = {
  id: Scalars['String'];
};


export type QueryGetTurnMsgArgs = {
  seqId: Scalars['Float'];
  MeetingId: Scalars['String'];
};


export type QueryApplicationArgs = {
  email: Scalars['String'];
};


export type QueryAircallStatusArgs = {
  userId: Scalars['Float'];
};


export type QueryMemberTemplatesArgs = {
  organizationId: Scalars['Float'];
  memberId: Scalars['Float'];
};


export type QuerySaveLocationByTemplateIdArgs = {
  templateId: Scalars['String'];
};


export type QueryOrganizationArgs = {
  id: Scalars['Float'];
};


export type QueryMemberArgs = {
  memberId: Scalars['Float'];
  organizationId: Scalars['Float'];
};


export type QueryInvitationsSentFromOrganizationArgs = {
  organizationId: Scalars['Float'];
};


export type QueryTeamArgs = {
  teamId: Scalars['String'];
};


export type QuerySpeakerTimeArgs = {
  meetingId: Scalars['String'];
  speaker: Scalars['String'];
};

export type UsersData = {
  __typename?: 'UsersData';
  id: Scalars['Int'];
  username?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['Float'];
  username: Scalars['String'];
  email: Scalars['String'];
  userRights?: Maybe<Scalars['JSON']>;
  integrations: Array<Integration>;
  organizationId?: Maybe<Scalars['Int']>;
  notion?: Maybe<NotionIntegration>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};


export type Integration = {
  __typename?: 'Integration';
  id: Scalars['Float'];
  organization?: Maybe<Organization>;
  type: Scalars['String'];
  token: Scalars['String'];
  isActive: Scalars['Boolean'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type Organization = {
  __typename?: 'Organization';
  id: Scalars['Float'];
  name: Scalars['String'];
  members: Array<Member>;
  teams: Array<Team>;
  integrations: Array<Integration>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type Member = {
  __typename?: 'Member';
  id: Scalars['Float'];
  role: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  integrationTemplates?: Maybe<Array<Scalars['JSON']>>;
};

export type Team = {
  __typename?: 'Team';
  id: Scalars['String'];
  name: Scalars['String'];
  members: Array<Member>;
};

export type NotionIntegration = {
  __typename?: 'NotionIntegration';
  workspaceId: Scalars['String'];
  notionToken: Scalars['String'];
  defaultParentPageId: Scalars['String'];
};

export type UserTeams = {
  __typename?: 'UserTeams';
  id: Scalars['Float'];
  username: Scalars['String'];
  email: Scalars['String'];
  userRights?: Maybe<Scalars['JSON']>;
  integrations: Array<Integration>;
  organizationId?: Maybe<Scalars['Int']>;
  notion?: Maybe<NotionIntegration>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  teams?: Maybe<Array<Team>>;
};

export type UserResponse = {
  __typename?: 'UserResponse';
  errors?: Maybe<Array<FieldError>>;
  user?: Maybe<User>;
  token?: Maybe<Scalars['String']>;
};

export type FieldError = {
  __typename?: 'FieldError';
  field: Scalars['String'];
  message: Scalars['String'];
};

export type Meeting = {
  __typename?: 'Meeting';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  url: Scalars['String'];
  title: Scalars['String'];
  emoji: Scalars['String'];
  template: MeetingTemplate;
  properties: Array<Property>;
  notes: Array<Notes>;
  recording?: Maybe<Recording>;
  snippets?: Maybe<Array<Snippet>>;
  turns?: Maybe<Array<Turn>>;
  inferences?: Maybe<MeetingInferences>;
  exports?: Maybe<MeetingExports>;
  metadata: MeetingMetaData;
};


export type MeetingTemplate = {
  __typename?: 'MeetingTemplate';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type Property = {
  __typename?: 'Property';
  id: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  type: Scalars['String'];
  data?: Maybe<Scalars['JSONObject']>;
  generated?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Array<PropertyOption>>;
  options?: Maybe<Array<PropertyOption>>;
};


export type PropertyOption = {
  __typename?: 'PropertyOption';
  text: Scalars['String'];
  color?: Maybe<Scalars['String']>;
  timestamps?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
};

export type Notes = {
  __typename?: 'Notes';
  id: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: Scalars['String'];
  updatedAtInRecording?: Maybe<Array<Scalars['Float']>>;
  children?: Maybe<Array<Children>>;
  checked?: Maybe<Scalars['Boolean']>;
  exports?: Maybe<NotesExports>;
  data?: Maybe<Array<Scalars['JSONObject']>>;
};

export type Children = {
  __typename?: 'Children';
  text?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  checked?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAtInRecording?: Maybe<Array<Scalars['Float']>>;
  data?: Maybe<Array<Scalars['JSONObject']>>;
  exports?: Maybe<NotesExports>;
  children?: Maybe<Array<Children>>;
};

export type NotesExports = {
  __typename?: 'NotesExports';
  notionBlockId?: Maybe<Scalars['String']>;
  emailBlockId?: Maybe<Scalars['String']>;
};

export type Recording = {
  __typename?: 'Recording';
  updatedAt: Scalars['DateTime'];
  duration: Scalars['Float'];
  url: Scalars['String'];
};

export type Snippet = {
  __typename?: 'Snippet';
  id: Scalars['String'];
  shareableId?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  url: Scalars['String'];
  description: Scalars['String'];
  turnsIds: Array<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  duration: Scalars['Float'];
  start: Scalars['Float'];
  end: Scalars['Float'];
  owner: SnippetOwner;
};

export type SnippetOwner = {
  __typename?: 'SnippetOwner';
  id: Scalars['Float'];
  username: Scalars['String'];
};

export type Turn = {
  __typename?: 'Turn';
  id: Scalars['Int'];
  speaker: Scalars['String'];
  text: Scalars['String'];
  words?: Maybe<Array<Word>>;
  timestamp: Scalars['String'];
  url: Scalars['String'];
  metadata: TurnMetaData;
  inferences?: Maybe<TurnDataAnalysis>;
};

export type Word = {
  __typename?: 'Word';
  id: Scalars['Float'];
  word: Scalars['String'];
  start: Scalars['Float'];
  end: Scalars['Float'];
  inferences?: Maybe<WordInference>;
};

export type WordInference = {
  __typename?: 'WordInference';
  acts: WordActs;
  sentiment: Scalars['Float'];
  thematics?: Maybe<Array<Scalars['String']>>;
};

export type WordActs = {
  __typename?: 'WordActs';
  macro: Scalars['String'];
  detailed: Array<Scalars['String']>;
};

export type TurnMetaData = {
  __typename?: 'TurnMetaData';
  seqId: Scalars['Int'];
  start: Scalars['Float'];
  end: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  endedAt: Scalars['DateTime'];
  duration: Scalars['Float'];
};

export type TurnDataAnalysis = {
  __typename?: 'TurnDataAnalysis';
  sentiment: Scalars['String'];
};

export type MeetingInferences = {
  __typename?: 'MeetingInferences';
  keywords?: Maybe<Array<MeetingKeywords>>;
};

export type MeetingKeywords = {
  __typename?: 'MeetingKeywords';
  id: Scalars['Int'];
  type: Scalars['String'];
  value: Scalars['String'];
};

export type MeetingExports = {
  __typename?: 'MeetingExports';
  notion?: Maybe<NotionExport>;
};

export type NotionExport = {
  __typename?: 'NotionExport';
  id: Scalars['String'];
  parentPageId: Scalars['String'];
  reportsExportsCount?: Maybe<Scalars['Int']>;
};

export type MeetingMetaData = {
  __typename?: 'MeetingMetaData';
  duration?: Maybe<Scalars['Float']>;
  accessRights: AccessRights;
};

export type AccessRights = {
  __typename?: 'AccessRights';
  accessRightLevel?: Maybe<Scalars['String']>;
  ownerId: Scalars['Int'];
  organizationId?: Maybe<Scalars['Float']>;
  teamId?: Maybe<Scalars['String']>;
  participantsIds?: Maybe<Array<Scalars['Float']>>;
};

export type ClusteredTimeline = {
  __typename?: 'ClusteredTimeline';
  block: Scalars['String'];
  turns: Array<Scalars['Int']>;
  updatedAtInRecording: Array<Scalars['Float']>;
};

export type SharedPage = {
  __typename?: 'SharedPage';
  id: Scalars['String'];
  documentId: Scalars['String'];
  meetingId: Scalars['String'];
  type: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type SharedPageOutput = {
  __typename?: 'SharedPageOutput';
  meeting: Meeting;
  documentId: Scalars['String'];
  type: Scalars['String'];
};

export type NotionDatabaseResponse = {
  __typename?: 'NotionDatabaseResponse';
  database?: Maybe<DatabaseIdentifiers>;
};

export type DatabaseIdentifiers = {
  __typename?: 'DatabaseIdentifiers';
  id: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type PageData = {
  __typename?: 'PageData';
  title: Scalars['String'];
  emoji: Scalars['String'];
  save?: Maybe<Scalars['String']>;
};

export type Dataset = {
  __typename?: 'Dataset';
  id: Scalars['String'];
  turns?: Maybe<Array<DatasetTurn>>;
  sections?: Maybe<Array<Scalars['String']>>;
  summaries?: Maybe<Array<Summary>>;
};

export type DatasetTurn = {
  __typename?: 'DatasetTurn';
  id?: Maybe<Scalars['Int']>;
  speaker?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type Summary = {
  __typename?: 'Summary';
  section?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
};

export type Application = {
  __typename?: 'Application';
  id: Scalars['Float'];
  email: Scalars['String'];
  authorized: Scalars['Boolean'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type AircallStatusOutput = {
  __typename?: 'AircallStatusOutput';
  aircallIntegration: Scalars['Boolean'];
  aircallToken?: Maybe<Scalars['String']>;
  webHookEndpoint?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type MemberTemplateOutput = {
  __typename?: 'MemberTemplateOutput';
  id: Scalars['String'];
  emoji: Scalars['String'];
  title: Scalars['String'];
  access: Scalars['String'];
};

export type NoteTemplate = {
  __typename?: 'NoteTemplate';
  id: Scalars['String'];
  title: Scalars['String'];
  emoji: Scalars['String'];
  accessRights: AccessRights;
  integrations?: Maybe<LinkedToIntegrations>;
  exports?: Maybe<TemplateExport>;
  properties?: Maybe<Array<Property>>;
  notes?: Maybe<Array<Notes>>;
};

export type LinkedToIntegrations = {
  __typename?: 'LinkedToIntegrations';
  aircall: Scalars['Boolean'];
  zoom: Scalars['Boolean'];
};

export type TemplateExport = {
  __typename?: 'TemplateExport';
  notion?: Maybe<NotionTemplateExport>;
};

export type NotionTemplateExport = {
  __typename?: 'NotionTemplateExport';
  parentPageId: Scalars['String'];
};

export type Searchs = {
  __typename?: 'Searchs';
  id: Scalars['String'];
  query: Scalars['String'];
  filters: Array<Filter>;
  type: Scalars['String'];
  emoji: Scalars['String'];
  title: Scalars['String'];
  accessRights: AccessRights;
  order: Scalars['Int'];
};

export type Filter = {
  __typename?: 'Filter';
  id: Scalars['String'];
  key: Scalars['String'];
  relation: Scalars['String'];
  value: Array<Scalars['String']>;
};

export type SnippetsLibrary = {
  __typename?: 'SnippetsLibrary';
  id: Scalars['Float'];
  accessRights: AccessRights;
  title: Scalars['String'];
  emoji: Scalars['String'];
};

export type InvitationOutput = {
  __typename?: 'InvitationOutput';
  id: Scalars['Float'];
  name: Scalars['String'];
};

export type Participant = {
  __typename?: 'Participant';
  id: Scalars['Float'];
  name: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  ownerId?: Maybe<Scalars['Float']>;
  organizationId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
  voiceSamples: Array<VoiceSample>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type VoiceSample = {
  __typename?: 'VoiceSample';
  id: Scalars['String'];
  text: Scalars['String'];
  url: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type SpeakerTimeOutput = {
  __typename?: 'SpeakerTimeOutput';
  spokenTimePercentThisMeeting: Scalars['Float'];
  spokenTimePercentSimilarMeetings: Array<Scalars['Float']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  forgotPassword: Scalars['Boolean'];
  changePassword: UserResponse;
  register: UserResponse;
  onboarding: Scalars['Boolean'];
  googleRegisterOrLogin: UserResponse;
  confirmUser?: Maybe<Scalars['Boolean']>;
  login: UserResponse;
  setNotionToken: Scalars['Boolean'];
  deleteNotionToken: Scalars['Boolean'];
  notionIdTest: Scalars['String'];
  setNotionWorkspaceId: Scalars['Boolean'];
  guestLogin: GuestLoginResponse;
  logout: Scalars['Boolean'];
  setNotes: Scalars['Boolean'];
  deleteMeeting: Scalars['Boolean'];
  setTitleAndEmoji: Scalars['Boolean'];
  setProperties: Scalars['Boolean'];
  deleteProperty: Scalars['Boolean'];
  createMeeting: Meeting;
  sendReportByEmail: Scalars['Boolean'];
  setMeetingPrivacy: Scalars['Boolean'];
  updateTitleAndEmoji: Meeting;
  insertSharedPage: Scalars['String'];
  sharedPageMemoryToken: Scalars['String'];
  uploadFile: Scalars['Boolean'];
  startMultiplePartsUpload: MultiPartUpload;
  completeMultiplePartsUpload: Scalars['Boolean'];
  abortMultiplePartsUpload: Scalars['Boolean'];
  signS3: SignedUrlResponse;
  signedUploadToGCP: Scalars['String'];
  signedReadGCP: Scalars['String'];
  transcribe: Scalars['Boolean'];
  updateTurns: Scalars['Boolean'];
  detectParticipantsWithoutVoiceSamples: Array<Scalars['String']>;
  mergeSegments: Snippet;
  generateFromPrompt: Scalars['String'];
  deleteSnippet: Scalars['Boolean'];
  updateSnippet: Scalars['Boolean'];
  extractSnippetFromFullRecording: Snippet;
  autoComplete: Scalars['String'];
  getSummary: Notes;
  topics: Array<TopicOutput>;
  updateNameIfNotionUser: Scalars['Boolean'];
  createPage: Scalars['String'];
  exportReportToNotion: ReportOutput;
  archivePage: Scalars['Boolean'];
  deleteAllTurns: Meeting;
  setTurnMsg: Scalars['Boolean'];
  test_queue: Meeting;
  applyToBeta: Scalars['Boolean'];
  hasAuthorization: Scalars['Boolean'];
  authorize: Scalars['Boolean'];
  revokeAuthorization: Scalars['Boolean'];
  setIsActive: Scalars['Boolean'];
  setAircallToken: Scalars['Boolean'];
  setHubSpotToken: Scalars['Boolean'];
  call_aircall_webhook_placeholder: Scalars['String'];
  calls: Scalars['Boolean'];
  setWebhookEndpoint: Scalars['Boolean'];
  adminSetWebHookEndpoint: Scalars['Boolean'];
  setNotesTemplate: Scalars['String'];
  setTemplateProperties: Scalars['Boolean'];
  deleteTemplateProperty: Scalars['Boolean'];
  resetNotesTemplate: Scalars['Boolean'];
  deleteNotesTemplate: Scalars['Boolean'];
  insertSavedSearch: Scalars['String'];
  updateSavedSearch: Scalars['String'];
  deleteSavedSearch: Scalars['Boolean'];
  searchForAnalytics: SearchForAnalyticsOutput;
  getWordClouds: Scalars['JSONObject'];
  search: SearchOutput;
  insertSnippetLibrary: SnippetsLibrary;
  updateSnippetLibrary: Scalars['Boolean'];
  deleteSnippetLibrary: Scalars['Boolean'];
  saveOrganization: Scalars['Int'];
  sendInvitation: Scalars['Boolean'];
  revokeInvitation: Scalars['Boolean'];
  saveOrganizationMember: Scalars['Boolean'];
  acceptInvitation: Scalars['Boolean'];
  setMemberIntegrationTemplates: Scalars['Boolean'];
  removeOrganizationMember: Scalars['Boolean'];
  createTeam: Team;
  updateTeam: Team;
  deleteTeam: Scalars['Boolean'];
  saveTeamMember: Scalars['Boolean'];
  deleteTeamMember: Scalars['Boolean'];
  saveParticipant: Participant;
  addVoiceSampleForNonUserParticipant: Participant;
  addVoiceSample: Participant;
  deleteVoiceSample: Scalars['Boolean'];
  createIntegrationAdmin: Scalars['String'];
  setWebhookAdmin: Scalars['Boolean'];
  reactivateAircallWebhookAdmin: Scalars['Boolean'];
  createOrganizationAdmin: Scalars['Int'];
  meetingsAdmin: Array<Meeting>;
  switchMemberFromOrganizationAdmin: Scalars['String'];
  createUserAdmin: Scalars['String'];
  ingestAircallHistoryData: Scalars['Boolean'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  newPassword: Scalars['String'];
  token: Scalars['String'];
};


export type MutationRegisterArgs = {
  options: UsernamePasswordInput;
};


export type MutationGoogleRegisterOrLoginArgs = {
  accessToken: Scalars['String'];
  token: Scalars['String'];
};


export type MutationConfirmUserArgs = {
  token: Scalars['String'];
};


export type MutationLoginArgs = {
  password: Scalars['String'];
  usernameOrEmail: Scalars['String'];
};


export type MutationSetNotionTokenArgs = {
  code: Scalars['String'];
};


export type MutationNotionIdTestArgs = {
  notionToken: Scalars['String'];
};


export type MutationSetNotionWorkspaceIdArgs = {
  defaultParentPageId: Scalars['String'];
};


export type MutationSetNotesArgs = {
  notes: Array<NotesInput>;
  idMeeting: Scalars['String'];
};


export type MutationDeleteMeetingArgs = {
  idMeeting: Scalars['String'];
};


export type MutationSetTitleAndEmojiArgs = {
  emoji: Scalars['String'];
  title: Scalars['String'];
  idMeeting: Scalars['String'];
};


export type MutationSetPropertiesArgs = {
  properties: Array<PropertyInput>;
  id: Scalars['String'];
};


export type MutationDeletePropertyArgs = {
  propertyId: Scalars['String'];
  meetingId: Scalars['String'];
};


export type MutationCreateMeetingArgs = {
  template?: Maybe<TemplateRefInput>;
  notes: Array<NotesInput>;
  properties: Array<PropertyInput>;
  url: Scalars['String'];
  emoji: Scalars['String'];
  title: Scalars['String'];
};


export type MutationSendReportByEmailArgs = {
  bcc?: Maybe<Array<Scalars['String']>>;
  cc?: Maybe<Array<Scalars['String']>>;
  to: Array<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  meetingId: Scalars['String'];
};


export type MutationSetMeetingPrivacyArgs = {
  teamId?: Maybe<Scalars['String']>;
  accessRightLevel: Scalars['String'];
  idMeeting: Scalars['String'];
};


export type MutationUpdateTitleAndEmojiArgs = {
  emoji?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  idMeeting: Scalars['String'];
};


export type MutationInsertSharedPageArgs = {
  meetingId: Scalars['String'];
  type: Scalars['String'];
  documentId: Scalars['String'];
};


export type MutationSharedPageMemoryTokenArgs = {
  sharedPageId: Scalars['String'];
};


export type MutationUploadFileArgs = {
  file: Scalars['Upload'];
};


export type MutationStartMultiplePartsUploadArgs = {
  parts: Scalars['Float'];
  filename: Scalars['String'];
};


export type MutationCompleteMultiplePartsUploadArgs = {
  parts: Array<Part>;
  uploadId: Scalars['String'];
  filename: Scalars['String'];
};


export type MutationAbortMultiplePartsUploadArgs = {
  uploadId: Scalars['String'];
  filename: Scalars['String'];
};


export type MutationSignS3Args = {
  filetype: Scalars['String'];
  filename: Scalars['String'];
};


export type MutationSignedUploadToGcpArgs = {
  fileName: Scalars['String'];
};


export type MutationSignedReadGcpArgs = {
  hours?: Maybe<Scalars['Float']>;
  fileName: Scalars['String'];
};


export type MutationTranscribeArgs = {
  endedAt: Scalars['DateTime'];
  end: Scalars['Float'];
  speaker: Scalars['String'];
  meetingId: Scalars['String'];
  url: Scalars['String'];
};


export type MutationUpdateTurnsArgs = {
  turns: Array<TurnInput>;
  meetingId: Scalars['String'];
};


export type MutationDetectParticipantsWithoutVoiceSamplesArgs = {
  participantsNames: Array<Scalars['String']>;
};


export type MutationMergeSegmentsArgs = {
  type: Scalars['String'];
  description: Scalars['String'];
  idMeeting: Scalars['String'];
  segments: Array<SegmentsInput>;
};


export type MutationGenerateFromPromptArgs = {
  context: Scalars['String'];
  prompt: Scalars['String'];
};


export type MutationDeleteSnippetArgs = {
  snippetId: Scalars['String'];
  idMeeting: Scalars['String'];
};


export type MutationUpdateSnippetArgs = {
  type: Scalars['String'];
  description: Scalars['String'];
  snippetId: Scalars['String'];
  idMeeting: Scalars['String'];
};


export type MutationExtractSnippetFromFullRecordingArgs = {
  endIndex?: Maybe<Scalars['Float']>;
  startIndex?: Maybe<Scalars['Float']>;
  end: Scalars['Float'];
  start: Scalars['Float'];
  type: Scalars['String'];
  description: Scalars['String'];
  idMeeting: Scalars['String'];
};


export type MutationAutoCompleteArgs = {
  meetingId: Scalars['String'];
  text: Scalars['String'];
};


export type MutationGetSummaryArgs = {
  meetingId: Scalars['String'];
};


export type MutationTopicsArgs = {
  meetingIds: Array<Scalars['String']>;
};


export type MutationCreatePageArgs = {
  templateId: Scalars['String'];
  emoji: Scalars['String'];
  title: Scalars['String'];
  idMeeting: Scalars['String'];
};


export type MutationExportReportToNotionArgs = {
  parentId?: Maybe<Scalars['String']>;
  meetingId: Scalars['String'];
};


export type MutationArchivePageArgs = {
  notionId: Scalars['String'];
};


export type MutationDeleteAllTurnsArgs = {
  MeetingId: Scalars['String'];
};


export type MutationSetTurnMsgArgs = {
  seqId: Scalars['Float'];
  MeetingId: Scalars['String'];
};


export type MutationTest_QueueArgs = {
  MeetingId: Scalars['String'];
};


export type MutationApplyToBetaArgs = {
  email: Scalars['String'];
};


export type MutationHasAuthorizationArgs = {
  email: Scalars['String'];
};


export type MutationAuthorizeArgs = {
  email: Scalars['String'];
};


export type MutationRevokeAuthorizationArgs = {
  email: Scalars['String'];
};


export type MutationSetIsActiveArgs = {
  isActive: Scalars['Boolean'];
  id: Scalars['Float'];
};


export type MutationSetAircallTokenArgs = {
  organizationId: Scalars['Float'];
  code: Scalars['String'];
};


export type MutationSetHubSpotTokenArgs = {
  code: Scalars['String'];
};


export type MutationCall_Aircall_Webhook_PlaceholderArgs = {
  test?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  organizationId: Scalars['Float'];
  userId: Scalars['Float'];
  callIds: Array<Scalars['Float']>;
};


export type MutationCallsArgs = {
  all?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
  userId: Scalars['Float'];
};


export type MutationAdminSetWebHookEndpointArgs = {
  userId: Scalars['Float'];
  aircallToken: Scalars['String'];
};


export type MutationSetNotesTemplateArgs = {
  notes?: Maybe<Array<NotesInput>>;
  properties?: Maybe<Array<PropertyInput>>;
  saveLocation?: Maybe<Scalars['String']>;
  accessOptions?: Maybe<AccessOptions>;
  accessRightLevel?: Maybe<Scalars['String']>;
  emoji?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};


export type MutationSetTemplatePropertiesArgs = {
  properties: Array<PropertyInput>;
  id: Scalars['String'];
};


export type MutationDeleteTemplatePropertyArgs = {
  propertyId: Scalars['String'];
  templateId: Scalars['String'];
};


export type MutationDeleteNotesTemplateArgs = {
  id: Scalars['String'];
};


export type MutationInsertSavedSearchArgs = {
  accessRightLevel: Scalars['String'];
  order: Scalars['Int'];
  filters: SearchFiltersInput;
  query: Scalars['String'];
  title: Scalars['String'];
  emoji: Scalars['String'];
};


export type MutationUpdateSavedSearchArgs = {
  organizationId?: Maybe<Scalars['Float']>;
  accessRightLevel?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  filters?: Maybe<SearchFiltersInput>;
  query?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  emoji?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};


export type MutationDeleteSavedSearchArgs = {
  id: Scalars['String'];
};


export type MutationSearchForAnalyticsArgs = {
  size?: Maybe<Scalars['Float']>;
  filters: SearchFiltersInput;
  query: Scalars['String'];
};


export type MutationGetWordCloudsArgs = {
  texts: Array<Scalars['String']>;
};


export type MutationSearchArgs = {
  cursors?: Maybe<SearchCursorsInput>;
  page?: Maybe<Scalars['Float']>;
  filters: SearchFiltersInput;
  query: Scalars['String'];
};


export type MutationInsertSnippetLibraryArgs = {
  accessRightLevel: Scalars['String'];
  emoji: Scalars['String'];
  title: Scalars['String'];
};


export type MutationUpdateSnippetLibraryArgs = {
  accessRightLevel: Scalars['String'];
  emoji: Scalars['String'];
  title: Scalars['String'];
  id: Scalars['Float'];
};


export type MutationDeleteSnippetLibraryArgs = {
  id: Scalars['Float'];
};


export type MutationSaveOrganizationArgs = {
  name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
};


export type MutationSendInvitationArgs = {
  email: Scalars['String'];
  organizationId: Scalars['Float'];
};


export type MutationRevokeInvitationArgs = {
  email: Scalars['String'];
  organizationId: Scalars['Float'];
};


export type MutationSaveOrganizationMemberArgs = {
  role: Scalars['String'];
  userId: Scalars['Float'];
  organizationId: Scalars['Float'];
};


export type MutationAcceptInvitationArgs = {
  email: Scalars['String'];
  organizationId: Scalars['Float'];
};


export type MutationSetMemberIntegrationTemplatesArgs = {
  integrationName: Scalars['String'];
  templateId: Scalars['String'];
  memberId: Scalars['Float'];
  organizationId: Scalars['Float'];
};


export type MutationRemoveOrganizationMemberArgs = {
  memberId: Scalars['Float'];
  organizationId: Scalars['Float'];
};


export type MutationCreateTeamArgs = {
  organizationId: Scalars['Float'];
};


export type MutationUpdateTeamArgs = {
  team: TeamInputType;
  organizationId: Scalars['Float'];
};


export type MutationDeleteTeamArgs = {
  teamId: Scalars['String'];
  organizationId: Scalars['Float'];
};


export type MutationSaveTeamMemberArgs = {
  teamMember: MemberInputType;
  teamId: Scalars['String'];
  organizationId: Scalars['Float'];
};


export type MutationDeleteTeamMemberArgs = {
  memberId: Scalars['Float'];
  teamId: Scalars['String'];
  organizationId: Scalars['Float'];
};


export type MutationSaveParticipantArgs = {
  participantInput?: Maybe<ParticipantInputType>;
};


export type MutationAddVoiceSampleForNonUserParticipantArgs = {
  name: Scalars['String'];
  url: Scalars['String'];
};


export type MutationAddVoiceSampleArgs = {
  id: Scalars['String'];
  text: Scalars['String'];
  url: Scalars['String'];
};


export type MutationDeleteVoiceSampleArgs = {
  id: Scalars['String'];
};


export type MutationCreateIntegrationAdminArgs = {
  userId: Scalars['Float'];
  type: Scalars['String'];
  token: Scalars['String'];
  organizationId: Scalars['Float'];
};


export type MutationSetWebhookAdminArgs = {
  integrationId: Scalars['Float'];
  type: Scalars['String'];
};


export type MutationReactivateAircallWebhookAdminArgs = {
  integrationId: Scalars['Float'];
};


export type MutationCreateOrganizationAdminArgs = {
  name: Scalars['String'];
};


export type MutationMeetingsAdminArgs = {
  userId: Scalars['Float'];
};


export type MutationSwitchMemberFromOrganizationAdminArgs = {
  organizationId: Scalars['Float'];
  role: Scalars['String'];
  userId: Scalars['Float'];
};


export type MutationCreateUserAdminArgs = {
  voiceSampleText?: Maybe<Scalars['String']>;
  voiceSample?: Maybe<Scalars['String']>;
  organizationRole: Scalars['String'];
  organizationId: Scalars['Float'];
  username: Scalars['String'];
  email: Scalars['String'];
};

export type UsernamePasswordInput = {
  email: Scalars['String'];
  username: Scalars['String'];
  password: Scalars['String'];
};

export type GuestLoginResponse = {
  __typename?: 'GuestLoginResponse';
  guestToken: Scalars['String'];
};

export type NotesInput = {
  id: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  checked?: Maybe<Scalars['Boolean']>;
  children?: Maybe<Array<Scalars['JSONObject']>>;
  data?: Maybe<Array<Scalars['JSONObject']>>;
  updatedAtInRecording: Array<Scalars['Float']>;
};

export type PropertyInput = {
  id: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  generated?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Array<PropertyOptionInput>>;
  options?: Maybe<Array<PropertyOptionInput>>;
};

export type PropertyOptionInput = {
  text: Scalars['String'];
  color?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type TemplateRefInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};


export type MultiPartUpload = {
  __typename?: 'MultiPartUpload';
  records: Array<SignedRecords>;
  uploadId: Scalars['String'];
};

export type SignedRecords = {
  __typename?: 'SignedRecords';
  signedRequest: Scalars['String'];
  id: Scalars['Float'];
};

export type Part = {
  ETag: Scalars['String'];
  PartNumber: Scalars['Float'];
};

export type SignedUrlResponse = {
  __typename?: 'SignedUrlResponse';
  signedRequest: Scalars['String'];
  url: Scalars['String'];
};

export type TurnInput = {
  id: Scalars['Int'];
  speaker: Scalars['String'];
  text: Scalars['String'];
  words?: Maybe<Array<WordInput>>;
  timestamp: Scalars['String'];
  url: Scalars['String'];
  metadata: TurnMetaDataInput;
  inferences?: Maybe<TurnDataAnalysisInput>;
};

export type WordInput = {
  id: Scalars['Float'];
  word: Scalars['String'];
  start: Scalars['Float'];
  end: Scalars['Float'];
  inferences?: Maybe<WordInferenceInput>;
};

export type WordInferenceInput = {
  acts: WordActsInput;
  sentiment: Scalars['Float'];
  thematics?: Maybe<Array<Scalars['String']>>;
};

export type WordActsInput = {
  macro: Scalars['String'];
  detailed: Array<Scalars['String']>;
};

export type TurnMetaDataInput = {
  seqId: Scalars['Int'];
  start: Scalars['Float'];
  end: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  endedAt: Scalars['DateTime'];
  duration: Scalars['Float'];
};

export type TurnDataAnalysisInput = {
  sentiment: Scalars['String'];
};

export type SegmentsInput = {
  id: Scalars['Float'];
  url: Scalars['String'];
  start: Scalars['Float'];
  end: Scalars['Float'];
};

export type TopicOutput = {
  __typename?: 'TopicOutput';
  keyword: Scalars['String'];
  sentiment: Scalars['String'];
};

export type ReportOutput = {
  __typename?: 'ReportOutput';
  pageId: Scalars['String'];
  archivedPreviousPage: Scalars['Boolean'];
};

export type AccessOptions = {
  teamId?: Maybe<Scalars['String']>;
  participantsId?: Maybe<Array<Scalars['Float']>>;
};

export type SearchFiltersInput = {
  type: Scalars['String'];
  filters: Array<SearchFilters>;
};

export type SearchFilters = {
  id: Scalars['String'];
  key: Scalars['String'];
  relation: Scalars['String'];
  value: Array<Scalars['String']>;
};

export type SearchForAnalyticsOutput = {
  __typename?: 'SearchForAnalyticsOutput';
  turns?: Maybe<Array<TurnsForAnalyticsOutput>>;
};

export type TurnsForAnalyticsOutput = {
  __typename?: 'TurnsForAnalyticsOutput';
  id: Scalars['Int'];
  speaker: Scalars['String'];
  text: Scalars['String'];
  words?: Maybe<Array<Word>>;
  timestamp: Scalars['String'];
  url: Scalars['String'];
  metadata: TurnMetaData;
  inferences?: Maybe<TurnDataAnalysis>;
  meetingId: Scalars['String'];
  x: Scalars['Float'];
  y: Scalars['Float'];
  cluster: Scalars['Float'];
  topics?: Maybe<Array<Scalars['String']>>;
};

export type SearchOutput = {
  __typename?: 'SearchOutput';
  blocks?: Maybe<Array<NotesOutput>>;
  snippets: Array<SnippetOutput>;
  meetings: Array<Meeting>;
  turns?: Maybe<Array<TurnOutput>>;
};

export type NotesOutput = {
  __typename?: 'NotesOutput';
  id: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: Scalars['String'];
  updatedAtInRecording?: Maybe<Array<Scalars['Float']>>;
  children?: Maybe<Array<Children>>;
  checked?: Maybe<Scalars['Boolean']>;
  exports?: Maybe<NotesExports>;
  data?: Maybe<Array<Scalars['JSONObject']>>;
  meetingId: Scalars['String'];
};

export type SnippetOutput = {
  __typename?: 'SnippetOutput';
  id: Scalars['String'];
  shareableId?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  url: Scalars['String'];
  description: Scalars['String'];
  turnsIds: Array<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  duration: Scalars['Float'];
  start: Scalars['Float'];
  end: Scalars['Float'];
  owner: SnippetOwner;
  meetingId: Scalars['String'];
  sharedPageUrl?: Maybe<Scalars['String']>;
};

export type TurnOutput = {
  __typename?: 'TurnOutput';
  id: Scalars['Int'];
  speaker: Scalars['String'];
  text: Scalars['String'];
  words?: Maybe<Array<Word>>;
  timestamp: Scalars['String'];
  url: Scalars['String'];
  metadata: TurnMetaData;
  inferences?: Maybe<TurnDataAnalysis>;
  meetingId: Scalars['String'];
};

export type SearchCursorsInput = {
  latestMeetings: CursorsInput;
  latestSnippets: CursorsInput;
  blocks: CursorsInput;
  snippets: CursorsInput;
  turns: CursorsInput;
  notes: CursorsInput;
};

export type CursorsInput = {
  take: Scalars['Float'];
  skip: Scalars['Float'];
};

export type TeamInputType = {
  id: Scalars['String'];
  name: Scalars['String'];
  members: Array<MemberInputType>;
};

export type MemberInputType = {
  id: Scalars['Float'];
  role: Scalars['String'];
};

export type ParticipantInputType = {
  name: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  subscribeToMeeting: Turn;
  subscribeToQnA: SubscriptionOutput;
  subscribeToSummary: Notes;
};


export type SubscriptionSubscribeToMeetingArgs = {
  topic: Scalars['String'];
};


export type SubscriptionSubscribeToQnAArgs = {
  topic: Scalars['String'];
};


export type SubscriptionSubscribeToSummaryArgs = {
  topic: Scalars['String'];
};

export type SubscriptionOutput = {
  __typename?: 'SubscriptionOutput';
  qna: Array<Notes>;
};

export type RegularErrorFragment = (
  { __typename?: 'FieldError' }
  & Pick<FieldError, 'field' | 'message'>
);

export type RegularUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'username' | 'email' | 'userRights' | 'organizationId'>
  & { notion?: Maybe<(
    { __typename?: 'NotionIntegration' }
    & Pick<NotionIntegration, 'defaultParentPageId'>
  )> }
);

export type RegularUserResponseFragment = (
  { __typename?: 'UserResponse' }
  & { errors?: Maybe<Array<(
    { __typename?: 'FieldError' }
    & RegularErrorFragment
  )>>, user?: Maybe<(
    { __typename?: 'User' }
    & RegularUserFragment
  )> }
);

export type AcceptInvitationMutationVariables = Exact<{
  organizationId: Scalars['Float'];
  email: Scalars['String'];
}>;


export type AcceptInvitationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'acceptInvitation'>
);

export type AddVoiceSampleMutationVariables = Exact<{
  id: Scalars['String'];
  text: Scalars['String'];
  url: Scalars['String'];
}>;


export type AddVoiceSampleMutation = (
  { __typename?: 'Mutation' }
  & { addVoiceSample: (
    { __typename?: 'Participant' }
    & Pick<Participant, 'id'>
  ) }
);

export type ApplyToBetaMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ApplyToBetaMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'applyToBeta'>
);

export type ChangePasswordMutationVariables = Exact<{
  token: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & { changePassword: (
    { __typename?: 'UserResponse' }
    & RegularUserResponseFragment
  ) }
);

export type ConfirmUserMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type ConfirmUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'confirmUser'>
);

export type CreateTeamMutationVariables = Exact<{
  organizationId: Scalars['Float'];
}>;


export type CreateTeamMutation = (
  { __typename?: 'Mutation' }
  & { createTeam: (
    { __typename?: 'Team' }
    & Pick<Team, 'id' | 'name'>
    & { members: Array<(
      { __typename?: 'Member' }
      & Pick<Member, 'id' | 'role' | 'name' | 'email' | 'integrationTemplates'>
    )> }
  ) }
);

export type DeleteMeetingMutationVariables = Exact<{
  idMeeting: Scalars['String'];
}>;


export type DeleteMeetingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteMeeting'>
);

export type DeleteNotesTemplateMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteNotesTemplateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteNotesTemplate'>
);

export type DeletePropertyMutationVariables = Exact<{
  meetingId: Scalars['String'];
  propertyId: Scalars['String'];
}>;


export type DeletePropertyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteProperty'>
);

export type DeleteSavedSearchMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteSavedSearchMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSavedSearch'>
);

export type DeleteSnippetMutationVariables = Exact<{
  idMeeting: Scalars['String'];
  snippetId: Scalars['String'];
}>;


export type DeleteSnippetMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSnippet'>
);

export type DeleteSnippetLibraryMutationVariables = Exact<{
  id: Scalars['Float'];
}>;


export type DeleteSnippetLibraryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSnippetLibrary'>
);

export type DeleteTeamMutationVariables = Exact<{
  organizationId: Scalars['Float'];
  teamId: Scalars['String'];
}>;


export type DeleteTeamMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteTeam'>
);

export type DeleteTeamMemberMutationVariables = Exact<{
  organizationId: Scalars['Float'];
  teamId: Scalars['String'];
  memberId: Scalars['Float'];
}>;


export type DeleteTeamMemberMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteTeamMember'>
);

export type DeleteTemplatePropertyMutationVariables = Exact<{
  templateId: Scalars['String'];
  propertyId: Scalars['String'];
}>;


export type DeleteTemplatePropertyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteTemplateProperty'>
);

export type DeleteVoiceSampleMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteVoiceSampleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteVoiceSample'>
);

export type ExportReportToNotionMutationVariables = Exact<{
  meetingId: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
}>;


export type ExportReportToNotionMutation = (
  { __typename?: 'Mutation' }
  & { exportReportToNotion: (
    { __typename?: 'ReportOutput' }
    & Pick<ReportOutput, 'pageId' | 'archivedPreviousPage'>
  ) }
);

export type ExtractSnippetFromFullRecordingMutationVariables = Exact<{
  idMeeting: Scalars['String'];
  description: Scalars['String'];
  type: Scalars['String'];
  start: Scalars['Float'];
  end: Scalars['Float'];
  startIndex?: Maybe<Scalars['Float']>;
  endIndex?: Maybe<Scalars['Float']>;
}>;


export type ExtractSnippetFromFullRecordingMutation = (
  { __typename?: 'Mutation' }
  & { extractSnippetFromFullRecording: (
    { __typename?: 'Snippet' }
    & Pick<Snippet, 'id' | 'createdAt' | 'updatedAt' | 'description' | 'duration' | 'url' | 'start' | 'end' | 'type' | 'turnsIds'>
  ) }
);

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'forgotPassword'>
);

export type GetWordCloudsMutationVariables = Exact<{
  texts: Array<Scalars['String']>;
}>;


export type GetWordCloudsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'getWordClouds'>
);

export type GoogleRegisterOrLoginMutationVariables = Exact<{
  token: Scalars['String'];
  accessToken: Scalars['String'];
}>;


export type GoogleRegisterOrLoginMutation = (
  { __typename?: 'Mutation' }
  & { googleRegisterOrLogin: (
    { __typename?: 'UserResponse' }
    & RegularUserResponseFragment
  ) }
);

export type HasAuthorizationMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type HasAuthorizationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'hasAuthorization'>
);

export type InsertSavedSearchMutationVariables = Exact<{
  emoji: Scalars['String'];
  title: Scalars['String'];
  query: Scalars['String'];
  filters: SearchFiltersInput;
  order: Scalars['Int'];
  accessRightLevel: Scalars['String'];
}>;


export type InsertSavedSearchMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'insertSavedSearch'>
);

export type InsertSharedPageMutationVariables = Exact<{
  documentId: Scalars['String'];
  meetingId: Scalars['String'];
  type: Scalars['String'];
}>;


export type InsertSharedPageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'insertSharedPage'>
);

export type InsertSnippetLibraryMutationVariables = Exact<{
  title: Scalars['String'];
  emoji: Scalars['String'];
  accessRightLevel: Scalars['String'];
}>;


export type InsertSnippetLibraryMutation = (
  { __typename?: 'Mutation' }
  & { insertSnippetLibrary: (
    { __typename?: 'SnippetsLibrary' }
    & Pick<SnippetsLibrary, 'id' | 'title' | 'emoji'>
    & { accessRights: (
      { __typename?: 'AccessRights' }
      & Pick<AccessRights, 'accessRightLevel' | 'ownerId'>
    ) }
  ) }
);

export type LoginMutationVariables = Exact<{
  usernameOrEmail: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'UserResponse' }
    & RegularUserResponseFragment
  ) }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type MergeSegmentsMutationVariables = Exact<{
  segments: Array<SegmentsInput>;
  idMeeting: Scalars['String'];
  description: Scalars['String'];
  type: Scalars['String'];
}>;


export type MergeSegmentsMutation = (
  { __typename?: 'Mutation' }
  & { mergeSegments: (
    { __typename?: 'Snippet' }
    & Pick<Snippet, 'id' | 'url' | 'duration'>
  ) }
);

export type OnboardingMutationVariables = Exact<{ [key: string]: never; }>;


export type OnboardingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'onboarding'>
);

export type RegisterMutationVariables = Exact<{
  options: UsernamePasswordInput;
}>;


export type RegisterMutation = (
  { __typename?: 'Mutation' }
  & { register: (
    { __typename?: 'UserResponse' }
    & RegularUserResponseFragment
  ) }
);

export type RemoveOrganizationMemberMutationVariables = Exact<{
  organizationId: Scalars['Float'];
  memberId: Scalars['Float'];
}>;


export type RemoveOrganizationMemberMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeOrganizationMember'>
);

export type RevokeInvitationMutationVariables = Exact<{
  email: Scalars['String'];
  organizationId: Scalars['Float'];
}>;


export type RevokeInvitationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'revokeInvitation'>
);

export type SaveOrganizationMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type SaveOrganizationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'saveOrganization'>
);

export type SaveOrganizationMemberMutationVariables = Exact<{
  organizationId: Scalars['Float'];
  role: Scalars['String'];
  userId: Scalars['Float'];
}>;


export type SaveOrganizationMemberMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'saveOrganizationMember'>
);

export type SaveTeamMemberMutationVariables = Exact<{
  organizationId: Scalars['Float'];
  teamId: Scalars['String'];
  teamMember: MemberInputType;
}>;


export type SaveTeamMemberMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'saveTeamMember'>
);

export type SearchMutationVariables = Exact<{
  query: Scalars['String'];
  filters: SearchFiltersInput;
  cursors?: Maybe<SearchCursorsInput>;
  page?: Maybe<Scalars['Float']>;
}>;


export type SearchMutation = (
  { __typename?: 'Mutation' }
  & { search: (
    { __typename?: 'SearchOutput' }
    & { blocks?: Maybe<Array<(
      { __typename?: 'NotesOutput' }
      & Pick<NotesOutput, 'id' | 'type' | 'updatedAt' | 'updatedAtInRecording' | 'meetingId'>
      & { children?: Maybe<Array<(
        { __typename?: 'Children' }
        & Pick<Children, 'text'>
      )>> }
    )>>, snippets: Array<(
      { __typename?: 'SnippetOutput' }
      & Pick<SnippetOutput, 'type' | 'url' | 'updatedAt' | 'createdAt' | 'description' | 'meetingId' | 'turnsIds' | 'shareableId' | 'id' | 'duration' | 'start' | 'end'>
      & { owner: (
        { __typename?: 'SnippetOwner' }
        & Pick<SnippetOwner, 'id' | 'username'>
      ) }
    )>, meetings: Array<(
      { __typename?: 'Meeting' }
      & Pick<Meeting, 'id' | 'emoji' | 'createdAt' | 'title' | 'url'>
      & { exports?: Maybe<(
        { __typename?: 'MeetingExports' }
        & { notion?: Maybe<(
          { __typename?: 'NotionExport' }
          & Pick<NotionExport, 'reportsExportsCount' | 'id'>
        )> }
      )>, template: (
        { __typename?: 'MeetingTemplate' }
        & Pick<MeetingTemplate, 'id' | 'name'>
      ) }
    )>, turns?: Maybe<Array<(
      { __typename?: 'TurnOutput' }
      & Pick<TurnOutput, 'id' | 'text' | 'speaker' | 'timestamp' | 'url' | 'meetingId'>
      & { words?: Maybe<Array<(
        { __typename?: 'Word' }
        & Pick<Word, 'id' | 'start' | 'end' | 'word'>
        & { inferences?: Maybe<(
          { __typename?: 'WordInference' }
          & Pick<WordInference, 'sentiment' | 'thematics'>
          & { acts: (
            { __typename?: 'WordActs' }
            & Pick<WordActs, 'macro' | 'detailed'>
          ) }
        )> }
      )>>, inferences?: Maybe<(
        { __typename?: 'TurnDataAnalysis' }
        & Pick<TurnDataAnalysis, 'sentiment'>
      )>, metadata: (
        { __typename?: 'TurnMetaData' }
        & Pick<TurnMetaData, 'createdAt' | 'endedAt' | 'start' | 'end' | 'seqId' | 'duration'>
      ) }
    )>> }
  ) }
);

export type SearchForAnalyticsMutationVariables = Exact<{
  query: Scalars['String'];
  size?: Maybe<Scalars['Float']>;
  filters: SearchFiltersInput;
}>;


export type SearchForAnalyticsMutation = (
  { __typename?: 'Mutation' }
  & { searchForAnalytics: (
    { __typename?: 'SearchForAnalyticsOutput' }
    & { turns?: Maybe<Array<(
      { __typename?: 'TurnsForAnalyticsOutput' }
      & Pick<TurnsForAnalyticsOutput, 'meetingId' | 'id' | 'speaker' | 'text' | 'timestamp' | 'url' | 'x' | 'y' | 'cluster' | 'topics'>
      & { inferences?: Maybe<(
        { __typename?: 'TurnDataAnalysis' }
        & Pick<TurnDataAnalysis, 'sentiment'>
      )>, metadata: (
        { __typename?: 'TurnMetaData' }
        & Pick<TurnMetaData, 'createdAt' | 'start' | 'end'>
      ) }
    )>> }
  ) }
);

export type SendInvitationMutationVariables = Exact<{
  email: Scalars['String'];
  organizationId: Scalars['Float'];
}>;


export type SendInvitationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendInvitation'>
);

export type SendReportByEmailMutationVariables = Exact<{
  meetingId: Scalars['String'];
  title: Scalars['String'];
  message: Scalars['String'];
  to: Array<Scalars['String']>;
  cc?: Maybe<Array<Scalars['String']>>;
  bcc?: Maybe<Array<Scalars['String']>>;
}>;


export type SendReportByEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendReportByEmail'>
);

export type SetAircallTokenMutationVariables = Exact<{
  code: Scalars['String'];
  organizationId: Scalars['Float'];
}>;


export type SetAircallTokenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setAircallToken'>
);

export type SetHubSpotTokenMutationVariables = Exact<{
  code: Scalars['String'];
}>;


export type SetHubSpotTokenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setHubSpotToken'>
);

export type SetIsActiveMutationVariables = Exact<{
  id: Scalars['Float'];
  isActive: Scalars['Boolean'];
}>;


export type SetIsActiveMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setIsActive'>
);

export type SetMeetingPrivacyMutationVariables = Exact<{
  idMeeting: Scalars['String'];
  teamId?: Maybe<Scalars['String']>;
  accessRightLevel: Scalars['String'];
}>;


export type SetMeetingPrivacyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setMeetingPrivacy'>
);

export type SetMemberIntegrationTemplatesMutationVariables = Exact<{
  templateId: Scalars['String'];
  organizationId: Scalars['Float'];
  memberId: Scalars['Float'];
  integrationName: Scalars['String'];
}>;


export type SetMemberIntegrationTemplatesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setMemberIntegrationTemplates'>
);

export type SetNotesTemplateMutationVariables = Exact<{
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  emoji?: Maybe<Scalars['String']>;
  saveLocation?: Maybe<Scalars['String']>;
  properties?: Maybe<Array<PropertyInput>>;
  notes?: Maybe<Array<NotesInput>>;
  accessRightLevel?: Maybe<Scalars['String']>;
  accessOptions?: Maybe<AccessOptions>;
}>;


export type SetNotesTemplateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setNotesTemplate'>
);

export type SetNotesMutationVariables = Exact<{
  idMeeting: Scalars['String'];
  notes: Array<NotesInput>;
}>;


export type SetNotesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setNotes'>
);

export type SetNotionTokenMutationVariables = Exact<{
  code: Scalars['String'];
}>;


export type SetNotionTokenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setNotionToken'>
);

export type SetNotionWorkspaceIdMutationVariables = Exact<{
  defaultParentPageId: Scalars['String'];
}>;


export type SetNotionWorkspaceIdMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setNotionWorkspaceId'>
);

export type SetPropertiesMutationVariables = Exact<{
  id: Scalars['String'];
  properties: Array<PropertyInput>;
}>;


export type SetPropertiesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setProperties'>
);

export type SetTemplatePropertiesMutationVariables = Exact<{
  id: Scalars['String'];
  properties: Array<PropertyInput>;
}>;


export type SetTemplatePropertiesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setTemplateProperties'>
);

export type SetTitleAndEmojiMutationVariables = Exact<{
  idMeeting: Scalars['String'];
  title: Scalars['String'];
  emoji: Scalars['String'];
}>;


export type SetTitleAndEmojiMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setTitleAndEmoji'>
);

export type SignedReadToGcpMutationVariables = Exact<{
  fileName: Scalars['String'];
  hours?: Maybe<Scalars['Float']>;
}>;


export type SignedReadToGcpMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'signedReadGCP'>
);

export type TemporarySharedMeetingQueryVariables = Exact<{
  sharedPageId: Scalars['String'];
  token: Scalars['String'];
}>;


export type TemporarySharedMeetingQuery = (
  { __typename?: 'Query' }
  & { temporarySharedMeeting: (
    { __typename?: 'SharedPageOutput' }
    & Pick<SharedPageOutput, 'documentId' | 'type'>
    & { meeting: (
      { __typename?: 'Meeting' }
      & Pick<Meeting, 'id' | 'url' | 'title' | 'emoji' | 'createdAt'>
      & { recording?: Maybe<(
        { __typename?: 'Recording' }
        & Pick<Recording, 'updatedAt' | 'duration' | 'url'>
      )>, notes: Array<(
        { __typename?: 'Notes' }
        & Pick<Notes, 'id' | 'updatedAt' | 'type' | 'updatedAtInRecording' | 'checked'>
        & { children?: Maybe<Array<(
          { __typename?: 'Children' }
          & Pick<Children, 'text' | 'type' | 'checked' | 'data' | 'updatedAtInRecording'>
          & { children?: Maybe<Array<(
            { __typename?: 'Children' }
            & Pick<Children, 'text' | 'type' | 'checked' | 'data'>
          )>> }
        )>> }
      )>, snippets?: Maybe<Array<(
        { __typename?: 'Snippet' }
        & Pick<Snippet, 'id' | 'updatedAt' | 'type' | 'url' | 'description' | 'turnsIds' | 'duration' | 'start' | 'end' | 'createdAt'>
        & { owner: (
          { __typename?: 'SnippetOwner' }
          & Pick<SnippetOwner, 'id' | 'username'>
        ) }
      )>>, turns?: Maybe<Array<(
        { __typename?: 'Turn' }
        & Pick<Turn, 'id' | 'url' | 'speaker' | 'text' | 'timestamp'>
        & { metadata: (
          { __typename?: 'TurnMetaData' }
          & Pick<TurnMetaData, 'seqId' | 'start' | 'end' | 'createdAt' | 'endedAt' | 'duration'>
        ) }
      )>>, properties: Array<(
        { __typename?: 'Property' }
        & Pick<Property, 'id' | 'updatedAt' | 'name' | 'type' | 'generated'>
        & { content?: Maybe<Array<(
          { __typename?: 'PropertyOption' }
          & Pick<PropertyOption, 'text' | 'color'>
        )>>, options?: Maybe<Array<(
          { __typename?: 'PropertyOption' }
          & Pick<PropertyOption, 'text' | 'color'>
        )>> }
      )> }
    ) }
  ) }
);

export type TopicsMutationVariables = Exact<{
  meetingIds: Array<Scalars['String']>;
}>;


export type TopicsMutation = (
  { __typename?: 'Mutation' }
  & { topics: Array<(
    { __typename?: 'TopicOutput' }
    & Pick<TopicOutput, 'keyword' | 'sentiment'>
  )> }
);

export type UpdateSavedSearchMutationVariables = Exact<{
  id: Scalars['String'];
  emoji?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  filters?: Maybe<SearchFiltersInput>;
  order?: Maybe<Scalars['Int']>;
  accessRightLevel?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['Float']>;
}>;


export type UpdateSavedSearchMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateSavedSearch'>
);

export type UpdateSnippetMutationVariables = Exact<{
  idMeeting: Scalars['String'];
  snippetId: Scalars['String'];
  type: Scalars['String'];
  description: Scalars['String'];
}>;


export type UpdateSnippetMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateSnippet'>
);

export type UpdateSnippetLibraryMutationVariables = Exact<{
  id: Scalars['Float'];
  title: Scalars['String'];
  emoji: Scalars['String'];
  accessRightLevel: Scalars['String'];
}>;


export type UpdateSnippetLibraryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateSnippetLibrary'>
);

export type UpdateTeamMutationVariables = Exact<{
  organizationId: Scalars['Float'];
  team: TeamInputType;
}>;


export type UpdateTeamMutation = (
  { __typename?: 'Mutation' }
  & { updateTeam: (
    { __typename?: 'Team' }
    & Pick<Team, 'id' | 'name'>
    & { members: Array<(
      { __typename?: 'Member' }
      & Pick<Member, 'id' | 'role' | 'email' | 'integrationTemplates'>
    )> }
  ) }
);

export type SignedUploadToGcpMutationVariables = Exact<{
  fileName: Scalars['String'];
}>;


export type SignedUploadToGcpMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'signedUploadToGCP'>
);

export type CheckAccessQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type CheckAccessQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'checkAccess'>
);

export type CheckForInvitationQueryVariables = Exact<{ [key: string]: never; }>;


export type CheckForInvitationQuery = (
  { __typename?: 'Query' }
  & { checkForInvitation?: Maybe<(
    { __typename?: 'InvitationOutput' }
    & Pick<InvitationOutput, 'id' | 'name'>
  )> }
);

export type GetNotionDatabasesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetNotionDatabasesQuery = (
  { __typename?: 'Query' }
  & { getNotionDatabases: Array<(
    { __typename?: 'NotionDatabaseResponse' }
    & { database?: Maybe<(
      { __typename?: 'DatabaseIdentifiers' }
      & Pick<DatabaseIdentifiers, 'id' | 'name' | 'type'>
    )> }
  )> }
);

export type InvitationsSentFromOrganizationQueryVariables = Exact<{
  organizationId: Scalars['Float'];
}>;


export type InvitationsSentFromOrganizationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'invitationsSentFromOrganization'>
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & RegularUserFragment
  )> }
);

export type MeetingQueryVariables = Exact<{
  idMeeting: Scalars['String'];
}>;


export type MeetingQuery = (
  { __typename?: 'Query' }
  & { meeting: (
    { __typename?: 'Meeting' }
    & Pick<Meeting, 'id' | 'url' | 'title' | 'emoji' | 'createdAt'>
    & { template: (
      { __typename?: 'MeetingTemplate' }
      & Pick<MeetingTemplate, 'id' | 'name'>
    ), properties: Array<(
      { __typename?: 'Property' }
      & Pick<Property, 'id' | 'updatedAt' | 'name' | 'type' | 'generated'>
      & { content?: Maybe<Array<(
        { __typename?: 'PropertyOption' }
        & Pick<PropertyOption, 'text' | 'color'>
      )>>, options?: Maybe<Array<(
        { __typename?: 'PropertyOption' }
        & Pick<PropertyOption, 'text' | 'color' | 'description'>
      )>> }
    )>, notes: Array<(
      { __typename?: 'Notes' }
      & Pick<Notes, 'id' | 'updatedAt' | 'type' | 'updatedAtInRecording' | 'checked' | 'data'>
      & { children?: Maybe<Array<(
        { __typename?: 'Children' }
        & Pick<Children, 'text' | 'type' | 'checked' | 'data' | 'updatedAtInRecording'>
        & { children?: Maybe<Array<(
          { __typename?: 'Children' }
          & Pick<Children, 'text' | 'type' | 'checked' | 'data'>
        )>> }
      )>>, exports?: Maybe<(
        { __typename?: 'NotesExports' }
        & Pick<NotesExports, 'notionBlockId' | 'emailBlockId'>
      )> }
    )>, recording?: Maybe<(
      { __typename?: 'Recording' }
      & Pick<Recording, 'updatedAt' | 'duration' | 'url'>
    )>, snippets?: Maybe<Array<(
      { __typename?: 'Snippet' }
      & Pick<Snippet, 'id' | 'updatedAt' | 'type' | 'url' | 'description' | 'turnsIds' | 'duration' | 'start' | 'end' | 'createdAt'>
      & { owner: (
        { __typename?: 'SnippetOwner' }
        & Pick<SnippetOwner, 'id' | 'username'>
      ) }
    )>>, turns?: Maybe<Array<(
      { __typename?: 'Turn' }
      & Pick<Turn, 'id' | 'url' | 'speaker' | 'text' | 'timestamp'>
      & { words?: Maybe<Array<(
        { __typename?: 'Word' }
        & Pick<Word, 'id' | 'word' | 'start' | 'end'>
        & { inferences?: Maybe<(
          { __typename?: 'WordInference' }
          & Pick<WordInference, 'sentiment' | 'thematics'>
          & { acts: (
            { __typename?: 'WordActs' }
            & Pick<WordActs, 'macro' | 'detailed'>
          ) }
        )> }
      )>>, metadata: (
        { __typename?: 'TurnMetaData' }
        & Pick<TurnMetaData, 'seqId' | 'start' | 'end' | 'createdAt' | 'endedAt' | 'duration'>
      ) }
    )>>, exports?: Maybe<(
      { __typename?: 'MeetingExports' }
      & { notion?: Maybe<(
        { __typename?: 'NotionExport' }
        & Pick<NotionExport, 'id' | 'parentPageId' | 'reportsExportsCount'>
      )> }
    )>, metadata: (
      { __typename?: 'MeetingMetaData' }
      & Pick<MeetingMetaData, 'duration'>
      & { accessRights: (
        { __typename?: 'AccessRights' }
        & Pick<AccessRights, 'accessRightLevel' | 'ownerId' | 'teamId'>
      ) }
    ) }
  ) }
);

export type MemberTemplatesQueryVariables = Exact<{
  memberId: Scalars['Float'];
  organizationId: Scalars['Float'];
}>;


export type MemberTemplatesQuery = (
  { __typename?: 'Query' }
  & { memberTemplates: Array<(
    { __typename?: 'MemberTemplateOutput' }
    & Pick<MemberTemplateOutput, 'id' | 'emoji' | 'title'>
  )> }
);

export type NotesTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type NotesTemplatesQuery = (
  { __typename?: 'Query' }
  & { notesTemplates: Array<(
    { __typename?: 'NoteTemplate' }
    & Pick<NoteTemplate, 'id' | 'emoji' | 'title'>
    & { notes?: Maybe<Array<(
      { __typename?: 'Notes' }
      & Pick<Notes, 'id' | 'updatedAt' | 'checked' | 'type'>
      & { children?: Maybe<Array<(
        { __typename?: 'Children' }
        & Pick<Children, 'text' | 'type'>
        & { children?: Maybe<Array<(
          { __typename?: 'Children' }
          & Pick<Children, 'text' | 'type'>
          & { children?: Maybe<Array<(
            { __typename?: 'Children' }
            & Pick<Children, 'text' | 'type'>
            & { children?: Maybe<Array<(
              { __typename?: 'Children' }
              & Pick<Children, 'text' | 'type'>
              & { children?: Maybe<Array<(
                { __typename?: 'Children' }
                & Pick<Children, 'text' | 'type'>
                & { children?: Maybe<Array<(
                  { __typename?: 'Children' }
                  & Pick<Children, 'text' | 'type'>
                  & { children?: Maybe<Array<(
                    { __typename?: 'Children' }
                    & Pick<Children, 'text' | 'type'>
                    & { children?: Maybe<Array<(
                      { __typename?: 'Children' }
                      & Pick<Children, 'text' | 'type'>
                      & { children?: Maybe<Array<(
                        { __typename?: 'Children' }
                        & Pick<Children, 'text' | 'type'>
                        & { children?: Maybe<Array<(
                          { __typename?: 'Children' }
                          & Pick<Children, 'text' | 'type'>
                          & { children?: Maybe<Array<(
                            { __typename?: 'Children' }
                            & Pick<Children, 'text' | 'type'>
                            & { children?: Maybe<Array<(
                              { __typename?: 'Children' }
                              & Pick<Children, 'text' | 'type'>
                              & { children?: Maybe<Array<(
                                { __typename?: 'Children' }
                                & Pick<Children, 'text' | 'type'>
                                & { children?: Maybe<Array<(
                                  { __typename?: 'Children' }
                                  & Pick<Children, 'text' | 'type'>
                                  & { children?: Maybe<Array<(
                                    { __typename?: 'Children' }
                                    & Pick<Children, 'text' | 'type'>
                                    & { children?: Maybe<Array<(
                                      { __typename?: 'Children' }
                                      & Pick<Children, 'text' | 'type'>
                                    )>> }
                                  )>> }
                                )>> }
                              )>> }
                            )>> }
                          )>> }
                        )>> }
                      )>> }
                    )>> }
                  )>> }
                )>> }
              )>> }
            )>> }
          )>> }
        )>> }
      )>> }
    )>>, properties?: Maybe<Array<(
      { __typename?: 'Property' }
      & Pick<Property, 'id' | 'type' | 'name' | 'generated'>
      & { content?: Maybe<Array<(
        { __typename?: 'PropertyOption' }
        & Pick<PropertyOption, 'text' | 'color'>
      )>>, options?: Maybe<Array<(
        { __typename?: 'PropertyOption' }
        & Pick<PropertyOption, 'text' | 'color' | 'description'>
      )>> }
    )>>, accessRights: (
      { __typename?: 'AccessRights' }
      & Pick<AccessRights, 'accessRightLevel' | 'ownerId' | 'teamId'>
    ), exports?: Maybe<(
      { __typename?: 'TemplateExport' }
      & { notion?: Maybe<(
        { __typename?: 'NotionTemplateExport' }
        & Pick<NotionTemplateExport, 'parentPageId'>
      )> }
    )> }
  )> }
);

export type NotionWorkspacesQueryVariables = Exact<{ [key: string]: never; }>;


export type NotionWorkspacesQuery = (
  { __typename?: 'Query' }
  & { notionWorkspaces: Array<(
    { __typename?: 'NotionDatabaseResponse' }
    & { database?: Maybe<(
      { __typename?: 'DatabaseIdentifiers' }
      & Pick<DatabaseIdentifiers, 'id' | 'name' | 'type'>
    )> }
  )> }
);

export type OrganizationQueryVariables = Exact<{
  id: Scalars['Float'];
}>;


export type OrganizationQuery = (
  { __typename?: 'Query' }
  & { organization: (
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
    & { members: Array<(
      { __typename?: 'Member' }
      & Pick<Member, 'id' | 'role' | 'email' | 'name' | 'integrationTemplates'>
    )>, teams: Array<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name'>
      & { members: Array<(
        { __typename?: 'Member' }
        & Pick<Member, 'id' | 'role' | 'email' | 'name'>
      )> }
    )>, integrations: Array<(
      { __typename?: 'Integration' }
      & Pick<Integration, 'id' | 'isActive' | 'type'>
    )> }
  ) }
);

export type ParticipantQueryVariables = Exact<{ [key: string]: never; }>;


export type ParticipantQuery = (
  { __typename?: 'Query' }
  & { participant: (
    { __typename?: 'Participant' }
    & Pick<Participant, 'id'>
    & { voiceSamples: Array<(
      { __typename?: 'VoiceSample' }
      & Pick<VoiceSample, 'id' | 'url' | 'text'>
    )> }
  ) }
);

export type ParticipantsQueryVariables = Exact<{ [key: string]: never; }>;


export type ParticipantsQuery = (
  { __typename?: 'Query' }
  & { participants: Array<(
    { __typename?: 'Participant' }
    & Pick<Participant, 'id' | 'name'>
  )> }
);

export type SavedSearchsQueryVariables = Exact<{ [key: string]: never; }>;


export type SavedSearchsQuery = (
  { __typename?: 'Query' }
  & { savedSearchs: Array<(
    { __typename?: 'Searchs' }
    & Pick<Searchs, 'id' | 'query' | 'type' | 'emoji' | 'title' | 'order'>
    & { accessRights: (
      { __typename?: 'AccessRights' }
      & Pick<AccessRights, 'accessRightLevel' | 'ownerId'>
    ), filters: Array<(
      { __typename?: 'Filter' }
      & Pick<Filter, 'id' | 'key' | 'relation' | 'value'>
    )> }
  )> }
);

export type SharedPageQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type SharedPageQuery = (
  { __typename?: 'Query' }
  & { sharedPage?: Maybe<(
    { __typename?: 'SharedPage' }
    & Pick<SharedPage, 'documentId' | 'type' | 'meetingId'>
  )> }
);

export type SnippetsLibrariesQueryVariables = Exact<{ [key: string]: never; }>;


export type SnippetsLibrariesQuery = (
  { __typename?: 'Query' }
  & { snippetsLibraries?: Maybe<Array<(
    { __typename?: 'SnippetsLibrary' }
    & Pick<SnippetsLibrary, 'id' | 'title' | 'emoji'>
    & { accessRights: (
      { __typename?: 'AccessRights' }
      & Pick<AccessRights, 'accessRightLevel' | 'ownerId'>
    ) }
  )>> }
);

export type SpeakerTimeQueryVariables = Exact<{
  meetingId: Scalars['String'];
  speaker: Scalars['String'];
}>;


export type SpeakerTimeQuery = (
  { __typename?: 'Query' }
  & { speakerTime: (
    { __typename?: 'SpeakerTimeOutput' }
    & Pick<SpeakerTimeOutput, 'spokenTimePercentThisMeeting' | 'spokenTimePercentSimilarMeetings'>
  ) }
);

export type TeamQueryVariables = Exact<{
  teamId: Scalars['String'];
}>;


export type TeamQuery = (
  { __typename?: 'Query' }
  & { team: (
    { __typename?: 'Team' }
    & Pick<Team, 'name'>
  ) }
);

export type TimelinesClustersQueryVariables = Exact<{
  idMeeting: Scalars['String'];
}>;


export type TimelinesClustersQuery = (
  { __typename?: 'Query' }
  & { timelinesClusters: Array<(
    { __typename?: 'ClusteredTimeline' }
    & Pick<ClusteredTimeline, 'block' | 'turns' | 'updatedAtInRecording'>
  )> }
);

export type UserIntegrationsQueryVariables = Exact<{ [key: string]: never; }>;


export type UserIntegrationsQuery = (
  { __typename?: 'Query' }
  & { userIntegrations: Array<(
    { __typename?: 'Integration' }
    & Pick<Integration, 'id' | 'type' | 'token' | 'updatedAt' | 'isActive'>
  )> }
);

export type UserTeamsQueryVariables = Exact<{ [key: string]: never; }>;


export type UserTeamsQuery = (
  { __typename?: 'Query' }
  & { userTeams?: Maybe<(
    { __typename?: 'UserTeams' }
    & Pick<UserTeams, 'organizationId'>
    & { teams?: Maybe<Array<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name'>
    )>> }
  )> }
);

export type SubscribeToMeetingSubscriptionVariables = Exact<{
  topic: Scalars['String'];
}>;


export type SubscribeToMeetingSubscription = (
  { __typename?: 'Subscription' }
  & { subscribeToMeeting: (
    { __typename?: 'Turn' }
    & Pick<Turn, 'id' | 'speaker' | 'text' | 'timestamp' | 'url'>
    & { words?: Maybe<Array<(
      { __typename?: 'Word' }
      & Pick<Word, 'id' | 'word' | 'start' | 'end'>
      & { inferences?: Maybe<(
        { __typename?: 'WordInference' }
        & Pick<WordInference, 'sentiment' | 'thematics'>
        & { acts: (
          { __typename?: 'WordActs' }
          & Pick<WordActs, 'macro' | 'detailed'>
        ) }
      )> }
    )>>, metadata: (
      { __typename?: 'TurnMetaData' }
      & Pick<TurnMetaData, 'seqId' | 'start' | 'end' | 'duration' | 'createdAt' | 'endedAt'>
    ) }
  ) }
);

export const RegularErrorFragmentDoc = gql`
    fragment RegularError on FieldError {
  field
  message
}
    `;
export const RegularUserFragmentDoc = gql`
    fragment RegularUser on User {
  id
  username
  email
  userRights
  notion {
    defaultParentPageId
  }
  organizationId
}
    `;
export const RegularUserResponseFragmentDoc = gql`
    fragment RegularUserResponse on UserResponse {
  errors {
    ...RegularError
  }
  user {
    ...RegularUser
  }
}
    ${RegularErrorFragmentDoc}
${RegularUserFragmentDoc}`;
export const AcceptInvitationDocument = gql`
    mutation AcceptInvitation($organizationId: Float!, $email: String!) {
  acceptInvitation(organizationId: $organizationId, email: $email)
}
    `;
export type AcceptInvitationMutationFn = Apollo.MutationFunction<AcceptInvitationMutation, AcceptInvitationMutationVariables>;

/**
 * __useAcceptInvitationMutation__
 *
 * To run a mutation, you first call `useAcceptInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInvitationMutation, { data, loading, error }] = useAcceptInvitationMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useAcceptInvitationMutation(baseOptions?: Apollo.MutationHookOptions<AcceptInvitationMutation, AcceptInvitationMutationVariables>) {
        return Apollo.useMutation<AcceptInvitationMutation, AcceptInvitationMutationVariables>(AcceptInvitationDocument, baseOptions);
      }
export type AcceptInvitationMutationHookResult = ReturnType<typeof useAcceptInvitationMutation>;
export type AcceptInvitationMutationResult = Apollo.MutationResult<AcceptInvitationMutation>;
export type AcceptInvitationMutationOptions = Apollo.BaseMutationOptions<AcceptInvitationMutation, AcceptInvitationMutationVariables>;
export const AddVoiceSampleDocument = gql`
    mutation AddVoiceSample($id: String!, $text: String!, $url: String!) {
  addVoiceSample(id: $id, text: $text, url: $url) {
    id
  }
}
    `;
export type AddVoiceSampleMutationFn = Apollo.MutationFunction<AddVoiceSampleMutation, AddVoiceSampleMutationVariables>;

/**
 * __useAddVoiceSampleMutation__
 *
 * To run a mutation, you first call `useAddVoiceSampleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVoiceSampleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVoiceSampleMutation, { data, loading, error }] = useAddVoiceSampleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      text: // value for 'text'
 *      url: // value for 'url'
 *   },
 * });
 */
export function useAddVoiceSampleMutation(baseOptions?: Apollo.MutationHookOptions<AddVoiceSampleMutation, AddVoiceSampleMutationVariables>) {
        return Apollo.useMutation<AddVoiceSampleMutation, AddVoiceSampleMutationVariables>(AddVoiceSampleDocument, baseOptions);
      }
export type AddVoiceSampleMutationHookResult = ReturnType<typeof useAddVoiceSampleMutation>;
export type AddVoiceSampleMutationResult = Apollo.MutationResult<AddVoiceSampleMutation>;
export type AddVoiceSampleMutationOptions = Apollo.BaseMutationOptions<AddVoiceSampleMutation, AddVoiceSampleMutationVariables>;
export const ApplyToBetaDocument = gql`
    mutation ApplyToBeta($email: String!) {
  applyToBeta(email: $email)
}
    `;
export type ApplyToBetaMutationFn = Apollo.MutationFunction<ApplyToBetaMutation, ApplyToBetaMutationVariables>;

/**
 * __useApplyToBetaMutation__
 *
 * To run a mutation, you first call `useApplyToBetaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyToBetaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyToBetaMutation, { data, loading, error }] = useApplyToBetaMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useApplyToBetaMutation(baseOptions?: Apollo.MutationHookOptions<ApplyToBetaMutation, ApplyToBetaMutationVariables>) {
        return Apollo.useMutation<ApplyToBetaMutation, ApplyToBetaMutationVariables>(ApplyToBetaDocument, baseOptions);
      }
export type ApplyToBetaMutationHookResult = ReturnType<typeof useApplyToBetaMutation>;
export type ApplyToBetaMutationResult = Apollo.MutationResult<ApplyToBetaMutation>;
export type ApplyToBetaMutationOptions = Apollo.BaseMutationOptions<ApplyToBetaMutation, ApplyToBetaMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($token: String!, $newPassword: String!) {
  changePassword(token: $token, newPassword: $newPassword) {
    ...RegularUserResponse
  }
}
    ${RegularUserResponseFragmentDoc}`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, baseOptions);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const ConfirmUserDocument = gql`
    mutation confirmUser($token: String!) {
  confirmUser(token: $token)
}
    `;
export type ConfirmUserMutationFn = Apollo.MutationFunction<ConfirmUserMutation, ConfirmUserMutationVariables>;

/**
 * __useConfirmUserMutation__
 *
 * To run a mutation, you first call `useConfirmUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmUserMutation, { data, loading, error }] = useConfirmUserMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useConfirmUserMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmUserMutation, ConfirmUserMutationVariables>) {
        return Apollo.useMutation<ConfirmUserMutation, ConfirmUserMutationVariables>(ConfirmUserDocument, baseOptions);
      }
export type ConfirmUserMutationHookResult = ReturnType<typeof useConfirmUserMutation>;
export type ConfirmUserMutationResult = Apollo.MutationResult<ConfirmUserMutation>;
export type ConfirmUserMutationOptions = Apollo.BaseMutationOptions<ConfirmUserMutation, ConfirmUserMutationVariables>;
export const CreateTeamDocument = gql`
    mutation CreateTeam($organizationId: Float!) {
  createTeam(organizationId: $organizationId) {
    id
    name
    members {
      id
      role
      name
      email
      integrationTemplates
    }
  }
}
    `;
export type CreateTeamMutationFn = Apollo.MutationFunction<CreateTeamMutation, CreateTeamMutationVariables>;

/**
 * __useCreateTeamMutation__
 *
 * To run a mutation, you first call `useCreateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamMutation, { data, loading, error }] = useCreateTeamMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useCreateTeamMutation(baseOptions?: Apollo.MutationHookOptions<CreateTeamMutation, CreateTeamMutationVariables>) {
        return Apollo.useMutation<CreateTeamMutation, CreateTeamMutationVariables>(CreateTeamDocument, baseOptions);
      }
export type CreateTeamMutationHookResult = ReturnType<typeof useCreateTeamMutation>;
export type CreateTeamMutationResult = Apollo.MutationResult<CreateTeamMutation>;
export type CreateTeamMutationOptions = Apollo.BaseMutationOptions<CreateTeamMutation, CreateTeamMutationVariables>;
export const DeleteMeetingDocument = gql`
    mutation DeleteMeeting($idMeeting: String!) {
  deleteMeeting(idMeeting: $idMeeting)
}
    `;
export type DeleteMeetingMutationFn = Apollo.MutationFunction<DeleteMeetingMutation, DeleteMeetingMutationVariables>;

/**
 * __useDeleteMeetingMutation__
 *
 * To run a mutation, you first call `useDeleteMeetingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMeetingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMeetingMutation, { data, loading, error }] = useDeleteMeetingMutation({
 *   variables: {
 *      idMeeting: // value for 'idMeeting'
 *   },
 * });
 */
export function useDeleteMeetingMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMeetingMutation, DeleteMeetingMutationVariables>) {
        return Apollo.useMutation<DeleteMeetingMutation, DeleteMeetingMutationVariables>(DeleteMeetingDocument, baseOptions);
      }
export type DeleteMeetingMutationHookResult = ReturnType<typeof useDeleteMeetingMutation>;
export type DeleteMeetingMutationResult = Apollo.MutationResult<DeleteMeetingMutation>;
export type DeleteMeetingMutationOptions = Apollo.BaseMutationOptions<DeleteMeetingMutation, DeleteMeetingMutationVariables>;
export const DeleteNotesTemplateDocument = gql`
    mutation DeleteNotesTemplate($id: String!) {
  deleteNotesTemplate(id: $id)
}
    `;
export type DeleteNotesTemplateMutationFn = Apollo.MutationFunction<DeleteNotesTemplateMutation, DeleteNotesTemplateMutationVariables>;

/**
 * __useDeleteNotesTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteNotesTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNotesTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNotesTemplateMutation, { data, loading, error }] = useDeleteNotesTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNotesTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNotesTemplateMutation, DeleteNotesTemplateMutationVariables>) {
        return Apollo.useMutation<DeleteNotesTemplateMutation, DeleteNotesTemplateMutationVariables>(DeleteNotesTemplateDocument, baseOptions);
      }
export type DeleteNotesTemplateMutationHookResult = ReturnType<typeof useDeleteNotesTemplateMutation>;
export type DeleteNotesTemplateMutationResult = Apollo.MutationResult<DeleteNotesTemplateMutation>;
export type DeleteNotesTemplateMutationOptions = Apollo.BaseMutationOptions<DeleteNotesTemplateMutation, DeleteNotesTemplateMutationVariables>;
export const DeletePropertyDocument = gql`
    mutation DeleteProperty($meetingId: String!, $propertyId: String!) {
  deleteProperty(meetingId: $meetingId, propertyId: $propertyId)
}
    `;
export type DeletePropertyMutationFn = Apollo.MutationFunction<DeletePropertyMutation, DeletePropertyMutationVariables>;

/**
 * __useDeletePropertyMutation__
 *
 * To run a mutation, you first call `useDeletePropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePropertyMutation, { data, loading, error }] = useDeletePropertyMutation({
 *   variables: {
 *      meetingId: // value for 'meetingId'
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useDeletePropertyMutation(baseOptions?: Apollo.MutationHookOptions<DeletePropertyMutation, DeletePropertyMutationVariables>) {
        return Apollo.useMutation<DeletePropertyMutation, DeletePropertyMutationVariables>(DeletePropertyDocument, baseOptions);
      }
export type DeletePropertyMutationHookResult = ReturnType<typeof useDeletePropertyMutation>;
export type DeletePropertyMutationResult = Apollo.MutationResult<DeletePropertyMutation>;
export type DeletePropertyMutationOptions = Apollo.BaseMutationOptions<DeletePropertyMutation, DeletePropertyMutationVariables>;
export const DeleteSavedSearchDocument = gql`
    mutation DeleteSavedSearch($id: String!) {
  deleteSavedSearch(id: $id)
}
    `;
export type DeleteSavedSearchMutationFn = Apollo.MutationFunction<DeleteSavedSearchMutation, DeleteSavedSearchMutationVariables>;

/**
 * __useDeleteSavedSearchMutation__
 *
 * To run a mutation, you first call `useDeleteSavedSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSavedSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSavedSearchMutation, { data, loading, error }] = useDeleteSavedSearchMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSavedSearchMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSavedSearchMutation, DeleteSavedSearchMutationVariables>) {
        return Apollo.useMutation<DeleteSavedSearchMutation, DeleteSavedSearchMutationVariables>(DeleteSavedSearchDocument, baseOptions);
      }
export type DeleteSavedSearchMutationHookResult = ReturnType<typeof useDeleteSavedSearchMutation>;
export type DeleteSavedSearchMutationResult = Apollo.MutationResult<DeleteSavedSearchMutation>;
export type DeleteSavedSearchMutationOptions = Apollo.BaseMutationOptions<DeleteSavedSearchMutation, DeleteSavedSearchMutationVariables>;
export const DeleteSnippetDocument = gql`
    mutation DeleteSnippet($idMeeting: String!, $snippetId: String!) {
  deleteSnippet(idMeeting: $idMeeting, snippetId: $snippetId)
}
    `;
export type DeleteSnippetMutationFn = Apollo.MutationFunction<DeleteSnippetMutation, DeleteSnippetMutationVariables>;

/**
 * __useDeleteSnippetMutation__
 *
 * To run a mutation, you first call `useDeleteSnippetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSnippetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSnippetMutation, { data, loading, error }] = useDeleteSnippetMutation({
 *   variables: {
 *      idMeeting: // value for 'idMeeting'
 *      snippetId: // value for 'snippetId'
 *   },
 * });
 */
export function useDeleteSnippetMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSnippetMutation, DeleteSnippetMutationVariables>) {
        return Apollo.useMutation<DeleteSnippetMutation, DeleteSnippetMutationVariables>(DeleteSnippetDocument, baseOptions);
      }
export type DeleteSnippetMutationHookResult = ReturnType<typeof useDeleteSnippetMutation>;
export type DeleteSnippetMutationResult = Apollo.MutationResult<DeleteSnippetMutation>;
export type DeleteSnippetMutationOptions = Apollo.BaseMutationOptions<DeleteSnippetMutation, DeleteSnippetMutationVariables>;
export const DeleteSnippetLibraryDocument = gql`
    mutation DeleteSnippetLibrary($id: Float!) {
  deleteSnippetLibrary(id: $id)
}
    `;
export type DeleteSnippetLibraryMutationFn = Apollo.MutationFunction<DeleteSnippetLibraryMutation, DeleteSnippetLibraryMutationVariables>;

/**
 * __useDeleteSnippetLibraryMutation__
 *
 * To run a mutation, you first call `useDeleteSnippetLibraryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSnippetLibraryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSnippetLibraryMutation, { data, loading, error }] = useDeleteSnippetLibraryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSnippetLibraryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSnippetLibraryMutation, DeleteSnippetLibraryMutationVariables>) {
        return Apollo.useMutation<DeleteSnippetLibraryMutation, DeleteSnippetLibraryMutationVariables>(DeleteSnippetLibraryDocument, baseOptions);
      }
export type DeleteSnippetLibraryMutationHookResult = ReturnType<typeof useDeleteSnippetLibraryMutation>;
export type DeleteSnippetLibraryMutationResult = Apollo.MutationResult<DeleteSnippetLibraryMutation>;
export type DeleteSnippetLibraryMutationOptions = Apollo.BaseMutationOptions<DeleteSnippetLibraryMutation, DeleteSnippetLibraryMutationVariables>;
export const DeleteTeamDocument = gql`
    mutation DeleteTeam($organizationId: Float!, $teamId: String!) {
  deleteTeam(organizationId: $organizationId, teamId: $teamId)
}
    `;
export type DeleteTeamMutationFn = Apollo.MutationFunction<DeleteTeamMutation, DeleteTeamMutationVariables>;

/**
 * __useDeleteTeamMutation__
 *
 * To run a mutation, you first call `useDeleteTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamMutation, { data, loading, error }] = useDeleteTeamMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useDeleteTeamMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTeamMutation, DeleteTeamMutationVariables>) {
        return Apollo.useMutation<DeleteTeamMutation, DeleteTeamMutationVariables>(DeleteTeamDocument, baseOptions);
      }
export type DeleteTeamMutationHookResult = ReturnType<typeof useDeleteTeamMutation>;
export type DeleteTeamMutationResult = Apollo.MutationResult<DeleteTeamMutation>;
export type DeleteTeamMutationOptions = Apollo.BaseMutationOptions<DeleteTeamMutation, DeleteTeamMutationVariables>;
export const DeleteTeamMemberDocument = gql`
    mutation DeleteTeamMember($organizationId: Float!, $teamId: String!, $memberId: Float!) {
  deleteTeamMember(
    organizationId: $organizationId
    teamId: $teamId
    memberId: $memberId
  )
}
    `;
export type DeleteTeamMemberMutationFn = Apollo.MutationFunction<DeleteTeamMemberMutation, DeleteTeamMemberMutationVariables>;

/**
 * __useDeleteTeamMemberMutation__
 *
 * To run a mutation, you first call `useDeleteTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamMemberMutation, { data, loading, error }] = useDeleteTeamMemberMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      teamId: // value for 'teamId'
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useDeleteTeamMemberMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTeamMemberMutation, DeleteTeamMemberMutationVariables>) {
        return Apollo.useMutation<DeleteTeamMemberMutation, DeleteTeamMemberMutationVariables>(DeleteTeamMemberDocument, baseOptions);
      }
export type DeleteTeamMemberMutationHookResult = ReturnType<typeof useDeleteTeamMemberMutation>;
export type DeleteTeamMemberMutationResult = Apollo.MutationResult<DeleteTeamMemberMutation>;
export type DeleteTeamMemberMutationOptions = Apollo.BaseMutationOptions<DeleteTeamMemberMutation, DeleteTeamMemberMutationVariables>;
export const DeleteTemplatePropertyDocument = gql`
    mutation DeleteTemplateProperty($templateId: String!, $propertyId: String!) {
  deleteTemplateProperty(templateId: $templateId, propertyId: $propertyId)
}
    `;
export type DeleteTemplatePropertyMutationFn = Apollo.MutationFunction<DeleteTemplatePropertyMutation, DeleteTemplatePropertyMutationVariables>;

/**
 * __useDeleteTemplatePropertyMutation__
 *
 * To run a mutation, you first call `useDeleteTemplatePropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTemplatePropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTemplatePropertyMutation, { data, loading, error }] = useDeleteTemplatePropertyMutation({
 *   variables: {
 *      templateId: // value for 'templateId'
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useDeleteTemplatePropertyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTemplatePropertyMutation, DeleteTemplatePropertyMutationVariables>) {
        return Apollo.useMutation<DeleteTemplatePropertyMutation, DeleteTemplatePropertyMutationVariables>(DeleteTemplatePropertyDocument, baseOptions);
      }
export type DeleteTemplatePropertyMutationHookResult = ReturnType<typeof useDeleteTemplatePropertyMutation>;
export type DeleteTemplatePropertyMutationResult = Apollo.MutationResult<DeleteTemplatePropertyMutation>;
export type DeleteTemplatePropertyMutationOptions = Apollo.BaseMutationOptions<DeleteTemplatePropertyMutation, DeleteTemplatePropertyMutationVariables>;
export const DeleteVoiceSampleDocument = gql`
    mutation DeleteVoiceSample($id: String!) {
  deleteVoiceSample(id: $id)
}
    `;
export type DeleteVoiceSampleMutationFn = Apollo.MutationFunction<DeleteVoiceSampleMutation, DeleteVoiceSampleMutationVariables>;

/**
 * __useDeleteVoiceSampleMutation__
 *
 * To run a mutation, you first call `useDeleteVoiceSampleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVoiceSampleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVoiceSampleMutation, { data, loading, error }] = useDeleteVoiceSampleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteVoiceSampleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteVoiceSampleMutation, DeleteVoiceSampleMutationVariables>) {
        return Apollo.useMutation<DeleteVoiceSampleMutation, DeleteVoiceSampleMutationVariables>(DeleteVoiceSampleDocument, baseOptions);
      }
export type DeleteVoiceSampleMutationHookResult = ReturnType<typeof useDeleteVoiceSampleMutation>;
export type DeleteVoiceSampleMutationResult = Apollo.MutationResult<DeleteVoiceSampleMutation>;
export type DeleteVoiceSampleMutationOptions = Apollo.BaseMutationOptions<DeleteVoiceSampleMutation, DeleteVoiceSampleMutationVariables>;
export const ExportReportToNotionDocument = gql`
    mutation ExportReportToNotion($meetingId: String!, $parentId: String) {
  exportReportToNotion(meetingId: $meetingId, parentId: $parentId) {
    pageId
    archivedPreviousPage
  }
}
    `;
export type ExportReportToNotionMutationFn = Apollo.MutationFunction<ExportReportToNotionMutation, ExportReportToNotionMutationVariables>;

/**
 * __useExportReportToNotionMutation__
 *
 * To run a mutation, you first call `useExportReportToNotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportReportToNotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportReportToNotionMutation, { data, loading, error }] = useExportReportToNotionMutation({
 *   variables: {
 *      meetingId: // value for 'meetingId'
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useExportReportToNotionMutation(baseOptions?: Apollo.MutationHookOptions<ExportReportToNotionMutation, ExportReportToNotionMutationVariables>) {
        return Apollo.useMutation<ExportReportToNotionMutation, ExportReportToNotionMutationVariables>(ExportReportToNotionDocument, baseOptions);
      }
export type ExportReportToNotionMutationHookResult = ReturnType<typeof useExportReportToNotionMutation>;
export type ExportReportToNotionMutationResult = Apollo.MutationResult<ExportReportToNotionMutation>;
export type ExportReportToNotionMutationOptions = Apollo.BaseMutationOptions<ExportReportToNotionMutation, ExportReportToNotionMutationVariables>;
export const ExtractSnippetFromFullRecordingDocument = gql`
    mutation ExtractSnippetFromFullRecording($idMeeting: String!, $description: String!, $type: String!, $start: Float!, $end: Float!, $startIndex: Float, $endIndex: Float) {
  extractSnippetFromFullRecording(
    idMeeting: $idMeeting
    description: $description
    type: $type
    start: $start
    end: $end
    startIndex: $startIndex
    endIndex: $endIndex
  ) {
    id
    createdAt
    updatedAt
    description
    duration
    url
    start
    end
    type
    turnsIds
  }
}
    `;
export type ExtractSnippetFromFullRecordingMutationFn = Apollo.MutationFunction<ExtractSnippetFromFullRecordingMutation, ExtractSnippetFromFullRecordingMutationVariables>;

/**
 * __useExtractSnippetFromFullRecordingMutation__
 *
 * To run a mutation, you first call `useExtractSnippetFromFullRecordingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExtractSnippetFromFullRecordingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [extractSnippetFromFullRecordingMutation, { data, loading, error }] = useExtractSnippetFromFullRecordingMutation({
 *   variables: {
 *      idMeeting: // value for 'idMeeting'
 *      description: // value for 'description'
 *      type: // value for 'type'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      startIndex: // value for 'startIndex'
 *      endIndex: // value for 'endIndex'
 *   },
 * });
 */
export function useExtractSnippetFromFullRecordingMutation(baseOptions?: Apollo.MutationHookOptions<ExtractSnippetFromFullRecordingMutation, ExtractSnippetFromFullRecordingMutationVariables>) {
        return Apollo.useMutation<ExtractSnippetFromFullRecordingMutation, ExtractSnippetFromFullRecordingMutationVariables>(ExtractSnippetFromFullRecordingDocument, baseOptions);
      }
export type ExtractSnippetFromFullRecordingMutationHookResult = ReturnType<typeof useExtractSnippetFromFullRecordingMutation>;
export type ExtractSnippetFromFullRecordingMutationResult = Apollo.MutationResult<ExtractSnippetFromFullRecordingMutation>;
export type ExtractSnippetFromFullRecordingMutationOptions = Apollo.BaseMutationOptions<ExtractSnippetFromFullRecordingMutation, ExtractSnippetFromFullRecordingMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($email: String!) {
  forgotPassword(email: $email)
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, baseOptions);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const GetWordCloudsDocument = gql`
    mutation GetWordClouds($texts: [String!]!) {
  getWordClouds(texts: $texts)
}
    `;
export type GetWordCloudsMutationFn = Apollo.MutationFunction<GetWordCloudsMutation, GetWordCloudsMutationVariables>;

/**
 * __useGetWordCloudsMutation__
 *
 * To run a mutation, you first call `useGetWordCloudsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetWordCloudsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getWordCloudsMutation, { data, loading, error }] = useGetWordCloudsMutation({
 *   variables: {
 *      texts: // value for 'texts'
 *   },
 * });
 */
export function useGetWordCloudsMutation(baseOptions?: Apollo.MutationHookOptions<GetWordCloudsMutation, GetWordCloudsMutationVariables>) {
        return Apollo.useMutation<GetWordCloudsMutation, GetWordCloudsMutationVariables>(GetWordCloudsDocument, baseOptions);
      }
export type GetWordCloudsMutationHookResult = ReturnType<typeof useGetWordCloudsMutation>;
export type GetWordCloudsMutationResult = Apollo.MutationResult<GetWordCloudsMutation>;
export type GetWordCloudsMutationOptions = Apollo.BaseMutationOptions<GetWordCloudsMutation, GetWordCloudsMutationVariables>;
export const GoogleRegisterOrLoginDocument = gql`
    mutation GoogleRegisterOrLogin($token: String!, $accessToken: String!) {
  googleRegisterOrLogin(token: $token, accessToken: $accessToken) {
    ...RegularUserResponse
  }
}
    ${RegularUserResponseFragmentDoc}`;
export type GoogleRegisterOrLoginMutationFn = Apollo.MutationFunction<GoogleRegisterOrLoginMutation, GoogleRegisterOrLoginMutationVariables>;

/**
 * __useGoogleRegisterOrLoginMutation__
 *
 * To run a mutation, you first call `useGoogleRegisterOrLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGoogleRegisterOrLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [googleRegisterOrLoginMutation, { data, loading, error }] = useGoogleRegisterOrLoginMutation({
 *   variables: {
 *      token: // value for 'token'
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useGoogleRegisterOrLoginMutation(baseOptions?: Apollo.MutationHookOptions<GoogleRegisterOrLoginMutation, GoogleRegisterOrLoginMutationVariables>) {
        return Apollo.useMutation<GoogleRegisterOrLoginMutation, GoogleRegisterOrLoginMutationVariables>(GoogleRegisterOrLoginDocument, baseOptions);
      }
export type GoogleRegisterOrLoginMutationHookResult = ReturnType<typeof useGoogleRegisterOrLoginMutation>;
export type GoogleRegisterOrLoginMutationResult = Apollo.MutationResult<GoogleRegisterOrLoginMutation>;
export type GoogleRegisterOrLoginMutationOptions = Apollo.BaseMutationOptions<GoogleRegisterOrLoginMutation, GoogleRegisterOrLoginMutationVariables>;
export const HasAuthorizationDocument = gql`
    mutation hasAuthorization($email: String!) {
  hasAuthorization(email: $email)
}
    `;
export type HasAuthorizationMutationFn = Apollo.MutationFunction<HasAuthorizationMutation, HasAuthorizationMutationVariables>;

/**
 * __useHasAuthorizationMutation__
 *
 * To run a mutation, you first call `useHasAuthorizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHasAuthorizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hasAuthorizationMutation, { data, loading, error }] = useHasAuthorizationMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useHasAuthorizationMutation(baseOptions?: Apollo.MutationHookOptions<HasAuthorizationMutation, HasAuthorizationMutationVariables>) {
        return Apollo.useMutation<HasAuthorizationMutation, HasAuthorizationMutationVariables>(HasAuthorizationDocument, baseOptions);
      }
export type HasAuthorizationMutationHookResult = ReturnType<typeof useHasAuthorizationMutation>;
export type HasAuthorizationMutationResult = Apollo.MutationResult<HasAuthorizationMutation>;
export type HasAuthorizationMutationOptions = Apollo.BaseMutationOptions<HasAuthorizationMutation, HasAuthorizationMutationVariables>;
export const InsertSavedSearchDocument = gql`
    mutation InsertSavedSearch($emoji: String!, $title: String!, $query: String!, $filters: SearchFiltersInput!, $order: Int!, $accessRightLevel: String!) {
  insertSavedSearch(
    emoji: $emoji
    title: $title
    query: $query
    filters: $filters
    order: $order
    accessRightLevel: $accessRightLevel
  )
}
    `;
export type InsertSavedSearchMutationFn = Apollo.MutationFunction<InsertSavedSearchMutation, InsertSavedSearchMutationVariables>;

/**
 * __useInsertSavedSearchMutation__
 *
 * To run a mutation, you first call `useInsertSavedSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertSavedSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertSavedSearchMutation, { data, loading, error }] = useInsertSavedSearchMutation({
 *   variables: {
 *      emoji: // value for 'emoji'
 *      title: // value for 'title'
 *      query: // value for 'query'
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *      accessRightLevel: // value for 'accessRightLevel'
 *   },
 * });
 */
export function useInsertSavedSearchMutation(baseOptions?: Apollo.MutationHookOptions<InsertSavedSearchMutation, InsertSavedSearchMutationVariables>) {
        return Apollo.useMutation<InsertSavedSearchMutation, InsertSavedSearchMutationVariables>(InsertSavedSearchDocument, baseOptions);
      }
export type InsertSavedSearchMutationHookResult = ReturnType<typeof useInsertSavedSearchMutation>;
export type InsertSavedSearchMutationResult = Apollo.MutationResult<InsertSavedSearchMutation>;
export type InsertSavedSearchMutationOptions = Apollo.BaseMutationOptions<InsertSavedSearchMutation, InsertSavedSearchMutationVariables>;
export const InsertSharedPageDocument = gql`
    mutation InsertSharedPage($documentId: String!, $meetingId: String!, $type: String!) {
  insertSharedPage(documentId: $documentId, meetingId: $meetingId, type: $type)
}
    `;
export type InsertSharedPageMutationFn = Apollo.MutationFunction<InsertSharedPageMutation, InsertSharedPageMutationVariables>;

/**
 * __useInsertSharedPageMutation__
 *
 * To run a mutation, you first call `useInsertSharedPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertSharedPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertSharedPageMutation, { data, loading, error }] = useInsertSharedPageMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      meetingId: // value for 'meetingId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useInsertSharedPageMutation(baseOptions?: Apollo.MutationHookOptions<InsertSharedPageMutation, InsertSharedPageMutationVariables>) {
        return Apollo.useMutation<InsertSharedPageMutation, InsertSharedPageMutationVariables>(InsertSharedPageDocument, baseOptions);
      }
export type InsertSharedPageMutationHookResult = ReturnType<typeof useInsertSharedPageMutation>;
export type InsertSharedPageMutationResult = Apollo.MutationResult<InsertSharedPageMutation>;
export type InsertSharedPageMutationOptions = Apollo.BaseMutationOptions<InsertSharedPageMutation, InsertSharedPageMutationVariables>;
export const InsertSnippetLibraryDocument = gql`
    mutation InsertSnippetLibrary($title: String!, $emoji: String!, $accessRightLevel: String!) {
  insertSnippetLibrary(
    title: $title
    emoji: $emoji
    accessRightLevel: $accessRightLevel
  ) {
    id
    title
    emoji
    accessRights {
      accessRightLevel
      ownerId
    }
  }
}
    `;
export type InsertSnippetLibraryMutationFn = Apollo.MutationFunction<InsertSnippetLibraryMutation, InsertSnippetLibraryMutationVariables>;

/**
 * __useInsertSnippetLibraryMutation__
 *
 * To run a mutation, you first call `useInsertSnippetLibraryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertSnippetLibraryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertSnippetLibraryMutation, { data, loading, error }] = useInsertSnippetLibraryMutation({
 *   variables: {
 *      title: // value for 'title'
 *      emoji: // value for 'emoji'
 *      accessRightLevel: // value for 'accessRightLevel'
 *   },
 * });
 */
export function useInsertSnippetLibraryMutation(baseOptions?: Apollo.MutationHookOptions<InsertSnippetLibraryMutation, InsertSnippetLibraryMutationVariables>) {
        return Apollo.useMutation<InsertSnippetLibraryMutation, InsertSnippetLibraryMutationVariables>(InsertSnippetLibraryDocument, baseOptions);
      }
export type InsertSnippetLibraryMutationHookResult = ReturnType<typeof useInsertSnippetLibraryMutation>;
export type InsertSnippetLibraryMutationResult = Apollo.MutationResult<InsertSnippetLibraryMutation>;
export type InsertSnippetLibraryMutationOptions = Apollo.BaseMutationOptions<InsertSnippetLibraryMutation, InsertSnippetLibraryMutationVariables>;
export const LoginDocument = gql`
    mutation Login($usernameOrEmail: String!, $password: String!) {
  login(usernameOrEmail: $usernameOrEmail, password: $password) {
    ...RegularUserResponse
  }
}
    ${RegularUserResponseFragmentDoc}`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      usernameOrEmail: // value for 'usernameOrEmail'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, baseOptions);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const MergeSegmentsDocument = gql`
    mutation MergeSegments($segments: [SegmentsInput!]!, $idMeeting: String!, $description: String!, $type: String!) {
  mergeSegments(
    segments: $segments
    idMeeting: $idMeeting
    description: $description
    type: $type
  ) {
    id
    url
    duration
  }
}
    `;
export type MergeSegmentsMutationFn = Apollo.MutationFunction<MergeSegmentsMutation, MergeSegmentsMutationVariables>;

/**
 * __useMergeSegmentsMutation__
 *
 * To run a mutation, you first call `useMergeSegmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMergeSegmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergeSegmentsMutation, { data, loading, error }] = useMergeSegmentsMutation({
 *   variables: {
 *      segments: // value for 'segments'
 *      idMeeting: // value for 'idMeeting'
 *      description: // value for 'description'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useMergeSegmentsMutation(baseOptions?: Apollo.MutationHookOptions<MergeSegmentsMutation, MergeSegmentsMutationVariables>) {
        return Apollo.useMutation<MergeSegmentsMutation, MergeSegmentsMutationVariables>(MergeSegmentsDocument, baseOptions);
      }
export type MergeSegmentsMutationHookResult = ReturnType<typeof useMergeSegmentsMutation>;
export type MergeSegmentsMutationResult = Apollo.MutationResult<MergeSegmentsMutation>;
export type MergeSegmentsMutationOptions = Apollo.BaseMutationOptions<MergeSegmentsMutation, MergeSegmentsMutationVariables>;
export const OnboardingDocument = gql`
    mutation Onboarding {
  onboarding
}
    `;
export type OnboardingMutationFn = Apollo.MutationFunction<OnboardingMutation, OnboardingMutationVariables>;

/**
 * __useOnboardingMutation__
 *
 * To run a mutation, you first call `useOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onboardingMutation, { data, loading, error }] = useOnboardingMutation({
 *   variables: {
 *   },
 * });
 */
export function useOnboardingMutation(baseOptions?: Apollo.MutationHookOptions<OnboardingMutation, OnboardingMutationVariables>) {
        return Apollo.useMutation<OnboardingMutation, OnboardingMutationVariables>(OnboardingDocument, baseOptions);
      }
export type OnboardingMutationHookResult = ReturnType<typeof useOnboardingMutation>;
export type OnboardingMutationResult = Apollo.MutationResult<OnboardingMutation>;
export type OnboardingMutationOptions = Apollo.BaseMutationOptions<OnboardingMutation, OnboardingMutationVariables>;
export const RegisterDocument = gql`
    mutation Register($options: UsernamePasswordInput!) {
  register(options: $options) {
    ...RegularUserResponse
  }
}
    ${RegularUserResponseFragmentDoc}`;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, baseOptions);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const RemoveOrganizationMemberDocument = gql`
    mutation removeOrganizationMember($organizationId: Float!, $memberId: Float!) {
  removeOrganizationMember(organizationId: $organizationId, memberId: $memberId)
}
    `;
export type RemoveOrganizationMemberMutationFn = Apollo.MutationFunction<RemoveOrganizationMemberMutation, RemoveOrganizationMemberMutationVariables>;

/**
 * __useRemoveOrganizationMemberMutation__
 *
 * To run a mutation, you first call `useRemoveOrganizationMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOrganizationMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOrganizationMemberMutation, { data, loading, error }] = useRemoveOrganizationMemberMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useRemoveOrganizationMemberMutation(baseOptions?: Apollo.MutationHookOptions<RemoveOrganizationMemberMutation, RemoveOrganizationMemberMutationVariables>) {
        return Apollo.useMutation<RemoveOrganizationMemberMutation, RemoveOrganizationMemberMutationVariables>(RemoveOrganizationMemberDocument, baseOptions);
      }
export type RemoveOrganizationMemberMutationHookResult = ReturnType<typeof useRemoveOrganizationMemberMutation>;
export type RemoveOrganizationMemberMutationResult = Apollo.MutationResult<RemoveOrganizationMemberMutation>;
export type RemoveOrganizationMemberMutationOptions = Apollo.BaseMutationOptions<RemoveOrganizationMemberMutation, RemoveOrganizationMemberMutationVariables>;
export const RevokeInvitationDocument = gql`
    mutation RevokeInvitation($email: String!, $organizationId: Float!) {
  revokeInvitation(email: $email, organizationId: $organizationId)
}
    `;
export type RevokeInvitationMutationFn = Apollo.MutationFunction<RevokeInvitationMutation, RevokeInvitationMutationVariables>;

/**
 * __useRevokeInvitationMutation__
 *
 * To run a mutation, you first call `useRevokeInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeInvitationMutation, { data, loading, error }] = useRevokeInvitationMutation({
 *   variables: {
 *      email: // value for 'email'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useRevokeInvitationMutation(baseOptions?: Apollo.MutationHookOptions<RevokeInvitationMutation, RevokeInvitationMutationVariables>) {
        return Apollo.useMutation<RevokeInvitationMutation, RevokeInvitationMutationVariables>(RevokeInvitationDocument, baseOptions);
      }
export type RevokeInvitationMutationHookResult = ReturnType<typeof useRevokeInvitationMutation>;
export type RevokeInvitationMutationResult = Apollo.MutationResult<RevokeInvitationMutation>;
export type RevokeInvitationMutationOptions = Apollo.BaseMutationOptions<RevokeInvitationMutation, RevokeInvitationMutationVariables>;
export const SaveOrganizationDocument = gql`
    mutation SaveOrganization($name: String!) {
  saveOrganization(name: $name)
}
    `;
export type SaveOrganizationMutationFn = Apollo.MutationFunction<SaveOrganizationMutation, SaveOrganizationMutationVariables>;

/**
 * __useSaveOrganizationMutation__
 *
 * To run a mutation, you first call `useSaveOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveOrganizationMutation, { data, loading, error }] = useSaveOrganizationMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useSaveOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<SaveOrganizationMutation, SaveOrganizationMutationVariables>) {
        return Apollo.useMutation<SaveOrganizationMutation, SaveOrganizationMutationVariables>(SaveOrganizationDocument, baseOptions);
      }
export type SaveOrganizationMutationHookResult = ReturnType<typeof useSaveOrganizationMutation>;
export type SaveOrganizationMutationResult = Apollo.MutationResult<SaveOrganizationMutation>;
export type SaveOrganizationMutationOptions = Apollo.BaseMutationOptions<SaveOrganizationMutation, SaveOrganizationMutationVariables>;
export const SaveOrganizationMemberDocument = gql`
    mutation SaveOrganizationMember($organizationId: Float!, $role: String!, $userId: Float!) {
  saveOrganizationMember(
    organizationId: $organizationId
    role: $role
    userId: $userId
  )
}
    `;
export type SaveOrganizationMemberMutationFn = Apollo.MutationFunction<SaveOrganizationMemberMutation, SaveOrganizationMemberMutationVariables>;

/**
 * __useSaveOrganizationMemberMutation__
 *
 * To run a mutation, you first call `useSaveOrganizationMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveOrganizationMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveOrganizationMemberMutation, { data, loading, error }] = useSaveOrganizationMemberMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      role: // value for 'role'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSaveOrganizationMemberMutation(baseOptions?: Apollo.MutationHookOptions<SaveOrganizationMemberMutation, SaveOrganizationMemberMutationVariables>) {
        return Apollo.useMutation<SaveOrganizationMemberMutation, SaveOrganizationMemberMutationVariables>(SaveOrganizationMemberDocument, baseOptions);
      }
export type SaveOrganizationMemberMutationHookResult = ReturnType<typeof useSaveOrganizationMemberMutation>;
export type SaveOrganizationMemberMutationResult = Apollo.MutationResult<SaveOrganizationMemberMutation>;
export type SaveOrganizationMemberMutationOptions = Apollo.BaseMutationOptions<SaveOrganizationMemberMutation, SaveOrganizationMemberMutationVariables>;
export const SaveTeamMemberDocument = gql`
    mutation SaveTeamMember($organizationId: Float!, $teamId: String!, $teamMember: MemberInputType!) {
  saveTeamMember(
    organizationId: $organizationId
    teamId: $teamId
    teamMember: $teamMember
  )
}
    `;
export type SaveTeamMemberMutationFn = Apollo.MutationFunction<SaveTeamMemberMutation, SaveTeamMemberMutationVariables>;

/**
 * __useSaveTeamMemberMutation__
 *
 * To run a mutation, you first call `useSaveTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveTeamMemberMutation, { data, loading, error }] = useSaveTeamMemberMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      teamId: // value for 'teamId'
 *      teamMember: // value for 'teamMember'
 *   },
 * });
 */
export function useSaveTeamMemberMutation(baseOptions?: Apollo.MutationHookOptions<SaveTeamMemberMutation, SaveTeamMemberMutationVariables>) {
        return Apollo.useMutation<SaveTeamMemberMutation, SaveTeamMemberMutationVariables>(SaveTeamMemberDocument, baseOptions);
      }
export type SaveTeamMemberMutationHookResult = ReturnType<typeof useSaveTeamMemberMutation>;
export type SaveTeamMemberMutationResult = Apollo.MutationResult<SaveTeamMemberMutation>;
export type SaveTeamMemberMutationOptions = Apollo.BaseMutationOptions<SaveTeamMemberMutation, SaveTeamMemberMutationVariables>;
export const SearchDocument = gql`
    mutation Search($query: String!, $filters: SearchFiltersInput!, $cursors: SearchCursorsInput, $page: Float) {
  search(query: $query, filters: $filters, cursors: $cursors, page: $page) {
    blocks {
      id
      children {
        text
      }
      type
      updatedAt
      updatedAtInRecording
      meetingId
    }
    snippets {
      type
      url
      updatedAt
      createdAt
      description
      meetingId
      turnsIds
      shareableId
      id
      duration
      start
      end
      owner {
        id
        username
      }
    }
    meetings {
      id
      emoji
      createdAt
      title
      url
      exports {
        notion {
          reportsExportsCount
          id
        }
      }
      template {
        id
        name
      }
    }
    turns {
      id
      text
      speaker
      timestamp
      url
      words {
        id
        start
        end
        word
        inferences {
          acts {
            macro
            detailed
          }
          sentiment
          thematics
        }
      }
      meetingId
      inferences {
        sentiment
      }
      metadata {
        createdAt
        endedAt
        start
        end
        seqId
        duration
      }
    }
  }
}
    `;
export type SearchMutationFn = Apollo.MutationFunction<SearchMutation, SearchMutationVariables>;

/**
 * __useSearchMutation__
 *
 * To run a mutation, you first call `useSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [searchMutation, { data, loading, error }] = useSearchMutation({
 *   variables: {
 *      query: // value for 'query'
 *      filters: // value for 'filters'
 *      cursors: // value for 'cursors'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useSearchMutation(baseOptions?: Apollo.MutationHookOptions<SearchMutation, SearchMutationVariables>) {
        return Apollo.useMutation<SearchMutation, SearchMutationVariables>(SearchDocument, baseOptions);
      }
export type SearchMutationHookResult = ReturnType<typeof useSearchMutation>;
export type SearchMutationResult = Apollo.MutationResult<SearchMutation>;
export type SearchMutationOptions = Apollo.BaseMutationOptions<SearchMutation, SearchMutationVariables>;
export const SearchForAnalyticsDocument = gql`
    mutation SearchForAnalytics($query: String!, $size: Float, $filters: SearchFiltersInput!) {
  searchForAnalytics(query: $query, size: $size, filters: $filters) {
    turns {
      meetingId
      id
      speaker
      text
      timestamp
      url
      x
      y
      cluster
      topics
      inferences {
        sentiment
      }
      metadata {
        createdAt
        start
        end
      }
    }
  }
}
    `;
export type SearchForAnalyticsMutationFn = Apollo.MutationFunction<SearchForAnalyticsMutation, SearchForAnalyticsMutationVariables>;

/**
 * __useSearchForAnalyticsMutation__
 *
 * To run a mutation, you first call `useSearchForAnalyticsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSearchForAnalyticsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [searchForAnalyticsMutation, { data, loading, error }] = useSearchForAnalyticsMutation({
 *   variables: {
 *      query: // value for 'query'
 *      size: // value for 'size'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useSearchForAnalyticsMutation(baseOptions?: Apollo.MutationHookOptions<SearchForAnalyticsMutation, SearchForAnalyticsMutationVariables>) {
        return Apollo.useMutation<SearchForAnalyticsMutation, SearchForAnalyticsMutationVariables>(SearchForAnalyticsDocument, baseOptions);
      }
export type SearchForAnalyticsMutationHookResult = ReturnType<typeof useSearchForAnalyticsMutation>;
export type SearchForAnalyticsMutationResult = Apollo.MutationResult<SearchForAnalyticsMutation>;
export type SearchForAnalyticsMutationOptions = Apollo.BaseMutationOptions<SearchForAnalyticsMutation, SearchForAnalyticsMutationVariables>;
export const SendInvitationDocument = gql`
    mutation SendInvitation($email: String!, $organizationId: Float!) {
  sendInvitation(email: $email, organizationId: $organizationId)
}
    `;
export type SendInvitationMutationFn = Apollo.MutationFunction<SendInvitationMutation, SendInvitationMutationVariables>;

/**
 * __useSendInvitationMutation__
 *
 * To run a mutation, you first call `useSendInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInvitationMutation, { data, loading, error }] = useSendInvitationMutation({
 *   variables: {
 *      email: // value for 'email'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useSendInvitationMutation(baseOptions?: Apollo.MutationHookOptions<SendInvitationMutation, SendInvitationMutationVariables>) {
        return Apollo.useMutation<SendInvitationMutation, SendInvitationMutationVariables>(SendInvitationDocument, baseOptions);
      }
export type SendInvitationMutationHookResult = ReturnType<typeof useSendInvitationMutation>;
export type SendInvitationMutationResult = Apollo.MutationResult<SendInvitationMutation>;
export type SendInvitationMutationOptions = Apollo.BaseMutationOptions<SendInvitationMutation, SendInvitationMutationVariables>;
export const SendReportByEmailDocument = gql`
    mutation sendReportByEmail($meetingId: String!, $title: String!, $message: String!, $to: [String!]!, $cc: [String!], $bcc: [String!]) {
  sendReportByEmail(
    meetingId: $meetingId
    title: $title
    message: $message
    to: $to
    cc: $cc
    bcc: $bcc
  )
}
    `;
export type SendReportByEmailMutationFn = Apollo.MutationFunction<SendReportByEmailMutation, SendReportByEmailMutationVariables>;

/**
 * __useSendReportByEmailMutation__
 *
 * To run a mutation, you first call `useSendReportByEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendReportByEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendReportByEmailMutation, { data, loading, error }] = useSendReportByEmailMutation({
 *   variables: {
 *      meetingId: // value for 'meetingId'
 *      title: // value for 'title'
 *      message: // value for 'message'
 *      to: // value for 'to'
 *      cc: // value for 'cc'
 *      bcc: // value for 'bcc'
 *   },
 * });
 */
export function useSendReportByEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendReportByEmailMutation, SendReportByEmailMutationVariables>) {
        return Apollo.useMutation<SendReportByEmailMutation, SendReportByEmailMutationVariables>(SendReportByEmailDocument, baseOptions);
      }
export type SendReportByEmailMutationHookResult = ReturnType<typeof useSendReportByEmailMutation>;
export type SendReportByEmailMutationResult = Apollo.MutationResult<SendReportByEmailMutation>;
export type SendReportByEmailMutationOptions = Apollo.BaseMutationOptions<SendReportByEmailMutation, SendReportByEmailMutationVariables>;
export const SetAircallTokenDocument = gql`
    mutation SetAircallToken($code: String!, $organizationId: Float!) {
  setAircallToken(code: $code, organizationId: $organizationId)
}
    `;
export type SetAircallTokenMutationFn = Apollo.MutationFunction<SetAircallTokenMutation, SetAircallTokenMutationVariables>;

/**
 * __useSetAircallTokenMutation__
 *
 * To run a mutation, you first call `useSetAircallTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAircallTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAircallTokenMutation, { data, loading, error }] = useSetAircallTokenMutation({
 *   variables: {
 *      code: // value for 'code'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useSetAircallTokenMutation(baseOptions?: Apollo.MutationHookOptions<SetAircallTokenMutation, SetAircallTokenMutationVariables>) {
        return Apollo.useMutation<SetAircallTokenMutation, SetAircallTokenMutationVariables>(SetAircallTokenDocument, baseOptions);
      }
export type SetAircallTokenMutationHookResult = ReturnType<typeof useSetAircallTokenMutation>;
export type SetAircallTokenMutationResult = Apollo.MutationResult<SetAircallTokenMutation>;
export type SetAircallTokenMutationOptions = Apollo.BaseMutationOptions<SetAircallTokenMutation, SetAircallTokenMutationVariables>;
export const SetHubSpotTokenDocument = gql`
    mutation SetHubSpotToken($code: String!) {
  setHubSpotToken(code: $code)
}
    `;
export type SetHubSpotTokenMutationFn = Apollo.MutationFunction<SetHubSpotTokenMutation, SetHubSpotTokenMutationVariables>;

/**
 * __useSetHubSpotTokenMutation__
 *
 * To run a mutation, you first call `useSetHubSpotTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetHubSpotTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setHubSpotTokenMutation, { data, loading, error }] = useSetHubSpotTokenMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useSetHubSpotTokenMutation(baseOptions?: Apollo.MutationHookOptions<SetHubSpotTokenMutation, SetHubSpotTokenMutationVariables>) {
        return Apollo.useMutation<SetHubSpotTokenMutation, SetHubSpotTokenMutationVariables>(SetHubSpotTokenDocument, baseOptions);
      }
export type SetHubSpotTokenMutationHookResult = ReturnType<typeof useSetHubSpotTokenMutation>;
export type SetHubSpotTokenMutationResult = Apollo.MutationResult<SetHubSpotTokenMutation>;
export type SetHubSpotTokenMutationOptions = Apollo.BaseMutationOptions<SetHubSpotTokenMutation, SetHubSpotTokenMutationVariables>;
export const SetIsActiveDocument = gql`
    mutation SetIsActive($id: Float!, $isActive: Boolean!) {
  setIsActive(id: $id, isActive: $isActive)
}
    `;
export type SetIsActiveMutationFn = Apollo.MutationFunction<SetIsActiveMutation, SetIsActiveMutationVariables>;

/**
 * __useSetIsActiveMutation__
 *
 * To run a mutation, you first call `useSetIsActiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetIsActiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setIsActiveMutation, { data, loading, error }] = useSetIsActiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useSetIsActiveMutation(baseOptions?: Apollo.MutationHookOptions<SetIsActiveMutation, SetIsActiveMutationVariables>) {
        return Apollo.useMutation<SetIsActiveMutation, SetIsActiveMutationVariables>(SetIsActiveDocument, baseOptions);
      }
export type SetIsActiveMutationHookResult = ReturnType<typeof useSetIsActiveMutation>;
export type SetIsActiveMutationResult = Apollo.MutationResult<SetIsActiveMutation>;
export type SetIsActiveMutationOptions = Apollo.BaseMutationOptions<SetIsActiveMutation, SetIsActiveMutationVariables>;
export const SetMeetingPrivacyDocument = gql`
    mutation SetMeetingPrivacy($idMeeting: String!, $teamId: String, $accessRightLevel: String!) {
  setMeetingPrivacy(
    idMeeting: $idMeeting
    teamId: $teamId
    accessRightLevel: $accessRightLevel
  )
}
    `;
export type SetMeetingPrivacyMutationFn = Apollo.MutationFunction<SetMeetingPrivacyMutation, SetMeetingPrivacyMutationVariables>;

/**
 * __useSetMeetingPrivacyMutation__
 *
 * To run a mutation, you first call `useSetMeetingPrivacyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMeetingPrivacyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMeetingPrivacyMutation, { data, loading, error }] = useSetMeetingPrivacyMutation({
 *   variables: {
 *      idMeeting: // value for 'idMeeting'
 *      teamId: // value for 'teamId'
 *      accessRightLevel: // value for 'accessRightLevel'
 *   },
 * });
 */
export function useSetMeetingPrivacyMutation(baseOptions?: Apollo.MutationHookOptions<SetMeetingPrivacyMutation, SetMeetingPrivacyMutationVariables>) {
        return Apollo.useMutation<SetMeetingPrivacyMutation, SetMeetingPrivacyMutationVariables>(SetMeetingPrivacyDocument, baseOptions);
      }
export type SetMeetingPrivacyMutationHookResult = ReturnType<typeof useSetMeetingPrivacyMutation>;
export type SetMeetingPrivacyMutationResult = Apollo.MutationResult<SetMeetingPrivacyMutation>;
export type SetMeetingPrivacyMutationOptions = Apollo.BaseMutationOptions<SetMeetingPrivacyMutation, SetMeetingPrivacyMutationVariables>;
export const SetMemberIntegrationTemplatesDocument = gql`
    mutation setMemberIntegrationTemplates($templateId: String!, $organizationId: Float!, $memberId: Float!, $integrationName: String!) {
  setMemberIntegrationTemplates(
    templateId: $templateId
    organizationId: $organizationId
    memberId: $memberId
    integrationName: $integrationName
  )
}
    `;
export type SetMemberIntegrationTemplatesMutationFn = Apollo.MutationFunction<SetMemberIntegrationTemplatesMutation, SetMemberIntegrationTemplatesMutationVariables>;

/**
 * __useSetMemberIntegrationTemplatesMutation__
 *
 * To run a mutation, you first call `useSetMemberIntegrationTemplatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMemberIntegrationTemplatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMemberIntegrationTemplatesMutation, { data, loading, error }] = useSetMemberIntegrationTemplatesMutation({
 *   variables: {
 *      templateId: // value for 'templateId'
 *      organizationId: // value for 'organizationId'
 *      memberId: // value for 'memberId'
 *      integrationName: // value for 'integrationName'
 *   },
 * });
 */
export function useSetMemberIntegrationTemplatesMutation(baseOptions?: Apollo.MutationHookOptions<SetMemberIntegrationTemplatesMutation, SetMemberIntegrationTemplatesMutationVariables>) {
        return Apollo.useMutation<SetMemberIntegrationTemplatesMutation, SetMemberIntegrationTemplatesMutationVariables>(SetMemberIntegrationTemplatesDocument, baseOptions);
      }
export type SetMemberIntegrationTemplatesMutationHookResult = ReturnType<typeof useSetMemberIntegrationTemplatesMutation>;
export type SetMemberIntegrationTemplatesMutationResult = Apollo.MutationResult<SetMemberIntegrationTemplatesMutation>;
export type SetMemberIntegrationTemplatesMutationOptions = Apollo.BaseMutationOptions<SetMemberIntegrationTemplatesMutation, SetMemberIntegrationTemplatesMutationVariables>;
export const SetNotesTemplateDocument = gql`
    mutation SetNotesTemplate($id: String, $title: String, $emoji: String, $saveLocation: String, $properties: [PropertyInput!], $notes: [NotesInput!], $accessRightLevel: String, $accessOptions: AccessOptions) {
  setNotesTemplate(
    id: $id
    accessRightLevel: $accessRightLevel
    accessOptions: $accessOptions
    title: $title
    emoji: $emoji
    saveLocation: $saveLocation
    properties: $properties
    notes: $notes
  )
}
    `;
export type SetNotesTemplateMutationFn = Apollo.MutationFunction<SetNotesTemplateMutation, SetNotesTemplateMutationVariables>;

/**
 * __useSetNotesTemplateMutation__
 *
 * To run a mutation, you first call `useSetNotesTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetNotesTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setNotesTemplateMutation, { data, loading, error }] = useSetNotesTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      emoji: // value for 'emoji'
 *      saveLocation: // value for 'saveLocation'
 *      properties: // value for 'properties'
 *      notes: // value for 'notes'
 *      accessRightLevel: // value for 'accessRightLevel'
 *      accessOptions: // value for 'accessOptions'
 *   },
 * });
 */
export function useSetNotesTemplateMutation(baseOptions?: Apollo.MutationHookOptions<SetNotesTemplateMutation, SetNotesTemplateMutationVariables>) {
        return Apollo.useMutation<SetNotesTemplateMutation, SetNotesTemplateMutationVariables>(SetNotesTemplateDocument, baseOptions);
      }
export type SetNotesTemplateMutationHookResult = ReturnType<typeof useSetNotesTemplateMutation>;
export type SetNotesTemplateMutationResult = Apollo.MutationResult<SetNotesTemplateMutation>;
export type SetNotesTemplateMutationOptions = Apollo.BaseMutationOptions<SetNotesTemplateMutation, SetNotesTemplateMutationVariables>;
export const SetNotesDocument = gql`
    mutation SetNotes($idMeeting: String!, $notes: [NotesInput!]!) {
  setNotes(idMeeting: $idMeeting, notes: $notes)
}
    `;
export type SetNotesMutationFn = Apollo.MutationFunction<SetNotesMutation, SetNotesMutationVariables>;

/**
 * __useSetNotesMutation__
 *
 * To run a mutation, you first call `useSetNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setNotesMutation, { data, loading, error }] = useSetNotesMutation({
 *   variables: {
 *      idMeeting: // value for 'idMeeting'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useSetNotesMutation(baseOptions?: Apollo.MutationHookOptions<SetNotesMutation, SetNotesMutationVariables>) {
        return Apollo.useMutation<SetNotesMutation, SetNotesMutationVariables>(SetNotesDocument, baseOptions);
      }
export type SetNotesMutationHookResult = ReturnType<typeof useSetNotesMutation>;
export type SetNotesMutationResult = Apollo.MutationResult<SetNotesMutation>;
export type SetNotesMutationOptions = Apollo.BaseMutationOptions<SetNotesMutation, SetNotesMutationVariables>;
export const SetNotionTokenDocument = gql`
    mutation SetNotionToken($code: String!) {
  setNotionToken(code: $code)
}
    `;
export type SetNotionTokenMutationFn = Apollo.MutationFunction<SetNotionTokenMutation, SetNotionTokenMutationVariables>;

/**
 * __useSetNotionTokenMutation__
 *
 * To run a mutation, you first call `useSetNotionTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetNotionTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setNotionTokenMutation, { data, loading, error }] = useSetNotionTokenMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useSetNotionTokenMutation(baseOptions?: Apollo.MutationHookOptions<SetNotionTokenMutation, SetNotionTokenMutationVariables>) {
        return Apollo.useMutation<SetNotionTokenMutation, SetNotionTokenMutationVariables>(SetNotionTokenDocument, baseOptions);
      }
export type SetNotionTokenMutationHookResult = ReturnType<typeof useSetNotionTokenMutation>;
export type SetNotionTokenMutationResult = Apollo.MutationResult<SetNotionTokenMutation>;
export type SetNotionTokenMutationOptions = Apollo.BaseMutationOptions<SetNotionTokenMutation, SetNotionTokenMutationVariables>;
export const SetNotionWorkspaceIdDocument = gql`
    mutation SetNotionWorkspaceId($defaultParentPageId: String!) {
  setNotionWorkspaceId(defaultParentPageId: $defaultParentPageId)
}
    `;
export type SetNotionWorkspaceIdMutationFn = Apollo.MutationFunction<SetNotionWorkspaceIdMutation, SetNotionWorkspaceIdMutationVariables>;

/**
 * __useSetNotionWorkspaceIdMutation__
 *
 * To run a mutation, you first call `useSetNotionWorkspaceIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetNotionWorkspaceIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setNotionWorkspaceIdMutation, { data, loading, error }] = useSetNotionWorkspaceIdMutation({
 *   variables: {
 *      defaultParentPageId: // value for 'defaultParentPageId'
 *   },
 * });
 */
export function useSetNotionWorkspaceIdMutation(baseOptions?: Apollo.MutationHookOptions<SetNotionWorkspaceIdMutation, SetNotionWorkspaceIdMutationVariables>) {
        return Apollo.useMutation<SetNotionWorkspaceIdMutation, SetNotionWorkspaceIdMutationVariables>(SetNotionWorkspaceIdDocument, baseOptions);
      }
export type SetNotionWorkspaceIdMutationHookResult = ReturnType<typeof useSetNotionWorkspaceIdMutation>;
export type SetNotionWorkspaceIdMutationResult = Apollo.MutationResult<SetNotionWorkspaceIdMutation>;
export type SetNotionWorkspaceIdMutationOptions = Apollo.BaseMutationOptions<SetNotionWorkspaceIdMutation, SetNotionWorkspaceIdMutationVariables>;
export const SetPropertiesDocument = gql`
    mutation SetProperties($id: String!, $properties: [PropertyInput!]!) {
  setProperties(id: $id, properties: $properties)
}
    `;
export type SetPropertiesMutationFn = Apollo.MutationFunction<SetPropertiesMutation, SetPropertiesMutationVariables>;

/**
 * __useSetPropertiesMutation__
 *
 * To run a mutation, you first call `useSetPropertiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPropertiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPropertiesMutation, { data, loading, error }] = useSetPropertiesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      properties: // value for 'properties'
 *   },
 * });
 */
export function useSetPropertiesMutation(baseOptions?: Apollo.MutationHookOptions<SetPropertiesMutation, SetPropertiesMutationVariables>) {
        return Apollo.useMutation<SetPropertiesMutation, SetPropertiesMutationVariables>(SetPropertiesDocument, baseOptions);
      }
export type SetPropertiesMutationHookResult = ReturnType<typeof useSetPropertiesMutation>;
export type SetPropertiesMutationResult = Apollo.MutationResult<SetPropertiesMutation>;
export type SetPropertiesMutationOptions = Apollo.BaseMutationOptions<SetPropertiesMutation, SetPropertiesMutationVariables>;
export const SetTemplatePropertiesDocument = gql`
    mutation SetTemplateProperties($id: String!, $properties: [PropertyInput!]!) {
  setTemplateProperties(id: $id, properties: $properties)
}
    `;
export type SetTemplatePropertiesMutationFn = Apollo.MutationFunction<SetTemplatePropertiesMutation, SetTemplatePropertiesMutationVariables>;

/**
 * __useSetTemplatePropertiesMutation__
 *
 * To run a mutation, you first call `useSetTemplatePropertiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTemplatePropertiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTemplatePropertiesMutation, { data, loading, error }] = useSetTemplatePropertiesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      properties: // value for 'properties'
 *   },
 * });
 */
export function useSetTemplatePropertiesMutation(baseOptions?: Apollo.MutationHookOptions<SetTemplatePropertiesMutation, SetTemplatePropertiesMutationVariables>) {
        return Apollo.useMutation<SetTemplatePropertiesMutation, SetTemplatePropertiesMutationVariables>(SetTemplatePropertiesDocument, baseOptions);
      }
export type SetTemplatePropertiesMutationHookResult = ReturnType<typeof useSetTemplatePropertiesMutation>;
export type SetTemplatePropertiesMutationResult = Apollo.MutationResult<SetTemplatePropertiesMutation>;
export type SetTemplatePropertiesMutationOptions = Apollo.BaseMutationOptions<SetTemplatePropertiesMutation, SetTemplatePropertiesMutationVariables>;
export const SetTitleAndEmojiDocument = gql`
    mutation SetTitleAndEmoji($idMeeting: String!, $title: String!, $emoji: String!) {
  setTitleAndEmoji(idMeeting: $idMeeting, title: $title, emoji: $emoji)
}
    `;
export type SetTitleAndEmojiMutationFn = Apollo.MutationFunction<SetTitleAndEmojiMutation, SetTitleAndEmojiMutationVariables>;

/**
 * __useSetTitleAndEmojiMutation__
 *
 * To run a mutation, you first call `useSetTitleAndEmojiMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTitleAndEmojiMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTitleAndEmojiMutation, { data, loading, error }] = useSetTitleAndEmojiMutation({
 *   variables: {
 *      idMeeting: // value for 'idMeeting'
 *      title: // value for 'title'
 *      emoji: // value for 'emoji'
 *   },
 * });
 */
export function useSetTitleAndEmojiMutation(baseOptions?: Apollo.MutationHookOptions<SetTitleAndEmojiMutation, SetTitleAndEmojiMutationVariables>) {
        return Apollo.useMutation<SetTitleAndEmojiMutation, SetTitleAndEmojiMutationVariables>(SetTitleAndEmojiDocument, baseOptions);
      }
export type SetTitleAndEmojiMutationHookResult = ReturnType<typeof useSetTitleAndEmojiMutation>;
export type SetTitleAndEmojiMutationResult = Apollo.MutationResult<SetTitleAndEmojiMutation>;
export type SetTitleAndEmojiMutationOptions = Apollo.BaseMutationOptions<SetTitleAndEmojiMutation, SetTitleAndEmojiMutationVariables>;
export const SignedReadToGcpDocument = gql`
    mutation SignedReadToGCP($fileName: String!, $hours: Float) {
  signedReadGCP(fileName: $fileName, hours: $hours)
}
    `;
export type SignedReadToGcpMutationFn = Apollo.MutationFunction<SignedReadToGcpMutation, SignedReadToGcpMutationVariables>;

/**
 * __useSignedReadToGcpMutation__
 *
 * To run a mutation, you first call `useSignedReadToGcpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignedReadToGcpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signedReadToGcpMutation, { data, loading, error }] = useSignedReadToGcpMutation({
 *   variables: {
 *      fileName: // value for 'fileName'
 *      hours: // value for 'hours'
 *   },
 * });
 */
export function useSignedReadToGcpMutation(baseOptions?: Apollo.MutationHookOptions<SignedReadToGcpMutation, SignedReadToGcpMutationVariables>) {
        return Apollo.useMutation<SignedReadToGcpMutation, SignedReadToGcpMutationVariables>(SignedReadToGcpDocument, baseOptions);
      }
export type SignedReadToGcpMutationHookResult = ReturnType<typeof useSignedReadToGcpMutation>;
export type SignedReadToGcpMutationResult = Apollo.MutationResult<SignedReadToGcpMutation>;
export type SignedReadToGcpMutationOptions = Apollo.BaseMutationOptions<SignedReadToGcpMutation, SignedReadToGcpMutationVariables>;
export const TemporarySharedMeetingDocument = gql`
    query TemporarySharedMeeting($sharedPageId: String!, $token: String!) {
  temporarySharedMeeting(sharedPageId: $sharedPageId, token: $token) {
    meeting {
      id
      url
      title
      emoji
      createdAt
      recording {
        updatedAt
        duration
        url
      }
      notes {
        id
        updatedAt
        type
        updatedAtInRecording
        checked
        children {
          text
          type
          checked
          data
          updatedAtInRecording
          children {
            text
            type
            checked
            data
          }
        }
      }
      snippets {
        id
        updatedAt
        type
        url
        description
        turnsIds
        duration
        start
        end
        createdAt
        owner {
          id
          username
        }
      }
      turns {
        id
        url
        speaker
        text
        timestamp
        metadata {
          seqId
          start
          end
          createdAt
          endedAt
          duration
        }
      }
      properties {
        id
        updatedAt
        name
        type
        generated
        content {
          text
          color
        }
        options {
          text
          color
        }
      }
    }
    documentId
    type
  }
}
    `;

/**
 * __useTemporarySharedMeetingQuery__
 *
 * To run a query within a React component, call `useTemporarySharedMeetingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemporarySharedMeetingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemporarySharedMeetingQuery({
 *   variables: {
 *      sharedPageId: // value for 'sharedPageId'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useTemporarySharedMeetingQuery(baseOptions: Apollo.QueryHookOptions<TemporarySharedMeetingQuery, TemporarySharedMeetingQueryVariables>) {
        return Apollo.useQuery<TemporarySharedMeetingQuery, TemporarySharedMeetingQueryVariables>(TemporarySharedMeetingDocument, baseOptions);
      }
export function useTemporarySharedMeetingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemporarySharedMeetingQuery, TemporarySharedMeetingQueryVariables>) {
          return Apollo.useLazyQuery<TemporarySharedMeetingQuery, TemporarySharedMeetingQueryVariables>(TemporarySharedMeetingDocument, baseOptions);
        }
export type TemporarySharedMeetingQueryHookResult = ReturnType<typeof useTemporarySharedMeetingQuery>;
export type TemporarySharedMeetingLazyQueryHookResult = ReturnType<typeof useTemporarySharedMeetingLazyQuery>;
export type TemporarySharedMeetingQueryResult = Apollo.QueryResult<TemporarySharedMeetingQuery, TemporarySharedMeetingQueryVariables>;
export const TopicsDocument = gql`
    mutation Topics($meetingIds: [String!]!) {
  topics(meetingIds: $meetingIds) {
    keyword
    sentiment
  }
}
    `;
export type TopicsMutationFn = Apollo.MutationFunction<TopicsMutation, TopicsMutationVariables>;

/**
 * __useTopicsMutation__
 *
 * To run a mutation, you first call `useTopicsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTopicsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [topicsMutation, { data, loading, error }] = useTopicsMutation({
 *   variables: {
 *      meetingIds: // value for 'meetingIds'
 *   },
 * });
 */
export function useTopicsMutation(baseOptions?: Apollo.MutationHookOptions<TopicsMutation, TopicsMutationVariables>) {
        return Apollo.useMutation<TopicsMutation, TopicsMutationVariables>(TopicsDocument, baseOptions);
      }
export type TopicsMutationHookResult = ReturnType<typeof useTopicsMutation>;
export type TopicsMutationResult = Apollo.MutationResult<TopicsMutation>;
export type TopicsMutationOptions = Apollo.BaseMutationOptions<TopicsMutation, TopicsMutationVariables>;
export const UpdateSavedSearchDocument = gql`
    mutation UpdateSavedSearch($id: String!, $emoji: String, $title: String, $query: String, $filters: SearchFiltersInput, $order: Int, $accessRightLevel: String, $organizationId: Float) {
  updateSavedSearch(
    id: $id
    emoji: $emoji
    title: $title
    query: $query
    filters: $filters
    order: $order
    accessRightLevel: $accessRightLevel
    organizationId: $organizationId
  )
}
    `;
export type UpdateSavedSearchMutationFn = Apollo.MutationFunction<UpdateSavedSearchMutation, UpdateSavedSearchMutationVariables>;

/**
 * __useUpdateSavedSearchMutation__
 *
 * To run a mutation, you first call `useUpdateSavedSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSavedSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSavedSearchMutation, { data, loading, error }] = useUpdateSavedSearchMutation({
 *   variables: {
 *      id: // value for 'id'
 *      emoji: // value for 'emoji'
 *      title: // value for 'title'
 *      query: // value for 'query'
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *      accessRightLevel: // value for 'accessRightLevel'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useUpdateSavedSearchMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSavedSearchMutation, UpdateSavedSearchMutationVariables>) {
        return Apollo.useMutation<UpdateSavedSearchMutation, UpdateSavedSearchMutationVariables>(UpdateSavedSearchDocument, baseOptions);
      }
export type UpdateSavedSearchMutationHookResult = ReturnType<typeof useUpdateSavedSearchMutation>;
export type UpdateSavedSearchMutationResult = Apollo.MutationResult<UpdateSavedSearchMutation>;
export type UpdateSavedSearchMutationOptions = Apollo.BaseMutationOptions<UpdateSavedSearchMutation, UpdateSavedSearchMutationVariables>;
export const UpdateSnippetDocument = gql`
    mutation UpdateSnippet($idMeeting: String!, $snippetId: String!, $type: String!, $description: String!) {
  updateSnippet(
    idMeeting: $idMeeting
    snippetId: $snippetId
    type: $type
    description: $description
  )
}
    `;
export type UpdateSnippetMutationFn = Apollo.MutationFunction<UpdateSnippetMutation, UpdateSnippetMutationVariables>;

/**
 * __useUpdateSnippetMutation__
 *
 * To run a mutation, you first call `useUpdateSnippetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSnippetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSnippetMutation, { data, loading, error }] = useUpdateSnippetMutation({
 *   variables: {
 *      idMeeting: // value for 'idMeeting'
 *      snippetId: // value for 'snippetId'
 *      type: // value for 'type'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateSnippetMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSnippetMutation, UpdateSnippetMutationVariables>) {
        return Apollo.useMutation<UpdateSnippetMutation, UpdateSnippetMutationVariables>(UpdateSnippetDocument, baseOptions);
      }
export type UpdateSnippetMutationHookResult = ReturnType<typeof useUpdateSnippetMutation>;
export type UpdateSnippetMutationResult = Apollo.MutationResult<UpdateSnippetMutation>;
export type UpdateSnippetMutationOptions = Apollo.BaseMutationOptions<UpdateSnippetMutation, UpdateSnippetMutationVariables>;
export const UpdateSnippetLibraryDocument = gql`
    mutation UpdateSnippetLibrary($id: Float!, $title: String!, $emoji: String!, $accessRightLevel: String!) {
  updateSnippetLibrary(
    id: $id
    title: $title
    emoji: $emoji
    accessRightLevel: $accessRightLevel
  )
}
    `;
export type UpdateSnippetLibraryMutationFn = Apollo.MutationFunction<UpdateSnippetLibraryMutation, UpdateSnippetLibraryMutationVariables>;

/**
 * __useUpdateSnippetLibraryMutation__
 *
 * To run a mutation, you first call `useUpdateSnippetLibraryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSnippetLibraryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSnippetLibraryMutation, { data, loading, error }] = useUpdateSnippetLibraryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      emoji: // value for 'emoji'
 *      accessRightLevel: // value for 'accessRightLevel'
 *   },
 * });
 */
export function useUpdateSnippetLibraryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSnippetLibraryMutation, UpdateSnippetLibraryMutationVariables>) {
        return Apollo.useMutation<UpdateSnippetLibraryMutation, UpdateSnippetLibraryMutationVariables>(UpdateSnippetLibraryDocument, baseOptions);
      }
export type UpdateSnippetLibraryMutationHookResult = ReturnType<typeof useUpdateSnippetLibraryMutation>;
export type UpdateSnippetLibraryMutationResult = Apollo.MutationResult<UpdateSnippetLibraryMutation>;
export type UpdateSnippetLibraryMutationOptions = Apollo.BaseMutationOptions<UpdateSnippetLibraryMutation, UpdateSnippetLibraryMutationVariables>;
export const UpdateTeamDocument = gql`
    mutation UpdateTeam($organizationId: Float!, $team: TeamInputType!) {
  updateTeam(organizationId: $organizationId, team: $team) {
    id
    name
    members {
      id
      role
      email
      integrationTemplates
    }
  }
}
    `;
export type UpdateTeamMutationFn = Apollo.MutationFunction<UpdateTeamMutation, UpdateTeamMutationVariables>;

/**
 * __useUpdateTeamMutation__
 *
 * To run a mutation, you first call `useUpdateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamMutation, { data, loading, error }] = useUpdateTeamMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      team: // value for 'team'
 *   },
 * });
 */
export function useUpdateTeamMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTeamMutation, UpdateTeamMutationVariables>) {
        return Apollo.useMutation<UpdateTeamMutation, UpdateTeamMutationVariables>(UpdateTeamDocument, baseOptions);
      }
export type UpdateTeamMutationHookResult = ReturnType<typeof useUpdateTeamMutation>;
export type UpdateTeamMutationResult = Apollo.MutationResult<UpdateTeamMutation>;
export type UpdateTeamMutationOptions = Apollo.BaseMutationOptions<UpdateTeamMutation, UpdateTeamMutationVariables>;
export const SignedUploadToGcpDocument = gql`
    mutation SignedUploadToGCP($fileName: String!) {
  signedUploadToGCP(fileName: $fileName)
}
    `;
export type SignedUploadToGcpMutationFn = Apollo.MutationFunction<SignedUploadToGcpMutation, SignedUploadToGcpMutationVariables>;

/**
 * __useSignedUploadToGcpMutation__
 *
 * To run a mutation, you first call `useSignedUploadToGcpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignedUploadToGcpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signedUploadToGcpMutation, { data, loading, error }] = useSignedUploadToGcpMutation({
 *   variables: {
 *      fileName: // value for 'fileName'
 *   },
 * });
 */
export function useSignedUploadToGcpMutation(baseOptions?: Apollo.MutationHookOptions<SignedUploadToGcpMutation, SignedUploadToGcpMutationVariables>) {
        return Apollo.useMutation<SignedUploadToGcpMutation, SignedUploadToGcpMutationVariables>(SignedUploadToGcpDocument, baseOptions);
      }
export type SignedUploadToGcpMutationHookResult = ReturnType<typeof useSignedUploadToGcpMutation>;
export type SignedUploadToGcpMutationResult = Apollo.MutationResult<SignedUploadToGcpMutation>;
export type SignedUploadToGcpMutationOptions = Apollo.BaseMutationOptions<SignedUploadToGcpMutation, SignedUploadToGcpMutationVariables>;
export const CheckAccessDocument = gql`
    query CheckAccess($id: String!) {
  checkAccess(id: $id)
}
    `;

/**
 * __useCheckAccessQuery__
 *
 * To run a query within a React component, call `useCheckAccessQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckAccessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckAccessQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCheckAccessQuery(baseOptions: Apollo.QueryHookOptions<CheckAccessQuery, CheckAccessQueryVariables>) {
        return Apollo.useQuery<CheckAccessQuery, CheckAccessQueryVariables>(CheckAccessDocument, baseOptions);
      }
export function useCheckAccessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckAccessQuery, CheckAccessQueryVariables>) {
          return Apollo.useLazyQuery<CheckAccessQuery, CheckAccessQueryVariables>(CheckAccessDocument, baseOptions);
        }
export type CheckAccessQueryHookResult = ReturnType<typeof useCheckAccessQuery>;
export type CheckAccessLazyQueryHookResult = ReturnType<typeof useCheckAccessLazyQuery>;
export type CheckAccessQueryResult = Apollo.QueryResult<CheckAccessQuery, CheckAccessQueryVariables>;
export const CheckForInvitationDocument = gql`
    query CheckForInvitation {
  checkForInvitation {
    id
    name
  }
}
    `;

/**
 * __useCheckForInvitationQuery__
 *
 * To run a query within a React component, call `useCheckForInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckForInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckForInvitationQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckForInvitationQuery(baseOptions?: Apollo.QueryHookOptions<CheckForInvitationQuery, CheckForInvitationQueryVariables>) {
        return Apollo.useQuery<CheckForInvitationQuery, CheckForInvitationQueryVariables>(CheckForInvitationDocument, baseOptions);
      }
export function useCheckForInvitationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckForInvitationQuery, CheckForInvitationQueryVariables>) {
          return Apollo.useLazyQuery<CheckForInvitationQuery, CheckForInvitationQueryVariables>(CheckForInvitationDocument, baseOptions);
        }
export type CheckForInvitationQueryHookResult = ReturnType<typeof useCheckForInvitationQuery>;
export type CheckForInvitationLazyQueryHookResult = ReturnType<typeof useCheckForInvitationLazyQuery>;
export type CheckForInvitationQueryResult = Apollo.QueryResult<CheckForInvitationQuery, CheckForInvitationQueryVariables>;
export const GetNotionDatabasesDocument = gql`
    query GetNotionDatabases {
  getNotionDatabases {
    database {
      id
      name
      type
    }
  }
}
    `;

/**
 * __useGetNotionDatabasesQuery__
 *
 * To run a query within a React component, call `useGetNotionDatabasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotionDatabasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotionDatabasesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNotionDatabasesQuery(baseOptions?: Apollo.QueryHookOptions<GetNotionDatabasesQuery, GetNotionDatabasesQueryVariables>) {
        return Apollo.useQuery<GetNotionDatabasesQuery, GetNotionDatabasesQueryVariables>(GetNotionDatabasesDocument, baseOptions);
      }
export function useGetNotionDatabasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotionDatabasesQuery, GetNotionDatabasesQueryVariables>) {
          return Apollo.useLazyQuery<GetNotionDatabasesQuery, GetNotionDatabasesQueryVariables>(GetNotionDatabasesDocument, baseOptions);
        }
export type GetNotionDatabasesQueryHookResult = ReturnType<typeof useGetNotionDatabasesQuery>;
export type GetNotionDatabasesLazyQueryHookResult = ReturnType<typeof useGetNotionDatabasesLazyQuery>;
export type GetNotionDatabasesQueryResult = Apollo.QueryResult<GetNotionDatabasesQuery, GetNotionDatabasesQueryVariables>;
export const InvitationsSentFromOrganizationDocument = gql`
    query InvitationsSentFromOrganization($organizationId: Float!) {
  invitationsSentFromOrganization(organizationId: $organizationId)
}
    `;

/**
 * __useInvitationsSentFromOrganizationQuery__
 *
 * To run a query within a React component, call `useInvitationsSentFromOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvitationsSentFromOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvitationsSentFromOrganizationQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useInvitationsSentFromOrganizationQuery(baseOptions: Apollo.QueryHookOptions<InvitationsSentFromOrganizationQuery, InvitationsSentFromOrganizationQueryVariables>) {
        return Apollo.useQuery<InvitationsSentFromOrganizationQuery, InvitationsSentFromOrganizationQueryVariables>(InvitationsSentFromOrganizationDocument, baseOptions);
      }
export function useInvitationsSentFromOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvitationsSentFromOrganizationQuery, InvitationsSentFromOrganizationQueryVariables>) {
          return Apollo.useLazyQuery<InvitationsSentFromOrganizationQuery, InvitationsSentFromOrganizationQueryVariables>(InvitationsSentFromOrganizationDocument, baseOptions);
        }
export type InvitationsSentFromOrganizationQueryHookResult = ReturnType<typeof useInvitationsSentFromOrganizationQuery>;
export type InvitationsSentFromOrganizationLazyQueryHookResult = ReturnType<typeof useInvitationsSentFromOrganizationLazyQuery>;
export type InvitationsSentFromOrganizationQueryResult = Apollo.QueryResult<InvitationsSentFromOrganizationQuery, InvitationsSentFromOrganizationQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    ...RegularUser
  }
}
    ${RegularUserFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const MeetingDocument = gql`
    query Meeting($idMeeting: String!) {
  meeting(idMeeting: $idMeeting) {
    id
    url
    title
    emoji
    createdAt
    template {
      id
      name
    }
    properties {
      id
      updatedAt
      name
      type
      generated
      content {
        text
        color
      }
      options {
        text
        color
        description
      }
    }
    notes {
      id
      updatedAt
      type
      updatedAtInRecording
      checked
      children {
        text
        type
        checked
        data
        updatedAtInRecording
        children {
          text
          type
          checked
          data
        }
      }
      exports {
        notionBlockId
        emailBlockId
      }
      data
    }
    recording {
      updatedAt
      duration
      url
    }
    snippets {
      id
      updatedAt
      type
      url
      description
      turnsIds
      duration
      start
      end
      createdAt
      owner {
        id
        username
      }
    }
    turns {
      id
      url
      speaker
      text
      timestamp
      words {
        id
        word
        start
        end
        inferences {
          acts {
            macro
            detailed
          }
          sentiment
          thematics
        }
      }
      metadata {
        seqId
        start
        end
        createdAt
        endedAt
        duration
      }
    }
    exports {
      notion {
        id
        parentPageId
        reportsExportsCount
      }
    }
    metadata {
      duration
      accessRights {
        accessRightLevel
        ownerId
        teamId
      }
    }
  }
}
    `;

/**
 * __useMeetingQuery__
 *
 * To run a query within a React component, call `useMeetingQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeetingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeetingQuery({
 *   variables: {
 *      idMeeting: // value for 'idMeeting'
 *   },
 * });
 */
export function useMeetingQuery(baseOptions: Apollo.QueryHookOptions<MeetingQuery, MeetingQueryVariables>) {
        return Apollo.useQuery<MeetingQuery, MeetingQueryVariables>(MeetingDocument, baseOptions);
      }
export function useMeetingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeetingQuery, MeetingQueryVariables>) {
          return Apollo.useLazyQuery<MeetingQuery, MeetingQueryVariables>(MeetingDocument, baseOptions);
        }
export type MeetingQueryHookResult = ReturnType<typeof useMeetingQuery>;
export type MeetingLazyQueryHookResult = ReturnType<typeof useMeetingLazyQuery>;
export type MeetingQueryResult = Apollo.QueryResult<MeetingQuery, MeetingQueryVariables>;
export const MemberTemplatesDocument = gql`
    query MemberTemplates($memberId: Float!, $organizationId: Float!) {
  memberTemplates(memberId: $memberId, organizationId: $organizationId) {
    id
    emoji
    title
  }
}
    `;

/**
 * __useMemberTemplatesQuery__
 *
 * To run a query within a React component, call `useMemberTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberTemplatesQuery({
 *   variables: {
 *      memberId: // value for 'memberId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useMemberTemplatesQuery(baseOptions: Apollo.QueryHookOptions<MemberTemplatesQuery, MemberTemplatesQueryVariables>) {
        return Apollo.useQuery<MemberTemplatesQuery, MemberTemplatesQueryVariables>(MemberTemplatesDocument, baseOptions);
      }
export function useMemberTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MemberTemplatesQuery, MemberTemplatesQueryVariables>) {
          return Apollo.useLazyQuery<MemberTemplatesQuery, MemberTemplatesQueryVariables>(MemberTemplatesDocument, baseOptions);
        }
export type MemberTemplatesQueryHookResult = ReturnType<typeof useMemberTemplatesQuery>;
export type MemberTemplatesLazyQueryHookResult = ReturnType<typeof useMemberTemplatesLazyQuery>;
export type MemberTemplatesQueryResult = Apollo.QueryResult<MemberTemplatesQuery, MemberTemplatesQueryVariables>;
export const NotesTemplatesDocument = gql`
    query NotesTemplates {
  notesTemplates {
    id
    emoji
    title
    notes {
      id
      updatedAt
      checked
      type
      children {
        text
        type
        children {
          text
          type
          children {
            text
            type
            children {
              text
              type
              children {
                text
                type
                children {
                  text
                  type
                  children {
                    text
                    type
                    children {
                      text
                      type
                      children {
                        text
                        type
                        children {
                          text
                          type
                          children {
                            text
                            type
                            children {
                              text
                              type
                              children {
                                text
                                type
                                children {
                                  text
                                  type
                                  children {
                                    text
                                    type
                                    children {
                                      text
                                      type
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    properties {
      id
      type
      name
      generated
      content {
        text
        color
      }
      options {
        text
        color
        description
      }
    }
    accessRights {
      accessRightLevel
      ownerId
      teamId
    }
    exports {
      notion {
        parentPageId
      }
    }
  }
}
    `;

/**
 * __useNotesTemplatesQuery__
 *
 * To run a query within a React component, call `useNotesTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotesTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotesTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotesTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<NotesTemplatesQuery, NotesTemplatesQueryVariables>) {
        return Apollo.useQuery<NotesTemplatesQuery, NotesTemplatesQueryVariables>(NotesTemplatesDocument, baseOptions);
      }
export function useNotesTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotesTemplatesQuery, NotesTemplatesQueryVariables>) {
          return Apollo.useLazyQuery<NotesTemplatesQuery, NotesTemplatesQueryVariables>(NotesTemplatesDocument, baseOptions);
        }
export type NotesTemplatesQueryHookResult = ReturnType<typeof useNotesTemplatesQuery>;
export type NotesTemplatesLazyQueryHookResult = ReturnType<typeof useNotesTemplatesLazyQuery>;
export type NotesTemplatesQueryResult = Apollo.QueryResult<NotesTemplatesQuery, NotesTemplatesQueryVariables>;
export const NotionWorkspacesDocument = gql`
    query NotionWorkspaces {
  notionWorkspaces {
    database {
      id
      name
      type
    }
  }
}
    `;

/**
 * __useNotionWorkspacesQuery__
 *
 * To run a query within a React component, call `useNotionWorkspacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotionWorkspacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotionWorkspacesQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotionWorkspacesQuery(baseOptions?: Apollo.QueryHookOptions<NotionWorkspacesQuery, NotionWorkspacesQueryVariables>) {
        return Apollo.useQuery<NotionWorkspacesQuery, NotionWorkspacesQueryVariables>(NotionWorkspacesDocument, baseOptions);
      }
export function useNotionWorkspacesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotionWorkspacesQuery, NotionWorkspacesQueryVariables>) {
          return Apollo.useLazyQuery<NotionWorkspacesQuery, NotionWorkspacesQueryVariables>(NotionWorkspacesDocument, baseOptions);
        }
export type NotionWorkspacesQueryHookResult = ReturnType<typeof useNotionWorkspacesQuery>;
export type NotionWorkspacesLazyQueryHookResult = ReturnType<typeof useNotionWorkspacesLazyQuery>;
export type NotionWorkspacesQueryResult = Apollo.QueryResult<NotionWorkspacesQuery, NotionWorkspacesQueryVariables>;
export const OrganizationDocument = gql`
    query Organization($id: Float!) {
  organization(id: $id) {
    id
    name
    members {
      id
      role
      email
      name
      integrationTemplates
    }
    teams {
      id
      name
      members {
        id
        role
        email
        name
      }
    }
    integrations {
      id
      isActive
      type
    }
  }
}
    `;

/**
 * __useOrganizationQuery__
 *
 * To run a query within a React component, call `useOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationQuery(baseOptions: Apollo.QueryHookOptions<OrganizationQuery, OrganizationQueryVariables>) {
        return Apollo.useQuery<OrganizationQuery, OrganizationQueryVariables>(OrganizationDocument, baseOptions);
      }
export function useOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationQuery, OrganizationQueryVariables>) {
          return Apollo.useLazyQuery<OrganizationQuery, OrganizationQueryVariables>(OrganizationDocument, baseOptions);
        }
export type OrganizationQueryHookResult = ReturnType<typeof useOrganizationQuery>;
export type OrganizationLazyQueryHookResult = ReturnType<typeof useOrganizationLazyQuery>;
export type OrganizationQueryResult = Apollo.QueryResult<OrganizationQuery, OrganizationQueryVariables>;
export const ParticipantDocument = gql`
    query Participant {
  participant {
    id
    voiceSamples {
      id
      url
      text
    }
  }
}
    `;

/**
 * __useParticipantQuery__
 *
 * To run a query within a React component, call `useParticipantQuery` and pass it any options that fit your needs.
 * When your component renders, `useParticipantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParticipantQuery({
 *   variables: {
 *   },
 * });
 */
export function useParticipantQuery(baseOptions?: Apollo.QueryHookOptions<ParticipantQuery, ParticipantQueryVariables>) {
        return Apollo.useQuery<ParticipantQuery, ParticipantQueryVariables>(ParticipantDocument, baseOptions);
      }
export function useParticipantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ParticipantQuery, ParticipantQueryVariables>) {
          return Apollo.useLazyQuery<ParticipantQuery, ParticipantQueryVariables>(ParticipantDocument, baseOptions);
        }
export type ParticipantQueryHookResult = ReturnType<typeof useParticipantQuery>;
export type ParticipantLazyQueryHookResult = ReturnType<typeof useParticipantLazyQuery>;
export type ParticipantQueryResult = Apollo.QueryResult<ParticipantQuery, ParticipantQueryVariables>;
export const ParticipantsDocument = gql`
    query Participants {
  participants {
    id
    name
  }
}
    `;

/**
 * __useParticipantsQuery__
 *
 * To run a query within a React component, call `useParticipantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useParticipantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParticipantsQuery({
 *   variables: {
 *   },
 * });
 */
export function useParticipantsQuery(baseOptions?: Apollo.QueryHookOptions<ParticipantsQuery, ParticipantsQueryVariables>) {
        return Apollo.useQuery<ParticipantsQuery, ParticipantsQueryVariables>(ParticipantsDocument, baseOptions);
      }
export function useParticipantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ParticipantsQuery, ParticipantsQueryVariables>) {
          return Apollo.useLazyQuery<ParticipantsQuery, ParticipantsQueryVariables>(ParticipantsDocument, baseOptions);
        }
export type ParticipantsQueryHookResult = ReturnType<typeof useParticipantsQuery>;
export type ParticipantsLazyQueryHookResult = ReturnType<typeof useParticipantsLazyQuery>;
export type ParticipantsQueryResult = Apollo.QueryResult<ParticipantsQuery, ParticipantsQueryVariables>;
export const SavedSearchsDocument = gql`
    query SavedSearchs {
  savedSearchs {
    id
    accessRights {
      accessRightLevel
      ownerId
    }
    query
    filters {
      id
      key
      relation
      value
    }
    type
    emoji
    title
    order
  }
}
    `;

/**
 * __useSavedSearchsQuery__
 *
 * To run a query within a React component, call `useSavedSearchsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSavedSearchsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSavedSearchsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSavedSearchsQuery(baseOptions?: Apollo.QueryHookOptions<SavedSearchsQuery, SavedSearchsQueryVariables>) {
        return Apollo.useQuery<SavedSearchsQuery, SavedSearchsQueryVariables>(SavedSearchsDocument, baseOptions);
      }
export function useSavedSearchsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SavedSearchsQuery, SavedSearchsQueryVariables>) {
          return Apollo.useLazyQuery<SavedSearchsQuery, SavedSearchsQueryVariables>(SavedSearchsDocument, baseOptions);
        }
export type SavedSearchsQueryHookResult = ReturnType<typeof useSavedSearchsQuery>;
export type SavedSearchsLazyQueryHookResult = ReturnType<typeof useSavedSearchsLazyQuery>;
export type SavedSearchsQueryResult = Apollo.QueryResult<SavedSearchsQuery, SavedSearchsQueryVariables>;
export const SharedPageDocument = gql`
    query SharedPage($id: String!) {
  sharedPage(id: $id) {
    documentId
    type
    meetingId
  }
}
    `;

/**
 * __useSharedPageQuery__
 *
 * To run a query within a React component, call `useSharedPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSharedPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSharedPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSharedPageQuery(baseOptions: Apollo.QueryHookOptions<SharedPageQuery, SharedPageQueryVariables>) {
        return Apollo.useQuery<SharedPageQuery, SharedPageQueryVariables>(SharedPageDocument, baseOptions);
      }
export function useSharedPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SharedPageQuery, SharedPageQueryVariables>) {
          return Apollo.useLazyQuery<SharedPageQuery, SharedPageQueryVariables>(SharedPageDocument, baseOptions);
        }
export type SharedPageQueryHookResult = ReturnType<typeof useSharedPageQuery>;
export type SharedPageLazyQueryHookResult = ReturnType<typeof useSharedPageLazyQuery>;
export type SharedPageQueryResult = Apollo.QueryResult<SharedPageQuery, SharedPageQueryVariables>;
export const SnippetsLibrariesDocument = gql`
    query SnippetsLibraries {
  snippetsLibraries {
    id
    accessRights {
      accessRightLevel
      ownerId
    }
    title
    emoji
  }
}
    `;

/**
 * __useSnippetsLibrariesQuery__
 *
 * To run a query within a React component, call `useSnippetsLibrariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSnippetsLibrariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSnippetsLibrariesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSnippetsLibrariesQuery(baseOptions?: Apollo.QueryHookOptions<SnippetsLibrariesQuery, SnippetsLibrariesQueryVariables>) {
        return Apollo.useQuery<SnippetsLibrariesQuery, SnippetsLibrariesQueryVariables>(SnippetsLibrariesDocument, baseOptions);
      }
export function useSnippetsLibrariesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SnippetsLibrariesQuery, SnippetsLibrariesQueryVariables>) {
          return Apollo.useLazyQuery<SnippetsLibrariesQuery, SnippetsLibrariesQueryVariables>(SnippetsLibrariesDocument, baseOptions);
        }
export type SnippetsLibrariesQueryHookResult = ReturnType<typeof useSnippetsLibrariesQuery>;
export type SnippetsLibrariesLazyQueryHookResult = ReturnType<typeof useSnippetsLibrariesLazyQuery>;
export type SnippetsLibrariesQueryResult = Apollo.QueryResult<SnippetsLibrariesQuery, SnippetsLibrariesQueryVariables>;
export const SpeakerTimeDocument = gql`
    query SpeakerTime($meetingId: String!, $speaker: String!) {
  speakerTime(meetingId: $meetingId, speaker: $speaker) {
    spokenTimePercentThisMeeting
    spokenTimePercentSimilarMeetings
  }
}
    `;

/**
 * __useSpeakerTimeQuery__
 *
 * To run a query within a React component, call `useSpeakerTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpeakerTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpeakerTimeQuery({
 *   variables: {
 *      meetingId: // value for 'meetingId'
 *      speaker: // value for 'speaker'
 *   },
 * });
 */
export function useSpeakerTimeQuery(baseOptions: Apollo.QueryHookOptions<SpeakerTimeQuery, SpeakerTimeQueryVariables>) {
        return Apollo.useQuery<SpeakerTimeQuery, SpeakerTimeQueryVariables>(SpeakerTimeDocument, baseOptions);
      }
export function useSpeakerTimeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SpeakerTimeQuery, SpeakerTimeQueryVariables>) {
          return Apollo.useLazyQuery<SpeakerTimeQuery, SpeakerTimeQueryVariables>(SpeakerTimeDocument, baseOptions);
        }
export type SpeakerTimeQueryHookResult = ReturnType<typeof useSpeakerTimeQuery>;
export type SpeakerTimeLazyQueryHookResult = ReturnType<typeof useSpeakerTimeLazyQuery>;
export type SpeakerTimeQueryResult = Apollo.QueryResult<SpeakerTimeQuery, SpeakerTimeQueryVariables>;
export const TeamDocument = gql`
    query Team($teamId: String!) {
  team(teamId: $teamId) {
    name
  }
}
    `;

/**
 * __useTeamQuery__
 *
 * To run a query within a React component, call `useTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useTeamQuery(baseOptions: Apollo.QueryHookOptions<TeamQuery, TeamQueryVariables>) {
        return Apollo.useQuery<TeamQuery, TeamQueryVariables>(TeamDocument, baseOptions);
      }
export function useTeamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamQuery, TeamQueryVariables>) {
          return Apollo.useLazyQuery<TeamQuery, TeamQueryVariables>(TeamDocument, baseOptions);
        }
export type TeamQueryHookResult = ReturnType<typeof useTeamQuery>;
export type TeamLazyQueryHookResult = ReturnType<typeof useTeamLazyQuery>;
export type TeamQueryResult = Apollo.QueryResult<TeamQuery, TeamQueryVariables>;
export const TimelinesClustersDocument = gql`
    query TimelinesClusters($idMeeting: String!) {
  timelinesClusters(idMeeting: $idMeeting) {
    block
    turns
    updatedAtInRecording
  }
}
    `;

/**
 * __useTimelinesClustersQuery__
 *
 * To run a query within a React component, call `useTimelinesClustersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimelinesClustersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimelinesClustersQuery({
 *   variables: {
 *      idMeeting: // value for 'idMeeting'
 *   },
 * });
 */
export function useTimelinesClustersQuery(baseOptions: Apollo.QueryHookOptions<TimelinesClustersQuery, TimelinesClustersQueryVariables>) {
        return Apollo.useQuery<TimelinesClustersQuery, TimelinesClustersQueryVariables>(TimelinesClustersDocument, baseOptions);
      }
export function useTimelinesClustersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimelinesClustersQuery, TimelinesClustersQueryVariables>) {
          return Apollo.useLazyQuery<TimelinesClustersQuery, TimelinesClustersQueryVariables>(TimelinesClustersDocument, baseOptions);
        }
export type TimelinesClustersQueryHookResult = ReturnType<typeof useTimelinesClustersQuery>;
export type TimelinesClustersLazyQueryHookResult = ReturnType<typeof useTimelinesClustersLazyQuery>;
export type TimelinesClustersQueryResult = Apollo.QueryResult<TimelinesClustersQuery, TimelinesClustersQueryVariables>;
export const UserIntegrationsDocument = gql`
    query UserIntegrations {
  userIntegrations {
    id
    type
    token
    updatedAt
    isActive
  }
}
    `;

/**
 * __useUserIntegrationsQuery__
 *
 * To run a query within a React component, call `useUserIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserIntegrationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserIntegrationsQuery(baseOptions?: Apollo.QueryHookOptions<UserIntegrationsQuery, UserIntegrationsQueryVariables>) {
        return Apollo.useQuery<UserIntegrationsQuery, UserIntegrationsQueryVariables>(UserIntegrationsDocument, baseOptions);
      }
export function useUserIntegrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserIntegrationsQuery, UserIntegrationsQueryVariables>) {
          return Apollo.useLazyQuery<UserIntegrationsQuery, UserIntegrationsQueryVariables>(UserIntegrationsDocument, baseOptions);
        }
export type UserIntegrationsQueryHookResult = ReturnType<typeof useUserIntegrationsQuery>;
export type UserIntegrationsLazyQueryHookResult = ReturnType<typeof useUserIntegrationsLazyQuery>;
export type UserIntegrationsQueryResult = Apollo.QueryResult<UserIntegrationsQuery, UserIntegrationsQueryVariables>;
export const UserTeamsDocument = gql`
    query UserTeams {
  userTeams {
    organizationId
    teams {
      id
      name
    }
  }
}
    `;

/**
 * __useUserTeamsQuery__
 *
 * To run a query within a React component, call `useUserTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTeamsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserTeamsQuery(baseOptions?: Apollo.QueryHookOptions<UserTeamsQuery, UserTeamsQueryVariables>) {
        return Apollo.useQuery<UserTeamsQuery, UserTeamsQueryVariables>(UserTeamsDocument, baseOptions);
      }
export function useUserTeamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserTeamsQuery, UserTeamsQueryVariables>) {
          return Apollo.useLazyQuery<UserTeamsQuery, UserTeamsQueryVariables>(UserTeamsDocument, baseOptions);
        }
export type UserTeamsQueryHookResult = ReturnType<typeof useUserTeamsQuery>;
export type UserTeamsLazyQueryHookResult = ReturnType<typeof useUserTeamsLazyQuery>;
export type UserTeamsQueryResult = Apollo.QueryResult<UserTeamsQuery, UserTeamsQueryVariables>;
export const SubscribeToMeetingDocument = gql`
    subscription SubscribeToMeeting($topic: String!) {
  subscribeToMeeting(topic: $topic) {
    id
    speaker
    text
    timestamp
    url
    words {
      id
      word
      start
      end
      inferences {
        acts {
          macro
          detailed
        }
        sentiment
        thematics
      }
    }
    metadata {
      seqId
      start
      end
      duration
      createdAt
      endedAt
    }
  }
}
    `;

/**
 * __useSubscribeToMeetingSubscription__
 *
 * To run a query within a React component, call `useSubscribeToMeetingSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToMeetingSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToMeetingSubscription({
 *   variables: {
 *      topic: // value for 'topic'
 *   },
 * });
 */
export function useSubscribeToMeetingSubscription(baseOptions: Apollo.SubscriptionHookOptions<SubscribeToMeetingSubscription, SubscribeToMeetingSubscriptionVariables>) {
        return Apollo.useSubscription<SubscribeToMeetingSubscription, SubscribeToMeetingSubscriptionVariables>(SubscribeToMeetingDocument, baseOptions);
      }
export type SubscribeToMeetingSubscriptionHookResult = ReturnType<typeof useSubscribeToMeetingSubscription>;
export type SubscribeToMeetingSubscriptionResult = Apollo.SubscriptionResult<SubscribeToMeetingSubscription>;