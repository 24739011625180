import styled, { css } from "styled-components";
type Props = {
  primary?: boolean;
  large?: boolean;
};

export const Button = styled.button`
  margin: 2px;
  background: ${({ primary }: Props) =>
    primary ? (props) => props.theme.color.primary : "white"};
  height: ${({ large }: Props) => (large ? "40px" : "20px")};
  cursor: pointer;
  color: ${({ primary }: Props) =>
    primary ? "white" : (props) => props.theme.color.primary};
  border: none;
  width: ${({ large }: Props) => (large ? "150px" : "60px")};
  border: 1px solid ${(props) => props.theme.color.primary};
  box-sizing: border-box;
  border-radius: 4px;
  ${(props) =>
    props.primary &&
    css`
      box-shadow: 0px 0px 2px ${(props) => props.theme.color.primary};
    `}
  outline: 0px transparent !important;
  // FONT CSS TODO: Specifier pour les petits style
  font-family: ${(props) => props.theme.font.headline};
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.25em;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.03);
  }
`;

export const SliderButton = styled.button``;

export const HoverButton = styled.button`
  font-family: ${(props) => props.theme.font.fullText};
  font-style: normal;
  font-weight: 300;
  font-size: ${(props) => props.theme.fontSize.medium};
  line-height: 12px;
  letter-spacing: 2px;
  color: #464646;
  cursor: pointer;
  border: none;
  background: transparent;

  margin-bottom: 10px;
  align-self: flex-end;
  justify-self: flex-start;

  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.03);
  }
  animation: 1.5s ease-out 0s 1 fadeIn;
  @keyframes fadeIn {
    0% {
      opacity: 0;
      //transform: translateX(-100%);
    }
    100% {
      opacity: 1;
      //transform: translateX(0);
    }
  }
`;

export const AddButton = styled.button`
  background: linear-gradient(138.28deg, #e1e1e6 -38.92%, #ffffff 188.83%);
  box-shadow: 3px 3px 6px #cad9ff, -3px -3px 6px #ffffff,
    inset 4px 10px 50px rgba(255, 255, 255, 0.5);
  height: 25px;
  width: 25px;
  border-radius: 50%;

  margin-left: auto;
  margin-right: 25px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${(props) => props.theme.color.grey};
  font-family: ${(props) => props.theme.font.fullText};
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  color: #696969;
  text-shadow: 0px 0px 10px #cad9ff;

  margin-bottom: 5px;
  align-self: flex-end;
  justify-self: center;

  cursor: pointer;
  border: none;

  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.03);
  }
`;

export const DeleteButton = styled.button`
  margin: 2px;
  background: white;
  height: ${({ large }: Props) => (large ? "30px" : "20px")};
  cursor: pointer;
  color: ${(props) => props.theme.color.red};
  border: none;
  width: ${({ large }: Props) => (large ? "90px" : "60px")};
  border: 1px solid ${(props) => props.theme.color.red};
  box-sizing: border-box;
  border-radius: 4px;

  // FONT CSS TODO: Specifier pour les petits style
  font-family: ${(props) => props.theme.font.headline};
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.25em;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.03);
  }
`;
