import { createGlobalStyle } from "styled-components";

export const theme = {
  color: {
    gradient: "linear-gradient(93.04deg, #FF3350 0.05%, #FF566E 103.78%)",
    primary: "#ff3350", //ff3350 66c7ff
    primaryRGBA: "0, 112, 243",
    searchBackground: "#f5f8ff",
    grey: "#696969",
    red: "#FF2F55",
    black: "#25313C",
    backgroundSecondary: "#f1f1f1",
  },
  font: {
    headline: "'Quicksand', sans-serif",
    fullText: "'Inter', sans-serif",
  },
  fontSize: {
    XLarge: "24px",
    large: "14px",
    medium: "11px",
    small: "10px",
    xsmall: "8px",
  },
  radius: {
    small: "3px",
  },
};

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
  }
`;
