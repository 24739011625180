import React, { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { useLogoutMutation, useMeQuery } from "../generated/graphql";
import { useNavigate, useLocation } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { animateScroll } from "react-scroll";

import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtnLink,
  NavFocus,
  NavLeftPart,
  NonLoggedNavBtnLink,
} from "./styles/NavBarElements";
import styled from "styled-components";
import { motion } from "framer-motion";
import logo192 from "./logo192.png";
import { Link } from "react-router-dom";

type NavBarProps = {
  toggle: () => void;
};

const WaterMelon = styled(motion.span)`
  font-size: 32px;
  margin-left: 10px;
  margin-bottom: 8px;
`;
const Grad = styled.span`
  background: ${(props) => props.theme.color.gradient};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const DownloadExtensionLink = styled.a`
  color: ${(props) => props.theme.color.primary};
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 30%;
  margin-top: 10px;
  margin-left: 20px;
  cursor: pointer;
  font-family: ${(props) => props.theme.font.headline};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;

  /* identical to box height */
  letter-spacing: 0.075em;
  border: 1px solid ${(props) => props.theme.color.primary};
  border-radius: 4px;
  min-width: 140px;
`;

const RealMeeting = styled(Link)`
  text-decoration: none;
  width: 160px;
  height: 30%;
  margin-top: 10px;
  padding: 0 0.5rem;
  font-family: ${(props) => props.theme.font.headline};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  border: 1px solid #fadec9;
  border-radius: 4px;

  display: flex;
  align-items: center;
  justify-content: center;
  color: #804818;
`;

const Logo = styled.img``;

const Navbar2: React.FC<NavBarProps> = ({ toggle }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { data, loading } = useMeQuery();
  const [logout] = useLogoutMutation();
  const apolloClient = useApolloClient();
  const [focus, setFocus] = useState(pathname);
  let body;
  const toggleHome = () => {
    animateScroll.scrollToTop();
  };
  useEffect(() => {
    setFocus(pathname);
  }, [pathname]);

  if (loading) {
    body = <div></div>;
    //data is loading
  } else if (!data?.me) {
    body = (
      <>
        <NavLogo
          to="/"
          onClick={() => {
            if (pathname === "/") {
              toggleHome();
            }
          }}>
          Sweez <Grad>.</Grad>io{" "}
          <WaterMelon whileHover={{ rotate: 360 }} transition={{ duration: 1 }}>
            <Logo src={logo192} height="40" width="40" />
          </WaterMelon>
        </NavLogo>

        <NavMenu>
          <NavItem>
            <NonLoggedNavBtnLink large primary to="/earlyaccess">
              SIGN UP
            </NonLoggedNavBtnLink>
          </NavItem>
        </NavMenu>
      </>
    );
  } else if (data.me) {
    body = (
      <>
        <NavLeftPart>
          {/*<AppLogo src={logo} alt="" /> */}
          <NavLogo to="/">
            Sweez.io{" "}
            <WaterMelon>
              <Logo src={logo192} height="40" width="40" />
            </WaterMelon>
          </NavLogo>

          <MobileIcon onClick={toggle}>
            <FaBars style={{ color: "#000" }} />
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavLinks to="/">HOME</NavLinks>
              {focus === "/" ||
              focus.includes("meeting") ||
              focus.includes("room") ? (
                <NavFocus />
              ) : null}
            </NavItem>
            <NavItem>
              <NavLinks to="/templates">TEMPLATES</NavLinks>
              {focus.includes("/templates") && <NavFocus />}
            </NavItem>

            <NavItem>
              <NavLinks to="/settings">SETTINGS</NavLinks>
              {["organization", "settings", "integrations"].some((p) =>
                focus.includes(p)
              ) && <NavFocus />}
            </NavItem>
          </NavMenu>
        </NavLeftPart>

        <NavMenu>
          {/* <NavItem>
            <RealMeeting to="/real-meeting">Start a Meeting</RealMeeting>
         </NavItem>*/}
        </NavMenu>

        <NavMenu>
          <NavItem>
            <NavBtnLink
              style={{ width: "44px" }}
              to="/"
              onClick={async () => {
                apolloClient.cache.reset();
                await logout();
                navigate("/");
                window.location.reload();
              }}>
              Log out
            </NavBtnLink>
          </NavItem>
        </NavMenu>
      </>
    );
  }

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav>
          <NavbarContainer>{body}</NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};
export default Navbar2;
