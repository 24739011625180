import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Helmet } from "react-helmet";
ReactDOM.render(
  <React.StrictMode>
    <Helmet>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Sweez helps PMs focus on the right problem"
      />

      <meta property="og:title" content="Sweez.io" />
      <meta
        property="og:image"
        content="https://images.emojiterra.com/google/android-11/512px/1f349.png"
      />
      <meta
        property="og:description"
        content="Sweez helps PMs focus on the right problem"
      />
      <meta property="og:url" content="https://sweez.io" />

      <title>Sweez.io</title>
      <link rel="canonical" href="https://sweez.io" title="Sweez.io" />
      <script
        defer
        type="text/javascript"
        src="https://api.pirsch.io/pirsch.js"
        id="pirschjs"
        data-code="lPr8hEWQbQJjUSUtpm1pvR2sahKDrGll"></script>
    </Helmet>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
