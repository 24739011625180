import React from "react";
import styled from "styled-components";
import { Button } from "../../../components/styles/ButtonsElements";
import { useNavigate } from "react-router";
import hero from "./assets/hero.png";
type SectionProps = {};

const Container = styled.div`
  height: 90vh;
  min-height: 800px;
  width: 90vw;

  display: flex;

  @media (min-width: 1600px) {
    width: 80vw;
  }

  @media (max-width: 768px) {
    width: 90vw;
    flex-direction: column;
  }
`;

const ContentContainer = styled.div`
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 1200px) {
    width: 50%;
  }

  @media (max-width: 768px) {
    align-items: center;
    text-align: center;
    width: 100%;
    height: 50%;
  }
`;

const VideoContainer = styled.div`
  width: 55%;
  height: 100%;
  display: flex;
  align-items: center;

  @media (max-width: 1200px) {
    width: 50%;
  }

  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
    height: 50%;
  }
`;

const Title = styled.div`
  min-height: 120px;
  margin: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 50px; //4.5rem;

  display: flex;
  flex-direction: column;

  color: ${(props) => props.theme.color.black};
  font-family: ${(props) => props.theme.font.headline};

  position: relative;

  @media (max-width: 1500px) {
    font-size: 38px;
  }

  @media (max-width: 1080px) {
    font-size: 38px;
  }

  @media (max-width: 1028px) {
    font-size: 40px;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
  }
  @media (max-width: 415px) {
    font-size: 30px;
  }

  @media (max-width: 320px) {
    font-size: 25px;
  }
`;

const Description = styled.div`
  margin-top: 10px;
  font-family: ${(props) => props.theme.font.fullText};
  color: ${(props) => props.theme.color.black};

  font-size: 17px;
  line-height: 34px;

  display: flex;
  flex-direction: column;
  width: 95%;

  @media (max-width: 768px) {
    font-size: 15px;
    margin-top: 20px;
  }

  @media (max-width: 415px) {
    font-size: 14px;
    line-height: 27px;
  }
`;

const CTAButton = styled(Button)`
  width: 140px;
  min-height: 40px;
  margin-top: 50px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
`;

const Italic = styled.span`
  font-size: 80%;
  margin-left: -5px;
`;

const HorizontalHero: React.FC<SectionProps> = () => {
  const navigate = useNavigate();

  const width = window.screen.width;

  return (
    <Container>
      <ContentContainer>
        <Title>
          Automatic Meeting Notes
          <Italic>For Busy Tech Managers</Italic>
        </Title>
        <Description>
          You lose 90% of information during meetings. <br /> That's how much
          valuable intel you miss about your customers & your team. <br /> Sweez
          helps you drop that figure to 0%.
        </Description>
        <CTAButton primary onClick={() => navigate("/earlyaccess")}>
          SIGN UP
        </CTAButton>
      </ContentContainer>
      <VideoContainer>
        <img
          style={{ height: width > 1300 ? "80%" : width > 768 ? "60%" : "70%" }}
          src={hero}
          alt="hero"
        />
      </VideoContainer>
    </Container>
  );
};

export default HorizontalHero;
