import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

type ButtonProps = {
  primary?: boolean;
  large?: boolean;
};
export const Nav = styled.nav`
  background: white;
  height: 80px;
  display: flex;
  font-size: 1rem;
  //position: sticky;
  top: 0;
  z-index: 100;

  //border: 1px solid rgba(163, 188, 224, 0.25);
  box-sizing: border-box;

  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: 100%;
`;

export const AppLogo = styled.img`
  display: flex;
  margin-left: -25px;
  padding: 0;
  width: 115px;
  height: 115px;
`;

export const NavLogo = styled(Link)`
  color: #000;
  justify-self: flex-start;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: -25px;
  font-weight: bold;
  text-decoration: none;
  font-family: ${(props) => props.theme.font.headline};
  font-style: normal;
  font-weight: 900;
  font-size: 26px;
  line-height: 32px;

  letter-spacing: 2px;

  margin-left: 0px;

  color: #25313c;
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const NavLeftPart = styled.div`
  width: 70%;
  display: flex;
`;

export const NavMenu = styled.ul`
  padding: 0;
  margin-left: 5%;
  height: 70px;
  display: flex;

  list-style: none;
  text-align: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const NavFocus = styled.div`
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background: ${(props) => props.theme.color.primary};
  box-shadow: 0px 0px 4px ${(props) => props.theme.color.primary};
  opacity: 1;
  animation: flickerAnimation 0.8s;

  @keyframes flickerAnimation {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const NavLinks = styled(Link)`
  color: #000;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1.5rem;
  height: 60%;
  cursor: pointer;
  font-family: ${(props) => props.theme.font.headline};
  font-style: normal;
  font-weight: bold;
  font-size: 8px;
  line-height: 10px;

  /* identical to box height */
  letter-spacing: 0.25em;
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  font-family: ${(props) => props.theme.font.headline};

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavBtnLink = styled(Link)`
  color: ${(props) => props.theme.color.grey};
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1.5rem;
  height: 60%;
  cursor: pointer;
  font-family: ${(props) => props.theme.font.headline};
  font-style: normal;
  font-weight: bold;
  font-size: 8px;
  line-height: 10px;

  /* identical to box height */
  letter-spacing: 0.25em;
  &:hover {
    transform: scale(1.15);
  }
`;

export const NonLoggedNavBtnLink = styled(Link)`
  margin: 2px;
  margin-right: 10px;
  background: ${({ primary }: ButtonProps) =>
    primary ? (props) => props.theme.color.primary : "white"};
  height: ${({ large }: ButtonProps) => (large ? "40px" : "20px")};
  cursor: pointer;
  color: ${({ primary }: ButtonProps) =>
    primary ? "white" : (props) => props.theme.color.primary};
  border: none;
  width: ${({ large }: ButtonProps) => (large ? "140px" : "60px")};
  border: 1px solid ${(props) => props.theme.color.primary};
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  ${(props) =>
    props.primary &&
    css`
      box-shadow: 0px 0px 2px ${(props) => props.theme.color.primary};
    `}
  outline: 0px transparent !important;
  font-family: ${(props) => props.theme.font.headline};
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 3px;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.03);
  }
`;
