import React, { useState } from "react";
import styled from "styled-components";
import { useApplyToBetaMutation } from "../../generated/graphql";
import { useFormik } from "formik";

type WaitingListProps = {};
enum Application {
  APPLIED,
  NOT_APPLIED,
  ERROR_APPLYING,
}
const Container = styled.div`
  height: 80vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: ${(props) => props.theme.font.headline};
`;

const Title = styled.div`
  font-size: 60px;
  font-weight: 500;
  color: ${(props) => props.theme.color.black};
  margin-bottom: 25px;
  position: relative;
`;

const Description = styled.div`
  margin-top: 10px;
  font-family: ${(props) => props.theme.font.headline};

  font-size: 20px;
  line-height: 34px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.5px;
  width: 800px;
  margin-bottom: 35px;
  user-select: none;

  @media (max-width: 768px) {
    width: 670px;
  }

  @media (max-width: 414px) {
    width: 350px;
    font-size: 14px;
    line-height: 26px;
  }
`;

const Email = styled.input`
  outline: none;
  height: 40px;
  width: 270px;
  padding: 0;
  margin: 0;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.color.primary};
  margin-bottom: 10px;
  padding-left: 10px;
`;

const ErrorMessage = styled.div`
  outline: none;
  height: 40px;
  width: 450px;
  padding: 0;
  margin: 0;
  border-radius: 4px;
  color: ${(props) => props.theme.color.primary};
  margin-bottom: 10px;
  padding-left: 10px;
  text-align: center;
  font-weight: 500;
`;

const RegisterButton = styled.div`
  cursor: pointer;
  color: white;
  background: ${(props) => props.theme.color.primary};
  box-shadow: 0px 0px 3px ${(props) => props.theme.color.primary};
  height: 40px;
  width: 280px;
  text-align: center;
  vertical-align: center;
  line-height: 40px;
  border-radius: 4px;

  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 2px;
`;

const EarlyAccessTag = styled.div`
  height: 30px;
  font-size: 20px;
  text-align: center;
  vertical-align: center;
  line-height: 30px;
  border: 1px solid ${(props) => props.theme.color.primary};
  color: ${(props) => props.theme.color.primary};
  font-weight: 500;

  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
  transform: rotate(45deg);
  position: absolute;
  right: -100px;
  top: -25px;
  word-spacing: 4px;

  z-index: 5000000;

  user-select: none;

  @media (max-width: 414px) {
    right: -20px;
    top: -25px;
    height: 16px;
    font-size: 12px;
    line-height: 16px;
  }
`;

const WaitingList: React.FC<WaitingListProps> = () => {
  const [applied, setApplied] = useState(Application.NOT_APPLIED);
  const [applyToBeta] = useApplyToBetaMutation();
  const formik = useFormik({
    initialValues: { email: "" },
    onSubmit: async ({ email }) => {
      const applied = await applyToBeta({ variables: { email } });
      if (applied.data?.applyToBeta) {
        setApplied(Application.APPLIED);
      } else {
        setApplied(Application.ERROR_APPLYING);
      }
    },
  });
  return (
    <Container>
      <Title>
        Sign Up 🍉 <EarlyAccessTag>Early Access</EarlyAccessTag>
      </Title>
      {applied !== Application.APPLIED ? (
        <Description>
          Sweez is currently in technical preview, access is limited to a small
          number of testers. Sign up and get a chance to help us improve Sweez !
        </Description>
      ) : (
        <Description>
          Congratz ! Application is done ! We'll get back to you as soon as a
          spot opens 🤗
        </Description>
      )}
      {applied === Application.ERROR_APPLYING && (
        <ErrorMessage>
          An Error occured during your application 😭 please try again or
          contact hello@sweez.io
        </ErrorMessage>
      )}
      {applied !== Application.APPLIED && (
        <>
          <Email
            id="email"
            name="email"
            type="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            placeholder="Email"
          />
          <RegisterButton
            onClick={() => {
              formik.submitForm();
            }}>
            SIGN UP & JOIN WAITLIST
          </RegisterButton>
        </>
      )}
    </Container>
  );
};

export default WaitingList;
