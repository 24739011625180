import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
//import ReCAPTCHA from "react-google-recaptcha";
import { GoogleLogin } from "react-google-login";
import { useFormik } from "formik";

import {
  useGoogleRegisterOrLoginMutation,
  MeQuery,
  MeDocument,
  useHasAuthorizationMutation,
  useLoginMutation,
} from "../../generated/graphql";

import { AiOutlineGoogle } from "react-icons/ai";
import passwordValidator from "password-validator";
import { useNavigate } from "react-router-dom";
import ForgotPassword from "./ForgotPassword";
import { motion } from "framer-motion";
interface registerProps {}

//COMPONENTS FOR THE NEW VERSION

const Container = styled.div`
  height: 80vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: ${(props) => props.theme.font.headline};
`;

const GoogleButton = styled.div`
  cursor: pointer;
  color: white;
  font-weight: 500;
  letter-spacing: 1px;
  background: ${(props) => props.theme.color.primary};
  box-shadow: 0px 0px 4px ${(props) => props.theme.color.primary};
  height: 40px;
  width: 280px;
  text-align: center;
  vertical-align: center;
  line-height: 40px;
  border-radius: 4px;
`;

const GoogleIcon = styled(AiOutlineGoogle)`
  font-size: 20px;
  position: relative;
  top: 4px;
  left: -4px;
`;
const Title = styled.div`
  font-size: 60px;
  font-weight: 500;
  color: ${(props) => props.theme.color.black};
  margin-bottom: 75px;
`;

const Email = styled.input`
  outline: none;
  height: 40px;
  width: 270px;
  padding: 0;
  margin: 0;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.color.primary};
  margin-bottom: 10px;
  padding-left: 10px;
`;

const Password = styled.input`
  outline: none;
  height: 40px;
  width: 270px;
  padding: 0;
  margin: 0;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.color.primary};
  margin-bottom: 10px;
  padding-left: 10px;
`;

const RegisterButton = styled.div`
  cursor: pointer;
  color: white;
  font-weight: 500;
  letter-spacing: 1px;
  background: ${(props) => props.theme.color.primary};
  box-shadow: 0px 0px 4px ${(props) => props.theme.color.primary};
  height: 40px;
  width: 280px;
  text-align: center;
  vertical-align: center;
  line-height: 40px;
  border-radius: 4px;
`;

const RegisterEmailContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  position: relative;
`;

const Delimiter = styled.span`
  width: 100%;
  border-top: 1px solid #f1f1f1;
  height: 10px;
  margin-bottom: 10px;
`;

const ForgotPasswordButton = styled.div`
  margin-top: 30px;
  text-align: center;
  font-size: 11px;
  max-width: 280px;
  line-height: 18px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const ErrorMessage = styled(motion.div)`
  position: absolute;
  bottom: -50px;
  left: 0%;
  width: 100%;
  border: 1px solid #fca3a3;
  height: 30px;
  border-radius: 4px;
  text-align: center;
  line-height: 30px;
  background: #ffe9e9;
  color: #c02f2f;
  letter-spacing: 1px;
`;

const schema = new passwordValidator();
schema
  .is()
  .min(8)
  .max(100)
  .has()
  .not()
  .spaces()
  .has()
  .digits(2) //
  .is()
  .not()
  .oneOf(["Passw0rd", "Password123"]);

export const Login: React.FC<registerProps> = () => {
  const passwordRef = useRef<HTMLInputElement>(null);
  const [errors, setErrors] = useState("");
  const [forgotPassword, setForgotPassword] = useState(false);
  const [hasAuhtorization] = useHasAuthorizationMutation();
  const [login] = useLoginMutation();
  const [googleRegisterOrLogin] = useGoogleRegisterOrLoginMutation();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: async ({ email, password }) => {
      const validated = schema.validate(password);
      if (!validated) {
        setErrors("Invalid Login");
      }
      if (email !== "") {
        if (validated) {
          const authorized = await hasAuhtorization({
            variables: { email },
          });
          if (authorized.data?.hasAuthorization) {
            const result = await login({
              variables: {
                usernameOrEmail: email,
                password: password,
              },
              update: (cache, { data }) => {
                cache.writeQuery<MeQuery>({
                  query: MeDocument,
                  data: {
                    __typename: "Query",
                    me: data?.login.user,
                  },
                });
              },
            });

            if (result.data?.login.user?.id) {
              navigate("/");
            }
          } else {
            //Not Authorized
            //setErrors("Invalid Login");

            navigate("/earlyaccess");
          }
        }
      }
    },
  });

  useEffect(() => {
    if (errors !== "") {
      setTimeout(() => {
        setErrors("");
      }, 4000);
    }
  }, [errors]);

  return (
    <Container>
      {!forgotPassword ? (
        <>
          <Title>Login</Title>
          <GoogleLogin
            render={(renderProps) => (
              <GoogleButton onClick={renderProps.onClick}>
                <GoogleIcon /> Continue with Google
              </GoogleButton>
            )}
            clientId="1045011037769-c0amgqqlf6phlift8jvbtcfnv9sd8pln.apps.googleusercontent.com"
            onSuccess={async (response) => {
              const data = response as any;
              const loginResult = await googleRegisterOrLogin({
                variables: {
                  token: data.tokenId,
                  accessToken: data.accessToken,
                },
                update: (cache, { data }) => {
                  cache.writeQuery<MeQuery>({
                    query: MeDocument,
                    data: {
                      __typename: "Query",
                      me: data?.googleRegisterOrLogin.user,
                    },
                  });
                },
              });

              if (
                !loginResult.data?.googleRegisterOrLogin.errors &&
                !loginResult.errors
              ) {
                navigate("/");
              } else {
                //setErrors("Invalid Login");
                navigate("/earlyaccess");
              }
            }}
            onFailure={() => {}}
          />
          <RegisterEmailContainer>
            <Delimiter />
            <Email
              type="email"
              placeholder="Email"
              id="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  passwordRef.current!.focus();
                }
              }}
            />
            <Password
              ref={passwordRef}
              type="password"
              placeholder="Password"
              id="password"
              onChange={formik.handleChange}
              value={formik.values.password}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  formik.handleSubmit();
                }
              }}
            />
            <RegisterButton onClick={() => formik.handleSubmit()}>
              Continue with email
            </RegisterButton>
            <ForgotPasswordButton
              onClick={() => {
                setForgotPassword(true);
              }}>
              Forgot Password
            </ForgotPasswordButton>
            {errors !== "" && (
              <ErrorMessage
                initial={{ opacity: 0, y: 25 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ type: "spring", bounce: 0.5 }}>
                {errors}
              </ErrorMessage>
            )}
          </RegisterEmailContainer>{" "}
        </>
      ) : (
        <ForgotPassword />
      )}
    </Container>
  );
};

export default Login;
