import React from "react";
import styled from "styled-components";

import HorizontalHero from "./HomeNotAuthed/HorizontalHero";
import FinalCTASection from "./HomeNotAuthed/FinalCTASection";
import Footer from "./HomeNotAuthed/Footer";
import LandingPageSection from "./HomeNotAuthed/LandingPageSection";
import NotionSection from "./HomeNotAuthed/NotionSection";
import automate from "./HomeNotAuthed/assets/automate.svg";
import search from "./HomeNotAuthed/assets/search.svg";
import activate from "./HomeNotAuthed/assets/activate.svg";

interface HomeNotAuthedProps {}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HomeNotAuthed: React.FC<HomeNotAuthedProps> = () => {
  const { width } = window.screen;
  return (
    <Container>
      <HorizontalHero />
      <>
        <LandingPageSection
          idx={0}
          title={"Don't spend Hours <br/> writing Minutes"}
          tag={"AUTOMATE"}
          lines={[
            "Record & transcribe your meeting, watch your notes come alive in real-time.",
            "Automatic Summarization, Question & Answer Extraction, and more.",
            "Add your own sentences in an instant with a powerful autocomplete.",
          ]}
          svg={automate}
        />

        <LandingPageSection
          idx={1}
          tag={"ANALYZE"}
          title={"Most Valuable <br/>  Knowledge Base"}
          lines={[
            "Search through your notes, recordings & transcripts.",
            "Monitor information flows. Detect tiny clues you would have missed.",
            "Give your team total visibility into what's happening. Always up-to-date.",
          ]}
          svg={search}
          height={width > 768 ? "120%" : "75%"}
          inversed={width > 768}
        />
        <LandingPageSection
          idx={3}
          tag={"ACTIVATE"}
          title={"Make Product-Market Fit <br/> Inevitable"}
          lines={[
            "Track every customers' reactions, clusterize & compare them.",
            "Fine-grained analysis: semantic relationships, sentiment & more.",
            "Prioritize & iterate based on evidences & data rather than intuition.",
          ]}
          svg={activate}
          height={width > 768 ? "80%" : "65%"}
        />
        <NotionSection />
      </>
      <FinalCTASection />

      <Footer />
    </Container>
  );
};

export default HomeNotAuthed;
