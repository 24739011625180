import React from "react";
import styled from "styled-components";
type FooterProps = {};

const Container = styled.div`
  width: 80%;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: ${(props) => props.theme.font.headline};
  font-size: 11px;

  @media (max-width: 768px) {
    height: 7vh;
    width: 98%;
    justify-content: space-around;
    font-size: 9px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
`;

const Content = styled.div`
  margin-top: 10px;
`;

const Grad = styled.span`
  background: ${(props) => props.theme.color.gradient};
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 14px;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const Footer: React.FC<FooterProps> = () => {
  return (
    <Container>
      <Column>
        <Grad>Sweez.io</Grad>
      </Column>
      <Column>Copyright © 2022 Sweez.io All rights reserved.</Column>
      <Column>
        <Grad>Contact Us</Grad>
        <Content>hello@sweez.io</Content>
      </Column>
    </Container>
  );
};

export default Footer;
