import React from "react";
import styled from "styled-components";
import { SiNotion } from "react-icons/si";
import { useColors } from "../../../utils/useColors";
import share from "./assets/share.png";
const Container = styled.div`
  height: 60vh;
  min-height: 700px;
  width: 100%;
  min-width: 99vw;
  z-index: 99999;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 769px) {
    width: 100vw;
    flex-direction: column;
    margin-top: -100px;
    height: 100vh;
  }
`;

const InnerContainer = styled.div`
  height: 90%;
  width: 90vw;
  flex-direction: row-reverse;
  display: flex;
  @media (min-width: 1600px) {
    width: 82.5vw;
  }

  @media (max-width: 769px) {
    width: 100vw;
    flex-direction: column;
    margin-top: -100px;
    height: 100%;
  }
`;

const ContentContainer = styled.div`
  width: 50%;
  margin-left: 100px;
  height: 90%;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    align-items: center;
    text-align: center;
    width: 100%;
    height: 60%;
    margin-left: 0px;
  }
`;

const VideoContainer = styled.div`
  width: 50%;
  height: 90%;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
    height: 60%;
  }
`;

const Title = styled.div`
  height: 120px;
  margin: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 50px; //4.5rem;

  display: flex;
  flex-direction: column;

  font-family: ${(props) => props.theme.font.headline};
  color: ${(props) => props.theme.color.black};

  position: relative;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    font-size: 55px;
    width: 100%;
    margin-bottom: 0px;
  }
  @media (max-width: 415px) {
    font-size: 28px;
  }

  @media (max-width: 320px) {
    font-size: 24px;
  }
`;

const DescriptionLine = styled.div`
  margin-top: 20px;
  font-family: ${(props) => props.theme.font.fullText};
  color: ${(props) => props.theme.color.black};

  font-size: 20px;
  line-height: 34px;

  display: flex;

  @media (max-width: 768px) {
    font-size: 17px;
    margin-top: 20px;
  }

  @media (max-width: 415px) {
    font-size: 14px;
    line-height: 27px;
  }
`;

const Tag = styled.div`
  margin-left: 3px;
  padding: 4px;
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 50px;
  margin-bottom: 10px;
  letter-spacing: 1.25px;
  font-weight: 500;

  font-size: 12.5px;
  font-family: ${(props) => props.theme.font.fullText};

  border-radius: 2px;
  width: fit-content;
`;
const NotionSection: React.FC = () => {
  const colors = useColors();
  const { width } = window.screen;
  return (
    <Container>
      <InnerContainer>
        <ContentContainer>
          <Tag
            style={{
              background: colors[2].background,
              color: colors[2].color,
            }}>
            SHARE
          </Tag>
          <Title>
            <span style={{ display: "flex", flexDirection: "column" }}>
              Built by & for{" "}
              <span style={{ position: "relative" }}>
                <SiNotion
                  size={width > 768 ? 60 : 40}
                  style={{ position: "relative", top: "10px" }}
                />{" "}
                Notioneers
              </span>
            </span>
          </Title>
          <DescriptionLine style={{ marginTop: "10px" }}>
            👉 Export your notes, recordings & transcript to Notion in 1 click.
          </DescriptionLine>
          <DescriptionLine>
            👉 Get 100% of Sweez's power directly into Notion.
          </DescriptionLine>
          <DescriptionLine>
            👉 Don't multiply tools, centralize in Notion.
          </DescriptionLine>
        </ContentContainer>
        <VideoContainer>
          <img
            src={share}
            alt="share"
            style={{
              height: width > 768 ? "80%" : "40%",
              marginLeft: width > 768 ? "-100px" : "0px",
            }}
          />
        </VideoContainer>
      </InnerContainer>
    </Container>
  );
};

export default NotionSection;
